import { useTranslation } from 'react-i18next';
import useUI from 'src/hooks/use-ui';
import CustomButton from '../CustomButton/CustomButton';
import CustomInput from '../CustomInput/CustomInput';
import HeaderSide from '../HeaderSide/HeaderSide';
import style from './ChangePassword.module.scss';

const ChangePassword = () => {
  const { t } = useTranslation();
  const { setSubSidePopup } = useUI();

  const onHeaderClick = () => {
    setSubSidePopup(null);
  };

  const onPasswordChange = () => {
    console.log('password');
    onHeaderClick();
  };

  return (
    <div className={style.changePassword}>
      <HeaderSide
        title={t('buttonsLink.password')}
        onHeaderClick={onHeaderClick}
      />
      <form action="" className={style.form}>
        <CustomInput placeholder={t('placeholder.currentPassword')} />
        <CustomInput placeholder={t('placeholder.newPassword')} />
        <CustomInput placeholder={t('placeholder.newPassword')} isSMS />
        <div style={{ marginTop: '40px' }} />
        <CustomButton
          type="main"
          title={t('buttons.confirm')}
          onClick={onPasswordChange}
        />
      </form>
    </div>
  );
};

export default ChangePassword;
