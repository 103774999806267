import { ChangeEvent, FC } from 'react';
import style from './CheckboxNotice.module.scss';

type PropsType = {
  checked: boolean;
  onNoticeChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const CheckboxNotice: FC<PropsType> = ({ checked, onNoticeChange }) => {
  return (
    <div className={style.checkbox}>
      <input type="checkbox" checked={checked} onChange={onNoticeChange} />
    </div>
  );
};

export default CheckboxNotice;
