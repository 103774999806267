import { useTranslation } from 'react-i18next';
import { activeDeals } from 'src/helpers/static-data';
import DealInfo from '../DealInfo/DealInfo';
import style from './ActiveDeals.module.scss';

const ActiveDeals = () => {
  const { t } = useTranslation();
  return (
    <div className={style.activeDeals}>
      <div className={style.header}>{t('Exchange.activeDeals')}</div>
      <div className={style.deals}>
        {activeDeals.map(
          ({ id, userName, deals, distance, performed, get, pay }) => (
            <div className={style.dealItem} key={id}>
              <DealInfo
                deals={deals}
                distance={distance}
                userName={userName}
                performed={performed}
                get={get}
                pay={pay}
              />
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default ActiveDeals;
