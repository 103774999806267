import { AbiItem } from 'web3-utils';
import { Web3Auth } from '@web3auth/modal';
import Web3 from 'web3';
import erc20Abi from 'src/abi/erc20.abi.json';

let web3auth = null;

export default class EthereumRpc {
  private provider: any;

  public static W3AUTH_KEY =
    'BFTWKgg6IEo2UgINGjaMu8kCPhaMnkWWfiFM8ivalneQOdRyyqoJy1SG0aFWhho4ioFs6ak6vRuZgjynvz3Qv0A';

  public static FAU_ADDRESS = '0xBA62BCfcAaFc6622853cca2BE6Ac7d845BC0f2Dc'; // Goerli

  public static USDT_ADDRESS = '0xdAC17F958D2ee523a2206206994597C13D831ec7';

  public static USDC_ADDRESS = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48';

  public static MULTI_ADDRESS = '0xcA11bde05977b3631167028862bE2a173976CA11'; // '0xcA11bde05977b3631167028862bE2a173976CA11';

  public FEE_ADDRESS = '0x2a71b8f645a7f4C1cE32196df8046947E19ffcb2'; // '0x3781dD72630874c5c60F9b23c031D88CCa91E1Af';

  constructor(provider: any) {
    this.provider = provider;
  }

  // eslint-disable-next-line class-methods-use-this
  sendTx(tx, callback): any {
    return {
      name: 'sendTransaction',
      call: 'eth_sendTransaction',
      params: [tx],
      returnTypes: 'txHash',
      callback,
    };
  }

  static getWeb3Auth(force = false): Web3Auth {
    if (!force && web3auth) {
      return web3auth;
    }

    web3auth = new Web3Auth({
      clientId: EthereumRpc.W3AUTH_KEY,
      web3AuthNetwork: 'sapphire_mainnet',
      chainConfig: {
        chainNamespace: 'eip155',
        chainId: '0x1',
        rpcTarget: 'https://rpc.ankr.com/eth',
      },
      enableLogging: true,
    });

    return web3auth;
  }

  static RefreshAuthContext() {
    return this.getWeb3Auth(true);
  }

  async getChainId(): Promise<string> {
    try {
      const web3 = new Web3(this.provider as any);

      // Get the connected Chain's ID
      const chainId = await web3.eth.getChainId();

      return chainId.toString();
    } catch (error) {
      return error as string;
    }
  }

  async getAccounts(): Promise<any> {
    try {
      const web3 = new Web3(this.provider as any);

      // Get user's Ethereum public address
      const address = (await web3.eth.getAccounts())[0];

      return address;
    } catch (error) {
      return error;
    }
  }

  async getBalance(): Promise<string> {
    try {
      const web3 = new Web3(this.provider as any);

      // Get user's Ethereum public address
      const address = (await web3.eth.getAccounts())[0];

      // Get user's balance in ether
      const balance = web3.utils.fromWei(
        await web3.eth.getBalance(address), // Balance is in wei
      );

      return balance.substring(0, 10);
    } catch (error) {
      return error as string;
    }
  }

  async getUSDCBalance(): Promise<string> {
    try {
      const web3 = new Web3(this.provider as any);
      const usdcAddress = EthereumRpc.USDC_ADDRESS;

      // Get user's Ethereum public address
      const address = (await web3.eth.getAccounts())[0];

      // create a contract handler
      const contract = new web3.eth.Contract(
        erc20Abi as AbiItem[],
        usdcAddress,
      );

      // Get user's balance in ether
      let balance = await contract.methods.balanceOf(address).call();
      const decimals = await contract.methods.decimals().call();
      balance /= 10 ** decimals;

      return balance;
    } catch (error) {
      return error as string;
    }
  }

  async getUSDTBalance(): Promise<string> {
    try {
      const web3 = new Web3(this.provider as any);
      const usdtAddress = EthereumRpc.USDT_ADDRESS;

      // Get user's Ethereum public address
      const address = (await web3.eth.getAccounts())[0];

      // create a contract handler
      const contract = new web3.eth.Contract(
        erc20Abi as AbiItem[],
        usdtAddress,
      );

      // Get user's balance in ether
      let balance = await contract.methods.balanceOf(address).call();
      const decimals = await contract.methods.decimals().call();
      balance /= 10 ** decimals;

      return balance;
    } catch (error) {
      return error as string;
    }
  }

  async sendTransaction(destination: string, amountEth: string): Promise<any> {
    try {
      const web3 = new Web3(this.provider as any);

      // Get user's Ethereum public address
      const fromAddress = (await web3.eth.getAccounts())[0];

      const amount = web3.utils.toWei(amountEth); // Convert 1 ether to wei
      console.log('amount', amount);

      // Submit transaction to the blockchain and wait for it to be mined
      const receipt = await web3.eth.sendTransaction({
        from: fromAddress,
        to: destination,
        value: amount,
        // maxPriorityFeePerGas: '5000000000', // Max priority fee per gas
        // maxFeePerGas: '6000000000000', // Max fee per gas
      });

      return receipt;
    } catch (error) {
      return error as string;
    }
  }

  async sendUSDCTransactionNoFee(
    destination: string,
    amountUSDC: string,
  ): Promise<string> {
    try {
      const web3 = new Web3(this.provider as any);
      const usdcAddress = EthereumRpc.USDC_ADDRESS;

      // Get user's Ethereum public address
      const fromAddress = (await web3.eth.getAccounts())[0];

      // create a contract handler
      const contract = new web3.eth.Contract(
        erc20Abi as AbiItem[],
        usdcAddress,
      );

      const decimals = await contract.methods.decimals().call();
      const amount = 10 ** decimals * parseFloat(amountUSDC);
      const receipt2 = await contract.methods
        .transfer(destination, amount)
        .send({
          from: fromAddress,
        });

      return receipt2;
    } catch (error) {
      return error as string;
    }
  }

  async sendUSDCTransaction(
    destination: string,
    amountUSDC: string,
  ): Promise<string> {
    try {
      const web3 = new Web3(this.provider as any);
      const usdcAddress = EthereumRpc.USDC_ADDRESS;

      // Get user's Ethereum public address
      const fromAddress = (await web3.eth.getAccounts())[0];

      // create a contract handler
      const contract = new web3.eth.Contract(
        erc20Abi as AbiItem[],
        usdcAddress,
      );

      const feeFloat = 1.0;
      const amountUSDCFloat = parseFloat(amountUSDC);
      const amountToSend = amountUSDCFloat - feeFloat;

      const decimals = await contract.methods.decimals().call();
      const amount = 10 ** decimals * amountToSend;
      const feeAmount = 10 ** decimals * feeFloat;

      const preNonce = await web3.eth.getTransactionCount(fromAddress);
      // eslint-disable-next-line prefer-template
      const accountNonce = '0x' + (preNonce + 1).toString(16);
      // eslint-disable-next-line prefer-template
      const accountNonce2 = '0x' + (preNonce + 2).toString(16);

      const receipt3 = await contract.methods
        .transfer(this.FEE_ADDRESS, feeAmount)
        .send({
          from: fromAddress,
          // nonce: accountNonce2,
        });

      const receipt2 = await contract.methods
        .transfer(destination, amount)
        .send({
          from: fromAddress,
          // nonce: accountNonce,
        });

      return receipt2;
    } catch (error) {
      return error as string;
    }
  }

  async sendUSDTTransaction(
    destination: string,
    amountUSDT: string,
  ): Promise<string> {
    try {
      const web3 = new Web3(this.provider as any);
      const usdtAddress = EthereumRpc.USDT_ADDRESS;

      // Get user's Ethereum public address
      const fromAddress = (await web3.eth.getAccounts())[0];

      const contract = new web3.eth.Contract(
        erc20Abi as AbiItem[],
        usdtAddress,
      );

      const feeFloat = 1.0;
      const amountUSDCFloat = parseFloat(amountUSDT);
      const amountToSend = amountUSDCFloat - feeFloat;

      const decimals = await contract.methods.decimals().call();
      console.log('decimals: ', decimals);
      const amount = 10 ** decimals * amountToSend;
      const feeAmount = 10 ** decimals * feeFloat;

      console.log('amount: ', amount);
      console.log('feeAmount: ', feeAmount);

      // const preNonce = await web3.eth.getTransactionCount(fromAddress);
      // eslint-disable-next-line prefer-template
      // const accountNonce = '0x' + (preNonce + 1).toString(16);
      // eslint-disable-next-line prefer-template
      // const accountNonce2 = '0x' + (preNonce + 2).toString(16);

      const receipt3 = await contract.methods
        .transfer(this.FEE_ADDRESS, feeAmount)
        .send({
          from: fromAddress,
          // nonce: accountNonce2,
        });

      const receipt2 = await contract.methods
        .transfer(destination, amount)
        .send({
          from: fromAddress,
          // nonce: accountNonce,
        });

      return receipt2;
    } catch (error) {
      console.log('error: ', error);
      return error as string;
    }
  }

  async sendUSDTTransactionNoFee(
    destination: string,
    amountUSDC: string,
  ): Promise<string> {
    try {
      const web3 = new Web3(this.provider as any);
      const usdtAddress = EthereumRpc.USDT_ADDRESS;

      // Get user's Ethereum public address
      const fromAddress = (await web3.eth.getAccounts())[0];

      // create a contract handler
      const contract = new web3.eth.Contract(
        erc20Abi as AbiItem[],
        usdtAddress,
      );

      const decimals = await contract.methods.decimals().call();
      const amount = 10 ** decimals * parseFloat(amountUSDC);
      const receipt2 = await contract.methods
        .transfer(destination, amount.toString())
        .send({
          from: fromAddress,
        });

      return receipt2;
    } catch (error) {
      return error as string;
    }
  }

  async signMessage() {
    try {
      const web3 = new Web3(this.provider as any);

      // Get user's Ethereum public address
      const fromAddress = (await web3.eth.getAccounts())[0];

      const originalMessage = '123';

      // Sign the message
      const signedMessage = await web3.eth.personal.sign(
        originalMessage,
        fromAddress,
        '', // configure your own password here.
      );

      return signedMessage;
    } catch (error) {
      return error as string;
    }
  }

  async signPersonalMessage(message: string): Promise<any> {
    try {
      const web3 = new Web3(this.provider as any);

      // Get user's Ethereum public address
      const fromAddress = (await web3.eth.getAccounts())[0];

      const signedMessage = await web3.eth.personal.sign(
        message,
        fromAddress,
        '',
      );

      // Get user's private key
      // const privateKey = await this.getPrivateKey();

      // Sign the message
      // const signedMessage = await web3.eth.accounts.sign(message, privateKey);

      return signedMessage;
    } catch (error) {
      return error as string;
    }
  }
}
