import { FC } from 'react';
import arrow from 'src/assets/images/authentications/arrow.svg';
import style from './AccountButton.module.scss';

type PropsType = {
  title: string;
  description: string;
  onClick: () => void;
};

const AccountButton: FC<PropsType> = ({ title, description, onClick }) => {
  return (
    <div className={style.accountButton} onClick={onClick} aria-hidden="true">
      <div className={style.accountButton__text}>
        <p>{title}</p>
        <span>{description}</span>
      </div>
      <div className={style.arrow}>
        <img src={arrow} alt="arrow" />
      </div>
    </div>
  );
};

export default AccountButton;
