import { ChangeEvent, FC, ReactNode, useState } from 'react';
import exchange from 'src/assets/images/exchange.png';
import ContainerPage from 'src/components/ContainerPage/ContainerPage';
import InformationItem from 'src/components/InformationItem/InformationItem';
import ExchangeControls from 'src/components/ExchangeControls/ExchangeControls';
import dollar from 'src/assets/images/exchange-dollar.svg';
import StatisticItem from 'src/components/StatisticItem/StatisticItem';
import {
  activeDeals,
  investTotal,
  SelectType,
  user,
  // workDays,
} from 'src/helpers/static-data';
import CheckboxNotice from 'src/components/CheckboxNotice/CheckboxNotice';
import CustomSelect from 'src/components/CustomSelect/CustomSelect';
import ActiveDeals from 'src/components/ActiveDeals/ActiveDeals';
import ChangerBlock from 'src/components/ChangerBlock/ChangerBlock';
import { useTranslation } from 'react-i18next';
import style from './Exchange.module.scss';

type PropsType = {
  children?: ReactNode;
};

const Exchange: FC<PropsType> = () => {
  const { t } = useTranslation();
  const { total } = investTotal;
  const { role } = user;
  const [checked, setChecked] = useState(false);
  const [workDay, setWorkDay] = useState('week');

  const workDays: SelectType[] = [
    { label: t('Exchange.selectWork.item1'), value: 'week' },
  ];

  const onNoticeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  const onworkDayChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setWorkDay(e.target.value);
  };

  const leftBlock = (
    <div className={style.exchangeBlock}>
      {role === 'changer' && (
        <>
          <StatisticItem
            styles={{ width: '100%', height: '164px' }}
            icon={dollar}
            total={total}
            title={t('Exchange.exchangeFund')}
          />
          <div className={style.settings}>
            <div className={style.worked}>
              <span>{t('placeholder.notWork')}</span>
              <CheckboxNotice
                checked={checked}
                onNoticeChange={onNoticeChange}
              />
            </div>
            <div className={style.workDay}>
              <CustomSelect
                defaulf={workDay}
                items={workDays}
                placeholder={t('placeholder.week')}
                onChange={onworkDayChange}
              />
            </div>
          </div>
        </>
      )}

      <ExchangeControls />
      <div className={style.notice}>
        <InformationItem description={t('Exchange.Information')} />
      </div>
    </div>
  );

  return (
    <ContainerPage
      leftBlock={leftBlock}
      isEmpty={!activeDeals.length}
      iconEmpty={exchange}
      emptyTitle={t('Exchange.empty.title')}
      emptyDescription={t('Exchange.empty.description')}
    >
      <div
        className={style.exchangeList}
        style={{ alignItems: role !== 'changer' ? '' : 'flex-start' }}
      >
        {role !== 'changer' ? <ActiveDeals /> : <ChangerBlock />}
      </div>
    </ContainerPage>
  );
};

export default Exchange;
