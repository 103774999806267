import { FC, MouseEvent, ReactNode, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar/Sidebar';
import style from './SidebarLayout.module.scss';

type PropsType = {
  children?: ReactNode;
};

const SidebarLayout: FC<PropsType> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const onCloseMenu = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target !== e.currentTarget && isOpen) {
      e.preventDefault();
      e.stopPropagation();
      setIsOpen(false);
    }
  };

  return (
    <div className={style.container}>
      <div
        className={style.menuContainer}
        onClick={onMenuClick}
        aria-hidden="true"
      >
        <div className={style.menuBtn} />
      </div>
      <Sidebar isOpen={isOpen} onMenuClick={onMenuClick} />
      <div
        className={style.mainContent}
        onClick={onCloseMenu}
        aria-hidden="true"
      >
        <Outlet />
      </div>
    </div>
  );
};

export default SidebarLayout;
