import { FC } from 'react';
import { matchPath, Link } from 'react-router-dom';
import IconContainer from 'src/components/IconContainer/IconContainer';
import { SidebarType } from 'src/types/types';
import style from './SidebarItem.module.scss';

type PropsType = {
  path: string;
  item: SidebarType;
  onMenuClick: () => void;
};

const SidebarItem: FC<PropsType> = ({ path, item, onMenuClick }) => {
  const { link, title, icon } = item;
  const childPath = path.split('/').includes(link);
  const exactMatch = link
    ? !!matchPath(
        {
          path: link,
          end: true,
        },
        path,
      ) ||
      childPath ||
      (path.includes('invest') && link === '/')
    : false;
  return (
    <Link
      to={link}
      key={title}
      className={
        exactMatch ? `${style.menuItem} ${style.active}` : style.menuItem
      }
      onClick={onMenuClick}
    >
      <IconContainer icon={icon} isActive={exactMatch} />
      <span>{title}</span>
    </Link>
  );
};

export default SidebarItem;
