import { useNavigate } from 'react-router-dom';
import back from 'src/assets/images/myScore/back.svg';
import style from './ButtonBack.module.scss';

const ButtonBack = () => {
  const navigate = useNavigate();
  const onBackClick = () => {
    navigate('/', { replace: true });
  };
  return (
    <div className={style.buttonBack} onClick={onBackClick} aria-hidden="true">
      <div className={style.btn}>
        <img src={back} alt="back" />
      </div>
    </div>
  );
};

export default ButtonBack;
