import { useTranslation } from 'react-i18next';
import { activeFund, investTotal } from 'src/helpers/static-data';
import style from './InvestInfo.module.scss';

const InvestInfo = () => {
  const { t } = useTranslation();
  return (
    <div className={style.investBlock}>
      <span>{t('sideBlock.InvestSide.invest')}</span>
      <div className={style.total}>{investTotal.total}</div>
      <span className={style.profit}>{t('sideBlock.InvestSide.profit')}</span>
      <div className={style.profitTotal}>
        <p>
          {activeFund.total}{' '}
          <span style={{ color: activeFund.rate > 0 ? '#12B76A' : '#F04438' }}>
            {`${activeFund.rate > 0 ? '+' : ''}${activeFund.rate}%`}
          </span>
        </p>
      </div>
    </div>
  );
};

export default InvestInfo;
