import { FC } from 'react';
import information from 'src/assets/images/information.svg';
import style from './InformationItem.module.scss';

type PropsType = {
  description: string;
};

const InformationItem: FC<PropsType> = ({ description }) => {
  return (
    <div className={style.information}>
      <div className={style.informationIcon}>
        <img src={information} alt="information" />
      </div>
      <div className={style.description}>{description}</div>
    </div>
  );
};

export default InformationItem;
