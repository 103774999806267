import { activeDeals } from 'src/helpers/static-data';
import useOffer from 'src/hooks/use-offer';
import useUI from 'src/hooks/use-ui';
import Distance from '../Distance/Distance';
import style from './DealsChnager.module.scss';

const DealsChnager = () => {
  const { setSidePopup } = useUI();
  const { setActiveDeal } = useOffer();
  return (
    <div className={style.dealsChnager}>
      {activeDeals.map(({ id, userName, distance, pay }) => {
        const onDealClick = () => {
          setActiveDeal(id);
          setSidePopup('ActiveDeal');
        };
        return (
          <div
            className={style.deal}
            key={id}
            onClick={onDealClick}
            aria-hidden="true"
          >
            <div className={style.name}>
              <span>{userName}</span>
              <Distance distance={distance} />
            </div>
            <div className={style.total}>
              {pay} {'USDT>USD'}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DealsChnager;
