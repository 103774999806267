import { FC, ReactNode } from 'react';
import DividerVertical from '../DividerVertical/DividerVertical';
import EmptyList from '../EmptyList/EmptyList';
import style from './ContainerPage.module.scss';

type PropsType = {
  children: ReactNode;
  leftBlock: JSX.Element;
  isEmpty: boolean;
  iconEmpty: string;
  emptyTitle: string;
  emptyDescription: string;
};

const ContainerPage: FC<PropsType> = ({
  children,
  leftBlock,
  isEmpty,
  iconEmpty,
  emptyTitle,
  emptyDescription,
}) => {
  return (
    <div className={style.containerPage}>
      <div className={style.leftBlock}>{leftBlock}</div>
      <div className={style.listContainer}>
        <div className={style.divider}>
          <DividerVertical />
        </div>
        {isEmpty ? (
          <div className={style.empty}>
            <EmptyList
              icon={iconEmpty}
              title={emptyTitle}
              description={emptyDescription}
            />
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default ContainerPage;
