import { useTranslation } from 'react-i18next';
import { user } from 'src/helpers/static-data';
import useUI from 'src/hooks/use-ui';
import Devider from '../Devider/Devider';
import HeaderSide from '../HeaderSide/HeaderSide';
import style from './ProfileDetails.module.scss';

const ProfileDetails = () => {
  const { t } = useTranslation();
  const { setSubSidePopup } = useUI();
  const { name, birthDate, address, phone, email } = user;
  const isConfirmed = true;

  const onHeaderClick = () => {
    setSubSidePopup(null);
  };

  return (
    <div className={style.profileDetails}>
      <HeaderSide
        title={t('sideBlock.SettingsAccount.details')}
        onHeaderClick={onHeaderClick}
      />
      <div className={style.user}>
        <div className={style.info}>
          <h3>Coming Soon</h3>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
