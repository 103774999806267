import { ChangeEvent, FC, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import search from 'src/assets/images/myScore/search_input.svg';
import CustomButton from '../CustomButton/CustomButton';
import style from './CustomInput.module.scss';

type PropsType = {
  type?: string;
  placeholder: string;
  error?: boolean;
  isSMS?: boolean;
  isSearch?: boolean;
  onClick?: () => void;
  onChange?: (value: string) => void;
};

// export type Ref = HTMLInputElement;

const CustomInput = forwardRef<HTMLInputElement, PropsType>((props, ref) => {
  const { type, placeholder, error, isSMS, isSearch, onClick, onChange } =
    props;
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [focus, setFocus] = useState(false);
  const [labelStyle, setLabelStyle] = useState([style.placeholder]);
  const [inputStyle, setInputStyle] = useState({});
  const [label, setLabel] = useState({});

  const onInputChage = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  const onInputBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setFocus(false);
    if (e.target.value) {
      setLabelStyle([style.placeholder, style.label]);
    } else {
      setLabelStyle([style.placeholder, style.empty]);
    }
  };

  const onInputFocus = (e: ChangeEvent<HTMLInputElement>) => {
    setFocus(true);
    if (!e.target.value) {
      setLabelStyle([style.placeholder, style.focus]);
    }
  };

  const onClickInput = () => {
    setFocus(true);
  };

  useEffect(() => {
    if (isSearch) {
      setInputStyle({ ...inputStyle, left: '44px' });
      setLabel({ ...label, left: '44px' });
    }

    if (focus || value) {
      setInputStyle({ ...inputStyle, height: '24px', bottom: '4px' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus, isSearch, value]);

  return (
    <>
      <div
        style={error ? { border: '1px solid rgba(240, 68, 56, 1)' } : {}}
        onClick={onClickInput}
        className={focus ? `${style.input} ${style.inputFocus}` : style.input}
        aria-hidden="true"
      >
        {isSearch && <img className={style.search} src={search} alt="search" />}
        <input
          style={inputStyle}
          value={value}
          type={type}
          id={placeholder}
          onChange={onInputChage}
          onBlur={onInputBlur}
          onFocus={onInputFocus}
          ref={ref}
        />
        <label
          style={error ? { ...label, color: '#F04438' } : label}
          className={labelStyle.join(' ')}
          htmlFor={placeholder}
        >
          {placeholder}
        </label>
        <span />
        {isSMS && (
          <div
            className={style.button}
            style={error ? { top: '-1px', right: '-1px' } : {}}
          >
            <CustomButton
              type="main"
              title={t('buttons.sendSMS')}
              onClick={onClick}
            />
          </div>
        )}
      </div>
      <div className={style.errorText}>{error && 'Error message text'}</div>
    </>
  );
});

export default CustomInput;

CustomInput.defaultProps = {
  type: 'text',
  error: false,
  isSMS: false,
  isSearch: false,
  onClick: () => {},
  onChange: () => {},
};
