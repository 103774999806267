import { FC, ReactNode } from 'react';
import creditIcon from 'src/assets/images/credit.svg';
import percent from 'src/assets/images/percent.svg';
import empty from 'src/assets/images/empty-credit.png';
import { activeLoans, investTotal, myLoans } from 'src/helpers/static-data';
import StatisticItem from 'src/components/StatisticItem/StatisticItem';
import CreateCredit from 'src/components/CreateCredit/CreateCredit';
import DepositItem from 'src/components/DepositItem/DepositItem';
import ContainerPage from 'src/components/ContainerPage/ContainerPage';
import { useTranslation } from 'react-i18next';
import style from './Credit.module.scss';

type PropsType = {
  children?: ReactNode;
};

const Credit: FC<PropsType> = () => {
  const { t } = useTranslation();
  const { rate, total } = investTotal;
  const styles = { width: '100%', height: '164px' };

  const leftBlock = (
    <>
      <div className={style.statistic}>
        <StatisticItem
          styles={styles}
          icon={creditIcon}
          rate={rate}
          total={total}
          title={t('Credit.Statistic.credit')}
        />
        <div className={style.block} />
        <StatisticItem
          styles={styles}
          icon={percent}
          total={total}
          title={t('Credit.Statistic.loans')}
        />
      </div>
      <CreateCredit />
    </>
  );

  return (
    <ContainerPage
      leftBlock={leftBlock}
      isEmpty={!activeLoans.length}
      iconEmpty={empty}
      emptyTitle={t('Credit.empty.title')}
      emptyDescription={t('Credit.empty.description')}
    >
      <div className={style.creditsList}>
        <h2>{t('Credit.loans.activeLoans')}</h2>
        {activeLoans.map(({ id, month, credit, user, rate: creditRate }) => (
          <DepositItem
            id={id}
            month={month}
            rate={creditRate}
            user={user}
            total={credit}
            key={id}
          />
        ))}
        <div style={{ marginTop: '40px' }} />
        <h2>{t('Credit.loans.myLoans')}</h2>
        {myLoans.map(({ id, month, credit, user, rate: creditRate }) => (
          <DepositItem
            id={id}
            month={month}
            rate={creditRate}
            user={user}
            total={credit}
            key={id}
          />
        ))}
      </div>
    </ContainerPage>
  );
};

export default Credit;
