import { createContext } from 'react';

export type CreditType = {
  id: string;
  month: number;
  rate: number;
  availableCredit: number;
  isDisabled: boolean;
};

export interface CreditState {
  conditionСredit: CreditType | null;
}

export const CreditInitialState: CreditState = {
  conditionСredit: null,
};

export const CreditReducer = (
  state: CreditState,
  action: ActionsType,
): CreditState => {
  switch (action.type) {
    case 'credit/x11-app/SET-CONDITION-CREDIT': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export const setConditionСreditAC = (conditionСredit: CreditType) =>
  ({
    type: 'credit/x11-app/SET-CONDITION-CREDIT',
    payload: { conditionСredit },
  } as const);

type ActionsType = ReturnType<typeof setConditionСreditAC>;

interface CreditContextValue extends CreditState {
  setConditionСredit: (conditionСredit: CreditType) => void;
}

export const CreditContext = createContext<CreditContextValue>({
  ...CreditInitialState,
  setConditionСredit: () => {},
});
