import HeaderSide from 'src/components/HeaderSide/HeaderSide';
import useUI from 'src/hooks/use-ui';
import CustomButton from '../CustomButton/CustomButton';
import EventInfo from '../EventInfo/EventInfo';
import style from './GetPaid.module.scss';

const GetPaid = () => {
  const { setSidePopup } = useUI();

  const onHeaderClick = () => {
    setSidePopup(null);
  };

  const onOpenClick = () => {
    onHeaderClick();
  };

  const onConfirmClick = () => {
    onHeaderClick();
  };

  return (
    <div className={style.getPaid}>
      <HeaderSide
        title="Получить оплату"
        onHeaderClick={onHeaderClick}
        isQuestion
      />
      <EventInfo isPaid />
      <div className={style.description}>
        <span>
          Деньги временно заблокированы. Ожидается подтверждение передачи
          товара\услуг.
        </span>
      </div>
      <div className={style.buttons}>
        <div className={style.button}>
          <CustomButton
            styled={{ color: ' #D92D20', borderColor: '#D92D20' }}
            type="secondary"
            title="Открыть диспут"
            onClick={onOpenClick}
          />
        </div>
        <div className={style.button}>
          <CustomButton
            type="main"
            title="Подтвердить"
            onClick={onConfirmClick}
          />
        </div>
      </div>
    </div>
  );
};

export default GetPaid;
