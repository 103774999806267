import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { formatDate } from 'src/helpers/formatDate';
import useDeposit from 'src/hooks/use-deposit';
import useUI from 'src/hooks/use-ui';
import style from './DepositItem.module.scss';

type PropsType = {
  id: string;
  month: number;
  rate: number;
  total: number;
  nextPayout?: string;
  user?: string;
};

const DepositItem: FC<PropsType> = ({
  id,
  month,
  nextPayout,
  rate,
  total,
  user,
}) => {
  const { t } = useTranslation();
  const { setSidePopup } = useUI();
  const { getDeposit } = useDeposit();
  const location = useLocation();

  const openDeposit = () => {
    if (location.pathname.includes('deposit')) {
      getDeposit(id);
      setSidePopup('DepositMonths');
    }
  };

  return (
    <div
      key={id}
      className={style.depositItem}
      onClick={openDeposit}
      aria-hidden="true"
    >
      <div className={style.rate}>
        <div className={style.progress}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
            <circle cx="16" cy="16" r="15.9155" className={style.progressBar} />
          </svg>
        </div>
        <span>{rate}%</span>
      </div>
      <div className={style.info}>
        <div className={style.month}>
          <span>
            {month} {t('buttons.month')}
          </span>
          {nextPayout && (
            <p>
              {t('Deposit.nextPayout')} {formatDate(nextPayout)}
            </p>
          )}
          {user && <p>{user}</p>}
        </div>
        <span>{total} USD</span>
      </div>
    </div>
  );
};

export default DepositItem;

DepositItem.defaultProps = {
  user: '',
  nextPayout: '',
};
