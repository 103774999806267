import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { infoChats } from 'src/helpers/static-data';
import style from './InfoChats.module.scss';

type PropsType = {
  children?: ReactNode;
};

const InfoChats: FC<PropsType> = () => {
  const { t } = useTranslation();
  const { chats, newMessage } = infoChats;
  return (
    <div className={style.infoChats}>
      <div className={style.infoItem}>
        <p>{t('Notifications.chats.active')}</p>
        <span>{chats}</span>
      </div>
      <div className={style.infoItem}>
        <p>{t('Notifications.chats.newMessage')}</p>
        <span>{newMessage}</span>
      </div>
    </div>
  );
};

export default InfoChats;
