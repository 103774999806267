import { useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import selfieIcon from 'src/assets/images/selfie.svg';
import ButtonLink from '../ButtonLink/ButtonLink';
import style from './UploadSelfie.module.scss';

const UploadSelfie = forwardRef<HTMLInputElement>((props, ref) => {
  const { t } = useTranslation();

  const [selfie, setSelfie] = useState<File | null>(null);

  return (
    <div className={style.activateAccount}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={style.uploadLabel} htmlFor="upload-selfie">
        <div className={style.icon}>
          <img src={selfieIcon} alt="" />
        </div>
        {t('activateAccount.uploadSelfie')}
      </label>
      <input
        className={style.uploadSelfie}
        name="passport"
        id="upload-selfie"
        type="file"
        ref={ref}
        onChange={(e) => {
          console.log(e.target.files);
          setSelfie(e.target.files?.[0]);
        }}
      />
      <span className={style.fileName}>{selfie ? selfie.name : ''}</span>
    </div>
  );
});

export default UploadSelfie;
