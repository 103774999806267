import { FC, useEffect, useState } from 'react';
import { TabsType } from 'src/types/types';
import style from './CardTabs.module.scss';

type PropsType = {
  currentTab: string;
  changeTab: (id: string) => void;
  tabs: TabsType[];
  type: string;
};

const CardTabs: FC<PropsType> = ({ currentTab, changeTab, tabs, type }) => {
  const [styles, setStyles] = useState(style.tabItem);

  useEffect(() => {
    if (type === 'chat') {
      setStyles(style.tabItemChat);
    }
    if (type === 'invest') {
      setStyles(style.tabItemInvest);
    }
    if (type === 'changer') {
      setStyles(style.tabItemChanger);
    }
  }, [type]);
  return (
    <div className={style.tabs}>
      {tabs.map(({ id, title }) => {
        const onTabClick = () => {
          changeTab(id);
        };
        return (
          <div
            onClick={onTabClick}
            aria-hidden="true"
            key={id}
            className={id === currentTab ? `${styles} ${style.active}` : styles}
          >
            <span>{title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default CardTabs;
