import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { tableRate } from 'src/helpers/static-data';
import style from './TableRate.module.scss';

const TableRate: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={style.rateBlock}>
      <div className={style.header}>
        <div className={style.summary}>
          {t('sideBlock.StakingSide.table.total')}
        </div>
        <div className={style.rate}>
          {t('sideBlock.StakingSide.table.rate')}:
        </div>
      </div>
      {tableRate.map(({ min, max, rate }) => (
        <div className={style.item} key={`${max}_${rate}`}>
          <div className={style.summary}>
            {!!min && (
              <>
                {t('sideBlock.StakingSide.table.from')} <span>{min} 000</span>{' '}
                {t('sideBlock.StakingSide.table.to')}
              </>
            )}{' '}
            <span>
              {!min && (
                <span className={style.more}>
                  {t('sideBlock.StakingSide.table.more')}{' '}
                </span>
              )}
              {max}
              000
            </span>{' '}
            {t('sideBlock.StakingSide.table.coins')}
          </div>
          <div className={style.rate}>{rate}%</div>
        </div>
      ))}
    </div>
  );
};
export default TableRate;
