import { FC } from 'react';
import success from 'src/assets/images/success.svg';
import failed from 'src/assets/images/failed.svg';
import { useTranslation } from 'react-i18next';
import style from './WriteForm.module.scss';

type PropsType = {
  type: string;
};

const ResponseForm: FC<PropsType> = ({ type }) => {
  const { t } = useTranslation();
  const isFailed = type === 'failed';
  return (
    <div className={style.response}>
      <img src={isFailed ? failed : success} alt="response_icon" />
      <p className={style.status}>
        {isFailed
          ? t('writeForm.response.failed.status')
          : t('writeForm.response.success.status')}
      </p>
      <span className={style.description}>
        {isFailed
          ? t('writeForm.response.failed.description')
          : t('writeForm.response.success.description')}
      </span>
    </div>
  );
};

export default ResponseForm;
