import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import filter from 'src/assets/images/myScore/filter.svg';
import search from 'src/assets/images/myScore/search.svg';
import CardTabs from 'src/components/CardTabs/CardTabs';
import ChatCurrent from 'src/components/ChatCurrent/ChatCurrent';
import ChatItem from 'src/components/ChatItem/ChatItem';
import Devider from 'src/components/Devider/Devider';
import DividerVertical from 'src/components/DividerVertical/DividerVertical';
import InfoChats from 'src/components/InfoChats/InfoChats';
import NotificationItem from 'src/components/NotificationItem/NotificationItem';
import SupportButton from 'src/components/SupportButton/SupportButton';
import {
  chatsList,
  notifications,
  translateNotifications,
} from 'src/helpers/static-data';
import { TabsType } from 'src/types/types';
import style from './Notifications.module.scss';

type PropsType = {
  children?: ReactNode;
};

const Notifications: FC<PropsType> = () => {
  const { t } = useTranslation();

  const tabsNotifications: TabsType[] = [
    { title: t('Notifications.tabs.notifications'), id: 'notifications' },
    { title: t('Notifications.tabs.chats'), id: 'chats' },
  ];

  const [currentChat, setCurrentChat] = useState(null);

  const [styles, setStyles] = useState(translateNotifications);
  const [translateX, setTranslateX] = useState('-575px');
  const [currentTab, setCurrentTab] = useState<string>('notifications');

  const chatStyles = currentChat
    ? {
        transform: `translateX(${translateX})`,
        transition: '0.3s ease-in',
      }
    : { transform: 'translateX(0px)', transition: '0.3s ease-in' };

  const onChatClick = (id: string) => {
    const findChat = chatsList.find((el) => el.id === id);
    if (findChat) {
      setCurrentChat(findChat);
      setStyles({ ...styles, transform: 'translateX(-842px)' });
    }
  };

  const closeChat = () => {
    setCurrentChat(null);
    setStyles(translateNotifications);
  };

  const changeTab = (id: string) => {
    if (!currentChat) {
      setCurrentTab(id);
    }
  };

  useEffect(() => {
    const size = window.innerWidth;
    if (size <= 1350 && size > 1100) {
      setTranslateX('-100%');
    }
    if (size <= 1100) {
      setTranslateX('-200%');
    }
  }, []);

  return (
    <div className={style.notificationsBlock}>
      {!currentChat && (
        <div className={style.tabsContainer}>
          <CardTabs
            currentTab={currentTab}
            changeTab={changeTab}
            tabs={tabsNotifications}
            type="chat"
          />
        </div>
      )}
      {(window.innerWidth > 1100 || !currentChat) && (
        <div className={style.line}>
          <DividerVertical />
        </div>
      )}
      {(currentTab === 'notifications' || window.innerWidth > 600) && (
        <div className={style.notificationsList} style={styles}>
          <div className={style.notificationsHeader}>
            <p>{t('Notifications.tabs.notifications')}</p>
            <img src={filter} alt="filter" />
          </div>
          {notifications.map((el) => (
            <NotificationItem notification={el} key={el.id} />
          ))}
        </div>
      )}
      {(currentTab === 'chats' || window.innerWidth > 600) && (
        <div className={style.chatsBlock} style={chatStyles}>
          <div className={style.notificationsHeader}>
            <p>{t('Notifications.tabs.chats')}</p>
            <img src={search} alt="search" />
          </div>
          <InfoChats />
          <SupportButton />
          <Devider styles={{ marginTop: '24px', marginBottom: '24px' }} />
          {chatsList.map((el) => (
            <ChatItem chat={el} key={el.id} onChatClick={onChatClick} />
          ))}
        </div>
      )}
      {currentChat && <ChatCurrent chat={currentChat} closeChat={closeChat} />}
    </div>
  );
};

export default Notifications;
