import { FC } from 'react';
import { ChatItemType } from 'src/helpers/static-data';
import HeaderSide from '../HeaderSide/HeaderSide';
import style from './ChatCurrent.module.scss';
import ChatList from './ChatList/ChatList';

type PropsType = {
  chat: ChatItemType;
  closeChat: () => void;
};

const ChatCurrent: FC<PropsType> = ({ chat, closeChat }) => {
  const { userName } = chat;

  const onHeaderClick = () => {
    closeChat();
  };

  return (
    <div className={style.chatCurrent}>
      <HeaderSide title={userName} onHeaderClick={onHeaderClick} />
      <div className={style.wrap}>
        <ChatList chat={chat} />
      </div>
    </div>
  );
};

export default ChatCurrent;
