import useOffer from 'src/hooks/use-offer';
import useUI from 'src/hooks/use-ui';
import { useEffect, useState } from 'react';
import { chatsList } from 'src/helpers/static-data';
import { useTranslation } from 'react-i18next';
import HeaderSide from '../HeaderSide/HeaderSide';
import CustomButton from '../CustomButton/CustomButton';
import ChatList from '../ChatCurrent/ChatList/ChatList';
import DealInfo from '../DealInfo/DealInfo';
import style from './ActiveDealSide.module.scss';

const ActiveDealSide = () => {
  const { t } = useTranslation();
  const { setSidePopup } = useUI();
  const { activeDeal } = useOffer();
  const [currentChat, setCurrentChat] = useState(null);
  const [isSmall, setIsSmall] = useState(true);

  const onHeaderClick = () => {
    setSidePopup(null);
  };

  useEffect(() => {
    if (currentChat) {
      const h = document.getElementById('chatOffer')?.clientHeight;
      if (h > 542) {
        setIsSmall(false);
      } else {
        setIsSmall(true);
      }
    }
  }, [currentChat]);

  useEffect(() => {
    const findChat = chatsList.find((el) => el.id === activeDeal.id);
    if (findChat) {
      setCurrentChat(findChat);
    }
  }, [activeDeal.id]);

  return (
    <div className={style.activeDealSide}>
      <HeaderSide
        title={`${activeDeal?.userName} (USDT)`}
        onHeaderClick={onHeaderClick}
      />
      <div className={style.dealBlock}>
        <DealInfo
          deals={activeDeal?.deals}
          performed={activeDeal?.performed}
          distance={activeDeal?.distance}
          userName={activeDeal?.userName}
          get={150}
          pay={150}
        />
        <div className={style.buttons}>
          <div className={style.button}>
            <CustomButton
              size="small"
              styled={{
                color: '#D92D20',
                borderColor: '#FDA29B',
              }}
              type="secondary"
              onClick={() => console.log('Отказаться')}
              title={t('buttons.dispute')}
            />
          </div>
          <div className={style.button}>
            <CustomButton
              size="small"
              type="main"
              onClick={() => console.log('Подвердить')}
              title={t('buttons.show')}
            />
          </div>
        </div>
      </div>
      {currentChat && !!currentChat.messages.length && (
        <div
          id="chatOffer"
          className={style.chatBlock}
          style={{ height: isSmall ? '100%' : '542px' }}
        >
          <ChatList chat={currentChat} type="exchange" />
        </div>
      )}
    </div>
  );
};

export default ActiveDealSide;
