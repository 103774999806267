import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import addCredit from 'src/assets/images/addCredit.svg';
import style from './ButtonAnte.module.scss';

type PropsType = {
  month?: string;
  rate?: string;
  add?: string;
  onClickButton: () => void;
};

const ButtonAnte: FC<PropsType> = ({ month, rate, add, onClickButton }) => {
  const { t } = useTranslation();
  return (
    <div className={style.item} onClick={onClickButton} aria-hidden="true">
      {!add ? (
        <>
          <span className={style.month}>{month}</span>
          <p>{rate}</p>
          <span>{t('buttons.annum')}</span>
        </>
      ) : (
        <div className={style.add}>
          <img src={addCredit} alt="addCredit" />
          <span className={style.month}>{add}</span>
        </div>
      )}
    </div>
  );
};

export default ButtonAnte;

ButtonAnte.defaultProps = {
  add: '',
  month: '',
  rate: '',
};
