import { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { currencies, networks } from 'src/helpers/static-data';
import useUI from 'src/hooks/use-ui';
import { AuthContext } from 'src/reducers/AuthReducer';
import RPC from 'src/helpers/web3RPC';
import Web3 from 'web3';
import CustomButton from '../CustomButton/CustomButton';
import CustomInput from '../CustomInput/CustomInput';
import CustomSelect from '../CustomSelect/CustomSelect';
import HeaderSide from '../HeaderSide/HeaderSide';
import style from './CreateTransfer.module.scss';
import Spinner from '../Spinner/Spinner';

const CreateTransfer = () => {
  const { t } = useTranslation();
  const { setNotification, setSidePopup } = useUI();
  const [currency, setCurrency] = useState('usdt');
  const [network, setNetwork] = useState('Ethereum (ERC20)');
  const authCtx = useContext(AuthContext);
  const [address, setAddress] = useState<string>('');
  const [amount, setAmount] = useState<string>('');

  const { auth } = authCtx;
  if (!auth.w3Auth) auth.w3Auth = RPC.RefreshAuthContext();
  console.log('AUTHCTX: ', auth.w3Auth);
  const rpc = new RPC(auth.w3Auth.provider);

  const [progress, setProgress] = useState(false);
  const [addressError, setAddressError] = useState<string>('');
  const [amountError, setAmountError] = useState<string>('');

  const onHeaderClick = () => {
    setSidePopup(null);
  };

  const onCurrencyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrency(e.target.value);
  };

  const onNetworkChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setNetwork(e.target.value);
  };

  const verifyAddress = () => {
    let error = '';
    const isAddress = Web3.utils.isAddress(address);
    if (!isAddress) {
      // error = t('sideBlock.CreateTransfer.addressError');
      error = 'Invalid address';
    }
    setAddressError(error);
    return error;
  };

  const verifyAmount = () => {
    let error = '';
    if (amount === '') {
      // error = t('sideBlock.CreateTransfer.amountError');
      error = 'Invalid amount. Minimum amount is $2';
      setAmountError(error);
      return error;
    }
    const amt = parseFloat(amount);
    console.log('AMOUNT: ', amt);
    if (amt < 2) {
      // error = t('sideBlock.CreateTransfer.amountError');
      error = 'Invalid amount. Minimum amount is $2';
    }
    setAmountError(error);
    return error;
  };

  const sendForm = async () => {
    console.log('CURRENCY: ', currency);
    setProgress(true);
    console.log('Connecting...');
    await auth.w3Auth.connect();
    console.log('Connected');
    let receipt;

    const addressErr = verifyAddress();
    const amountErr = verifyAmount();

    if (addressErr !== '' || amountErr !== '') {
      setProgress(false);
      return;
    }

    switch (currency) {
      case 'eth':
        receipt = await rpc.sendTransaction(address, amount);
        break;
      case 'usdt':
        receipt = await rpc.sendUSDTTransaction(address, amount);
        break;
      case 'usdc':
        receipt = await rpc.sendUSDCTransaction(address, amount);
        break;
      default:
        break;
    }
    setProgress(false);
    setAddressError('');
    setAmountError('');

    onHeaderClick();
    setNotification({
      isShow: true,
      header: t('sideBlock.CreateTransfer.notification'),
      description: receipt.status
        ? receipt.transactionHash
        : receipt.toString(),
    });

    console.log(receipt);
    console.log('send');
  };

  return (
    <div className={style.createTranslation}>
      <HeaderSide
        title={t('sideBlock.CreateTransfer.header')}
        onHeaderClick={onHeaderClick}
      />
      <form>
        <div className={style.wrap}>
          <CustomSelect
            onChange={onCurrencyChange}
            placeholder={t('placeholder.currency')}
            items={currencies}
            defaulf={currency}
          />
        </div>
        <div className={style.wrap}>
          <CustomSelect
            onChange={onNetworkChange}
            placeholder={t('placeholder.net')}
            items={networks}
            defaulf={network}
          />
        </div>
        <div className={style.wrap}>
          <CustomInput
            placeholder={t('placeholder.address')}
            onChange={setAddress}
          />
        </div>
        <span className={style.error}>{addressError}</span>
        <CustomInput
          placeholder={t('placeholder.total')}
          onChange={setAmount}
        />
        <span className={style.error}>{amountError}</span>
        <p className={style.available}>
          Available <span>{auth.balance} ETH</span>{' '}
          <span>{auth.balanceUSDT} USDT</span>{' '}
          <span>{auth.balanceUSDC} USDC</span>
        </p>

        {!progress && (
          <CustomButton
            type="main"
            title={t('buttons.send')}
            onClick={sendForm}
          />
        )}
        {progress && <Spinner />}
      </form>
    </div>
  );
};

export default CreateTransfer;
