import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import logo from 'src/assets/images/authentications/X11_bank.svg';
import communication from 'src/assets/images/communication.svg';
import information from 'src/assets/images/information.svg';
import ButtonLink from 'src/components/ButtonLink/ButtonLink';
import DepositAddress from 'src/components/DepositAddress/DepositAddress';
import Devider from 'src/components/Devider/Devider';
import InformationItem from 'src/components/InformationItem/InformationItem';
import QrCode from 'src/components/QrCode/QrCode';
import { user } from 'src/helpers/static-data';
import style from './Invoice.module.scss';

const Invoice = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { name } = user;

  useEffect(() => {
    if (location.pathname.includes('ru')) {
      i18n.changeLanguage('ru');
    } else {
      i18n.changeLanguage('en');
    }
  }, [i18n, location]);

  return (
    <div className={style.borderContainer}>
      <img
        className={style.logo}
        src={logo}
        alt="logo_x11"
        aria-hidden="true"
      />
      <div className={style.invoice}>
        <div className={style.headerInvoice}>{t('Invoice.header')}</div>
        <div className={style.user}>
          <p>
            {t('Invoice.user')} <span>{name}</span> <br />{' '}
            {t('Invoice.payment')}
          </p>
        </div>
        <div className={style.currency}>
          <p>
            {t('placeholder.currency')}: <span>USDT</span>
          </p>
          <p className={style.total}>
            {t('placeholder.total')}: <span>2 300</span>
          </p>
        </div>
        <InformationItem description={t('placeholder.info.item1')} />
        <div className={style.information}>
          <div className={style.informationIcon}>
            <img src={information} alt="information" />
          </div>
          <div className={style.description}>
            {t('Invoice.info.item2.point1')}{' '}
            <span className={style.link}>{t('Invoice.info.item2.bank')}</span>{' '}
            {t('Invoice.info.item2.point2')}
          </div>
        </div>
        <div style={{ marginBottom: '48px' }} />
        <QrCode />
        <div className={style.network}>
          {t('placeholder.net')} <br /> <span>Ethereum (ER20)</span>
        </div>
        <DepositAddress />
        <Devider styles={{ marginTop: '32px', marginBottom: '24px' }} />
        <p>{t('Invoice.description')}</p>
        <ButtonLink
          icon={communication}
          title={t('buttonsLink.communication')}
        />
      </div>
    </div>
  );
};

export default Invoice;
