import { ChangeEvent, FC, forwardRef, useEffect, useState } from 'react';
import style from './CustomTextArea.module.scss';

type PropsType = {
  placeholder: string;
  error?: boolean;
  onChange?: (value: string) => void;
};

const CustomTextArea = forwardRef<HTMLTextAreaElement, PropsType>(
  (props, ref) => {
    const { placeholder, error, onChange } = props;
    const [value, setValue] = useState('');
    const [focus, setFocus] = useState(false);
    const [labelStyle, setLabelStyle] = useState([style.placeholder]);
    const [inputStyle, setInputStyle] = useState({});

    const onInputChage = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(e.target.value);
      onChange(e.target.value);
    };

    const onInputBlur = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setFocus(false);
      if (e.target.value) {
        setLabelStyle([style.placeholder, style.label]);
      } else {
        setLabelStyle([style.placeholder, style.empty]);
      }
    };

    const onInputFocus = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setFocus(true);
      if (!e.target.value) {
        setLabelStyle([style.placeholder, style.focus]);
      }
    };

    const onClickInput = () => {
      setFocus(true);
    };

    useEffect(() => {
      if (focus || value) {
        setInputStyle({ ...inputStyle, bottom: '4px', height: '52px' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focus, value]);

    return (
      <>
        <div
          style={error ? { border: '1px solid rgba(240, 68, 56, 1)' } : {}}
          onClick={onClickInput}
          className={focus ? `${style.input} ${style.inputFocus}` : style.input}
          aria-hidden="true"
        >
          <textarea
            rows={2}
            style={inputStyle}
            value={value}
            id={placeholder}
            onChange={onInputChage}
            onBlur={onInputBlur}
            onFocus={onInputFocus}
            ref={ref}
          />
          <label
            style={{ color: error ? '#F04438' : '' }}
            className={labelStyle.join(' ')}
            htmlFor={placeholder}
          >
            {placeholder}
          </label>
          <span />
        </div>
        <div className={style.errorText}>{error && 'Error message text'}</div>
      </>
    );
  },
);

export default CustomTextArea;

CustomTextArea.defaultProps = {
  error: false,
  onChange: () => {},
};
