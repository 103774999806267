import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { eventData } from 'src/helpers/static-data';
import style from './EventInfo.module.scss';

type PropsType = {
  isPaid?: boolean;
};

const EventInfo: FC<PropsType> = ({ isPaid }) => {
  const { t } = useTranslation();
  const { name, comment, currency, total, available, date } = eventData;
  return (
    <div className={style.eventInfo} style={{ height: isPaid ? '214px' : '' }}>
      <div className={style.user}>
        {!isPaid ? (
          <p>
            {t('sideBlock.PayBillSide.billInfo.user')} <span>{name}</span>{' '}
            {t('sideBlock.PayBillSide.billInfo.payment')}
          </p>
        ) : (
          <p>
            Произведена оплата от <span>{name}</span> по счету от{' '}
            <span>{date}</span>{' '}
          </p>
        )}
      </div>
      <div className={style.comment}>
        <p>
          {t('sideBlock.PayBillSide.billInfo.comment')}: <br />{' '}
          <span>{comment}</span>
        </p>
      </div>
      <div className={style.currency}>
        <p>
          {t('sideBlock.PayBillSide.billInfo.currency')}:{' '}
          <span>{currency}</span>
        </p>
        <p className={style.total}>
          {t('sideBlock.PayBillSide.billInfo.total')}: <span>{total}</span>
        </p>
      </div>
      {!isPaid && (
        <div className={style.available}>
          <p>
            {t('sideBlock.PayBillSide.billInfo.available')}{' '}
            <span>{available}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default EventInfo;

EventInfo.defaultProps = {
  isPaid: false,
};
