import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import QRCode from 'react-qr-code';
import { AuthContext } from 'src/reducers/AuthReducer';
import style from './QrCode.module.scss';

const QrCode = () => {
  const authCtx = useContext(AuthContext);
  const { auth } = authCtx;
  const { address } = auth;
  return (
    <div className={style.qrCoreBlock}>
      <QRCode
        size={172}
        style={{
          height: 172,
          maxWidth: '100%',
        }}
        viewBox="0 0 172 172"
        value={address}
      />
    </div>
  );
};

export default QrCode;
