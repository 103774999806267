import { FC, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ActionsBlock from './ActionsBlock/ActionsBlock';
import BannerBlock from './BannerBlock/BannerBlock';
import Card from './Card/Card';
import InvestBlock from './InvestBlock/InvestBlock';
import style from './MyScore.module.scss';
import Transactions from './Transactions/Transactions';

type PropsType = {
  children?: ReactNode;
};

const MyScore: FC<PropsType> = () => {
  const navigate = useNavigate();

  useEffect(
    () => {
      // check if user is logged in
      // if not, redirect to login page
      const address = localStorage.getItem('address');
      if (!address) {
        navigate('/authentication');
      }

      // get user data
      // if user data is not available, redirect to KYC page
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div className={style.myScore}>
      <Card />
      <ActionsBlock />
      <BannerBlock />
      <Transactions />
      <InvestBlock />
    </div>
  );
};

export default MyScore;
