export const DepositIcon = (): string => {
  return 'M26.6667 27.1106V29.3333H24V27.556L14.1093 29.204C14.0139 29.2198 13.9161 29.2147 13.8228 29.189C13.7295 29.1633 13.6429 29.1176 13.5691 29.055C13.4953 28.9925 13.4359 28.9146 13.3952 28.8268C13.3545 28.739 13.3334 28.6434 13.3333 28.5466V26.6666H8V29.3333H5.33334V26.6666H4.00001C3.64638 26.6666 3.30724 26.5262 3.0572 26.2761C2.80715 26.0261 2.66667 25.6869 2.66667 25.3333V5.33331C2.66667 4.97968 2.80715 4.64055 3.0572 4.3905C3.30724 4.14045 3.64638 3.99997 4.00001 3.99997H13.3333V2.11997C13.3334 2.0232 13.3545 1.9276 13.3952 1.83981C13.4359 1.75201 13.4953 1.67414 13.5691 1.61159C13.6429 1.54904 13.7295 1.50331 13.8228 1.47759C13.9161 1.45187 14.0139 1.44677 14.1093 1.46264L28.2187 3.81464C28.53 3.8664 28.8129 4.02692 29.017 4.26765C29.2211 4.50837 29.3332 4.8137 29.3333 5.12931V7.99997H30.6667V10.6666H29.3333V20H30.6667V22.6666H29.3333V25.5373C29.3332 25.8529 29.2211 26.1582 29.017 26.399C28.8129 26.6397 28.53 26.8002 28.2187 26.852L26.6667 27.1106ZM5.33334 6.66664V24H13.3333V6.66664H5.33334ZM16 26.1866L26.6667 24.408V6.25864L16 4.48131V26.1853V26.1866ZM22 18.6666C20.896 18.6666 20 17.1733 20 15.3333C20 13.4933 20.896 12 22 12C23.104 12 24 13.4933 24 15.3333C24 17.1733 23.104 18.6666 22 18.6666Z';
};

export const WalletIcon = (): string => {
  return 'M29.3333 8H20C17.8783 8 15.8434 8.84285 14.3432 10.3431C12.8429 11.8434 12 13.8783 12 16C12 18.1217 12.8429 20.1566 14.3432 21.6569C15.8434 23.1571 17.8783 24 20 24H29.3333V26.6667C29.3333 27.0203 29.1929 27.3594 28.9428 27.6095C28.6928 27.8595 28.3536 28 28 28H4.00001C3.64638 28 3.30724 27.8595 3.0572 27.6095C2.80715 27.3594 2.66667 27.0203 2.66667 26.6667V5.33333C2.66667 4.97971 2.80715 4.64057 3.0572 4.39052C3.30724 4.14048 3.64638 4 4.00001 4H28C28.3536 4 28.6928 4.14048 28.9428 4.39052C29.1929 4.64057 29.3333 4.97971 29.3333 5.33333V8ZM20 10.6667H30.6667V21.3333H20C18.5855 21.3333 17.229 20.7714 16.2288 19.7712C15.2286 18.771 14.6667 17.4145 14.6667 16C14.6667 14.5855 15.2286 13.229 16.2288 12.2288C17.229 11.2286 18.5855 10.6667 20 10.6667ZM20 14.6667V17.3333H24V14.6667H20Z';
};

export const CreditIcon = (): string => {
  return 'M23.3333 28C22.0957 28 20.9087 27.5083 20.0335 26.6332C19.1583 25.758 18.6667 24.571 18.6667 23.3333C18.6667 22.0957 19.1583 20.9087 20.0335 20.0335C20.9087 19.1583 22.0957 18.6667 23.3333 18.6667C24.571 18.6667 25.758 19.1583 26.6332 20.0335C27.5083 20.9087 28 22.0957 28 23.3333C28 24.571 27.5083 25.758 26.6332 26.6332C25.758 27.5083 24.571 28 23.3333 28ZM23.3333 25.3333C23.8638 25.3333 24.3725 25.1226 24.7475 24.7475C25.1226 24.3725 25.3333 23.8638 25.3333 23.3333C25.3333 22.8029 25.1226 22.2942 24.7475 21.9191C24.3725 21.544 23.8638 21.3333 23.3333 21.3333C22.8029 21.3333 22.2942 21.544 21.9191 21.9191C21.544 22.2942 21.3333 22.8029 21.3333 23.3333C21.3333 23.8638 21.544 24.3725 21.9191 24.7475C22.2942 25.1226 22.8029 25.3333 23.3333 25.3333ZM8.66667 13.3333C8.05383 13.3333 7.447 13.2126 6.88081 12.9781C6.31462 12.7436 5.80017 12.3998 5.36683 11.9665C4.93349 11.5332 4.58975 11.0187 4.35523 10.4525C4.12071 9.88634 4 9.2795 4 8.66667C4 8.05383 4.12071 7.447 4.35523 6.88081C4.58975 6.31462 4.93349 5.80018 5.36683 5.36684C5.80017 4.9335 6.31462 4.58975 6.88081 4.35523C7.447 4.12071 8.05383 4 8.66667 4C9.90434 4 11.0913 4.49167 11.9665 5.36684C12.8417 6.24201 13.3333 7.42899 13.3333 8.66667C13.3333 9.90434 12.8417 11.0913 11.9665 11.9665C11.0913 12.8417 9.90434 13.3333 8.66667 13.3333ZM8.66667 10.6667C9.1971 10.6667 9.70581 10.456 10.0809 10.0809C10.456 9.70581 10.6667 9.1971 10.6667 8.66667C10.6667 8.13623 10.456 7.62753 10.0809 7.25245C9.70581 6.87738 9.1971 6.66667 8.66667 6.66667C8.13623 6.66667 7.62753 6.87738 7.25245 7.25245C6.87738 7.62753 6.66667 8.13623 6.66667 8.66667C6.66667 9.1971 6.87738 9.70581 7.25245 10.0809C7.62753 10.456 8.13623 10.6667 8.66667 10.6667ZM25.428 4.68667L27.3133 6.572L6.57333 27.3133L4.688 25.428L25.4267 4.68667H25.428Z';
};

export const ExchangeIcon = (): string => {
  return 'M16 29.3332C8.63601 29.3332 2.66667 23.3638 2.66667 15.9998C2.66667 8.63584 8.63601 2.6665 16 2.6665C23.364 2.6665 29.3333 8.63584 29.3333 15.9998C29.3333 23.3638 23.364 29.3332 16 29.3332ZM16 26.6665C18.829 26.6665 21.5421 25.5427 23.5425 23.5423C25.5429 21.5419 26.6667 18.8288 26.6667 15.9998C26.6667 13.1709 25.5429 10.4578 23.5425 8.45737C21.5421 6.45698 18.829 5.33317 16 5.33317C13.171 5.33317 10.4579 6.45698 8.45753 8.45737C6.45714 10.4578 5.33334 13.1709 5.33334 15.9998C5.33334 18.8288 6.45714 21.5419 8.45753 23.5423C10.4579 25.5427 13.171 26.6665 16 26.6665ZM9.33334 11.9998L13.3333 7.33317L17.3333 11.9998H14.6667V17.3332H12V11.9998H9.33334ZM22.6667 19.9998L18.6667 24.6665L14.6667 19.9998H17.3333V14.6665H20V19.9998H22.6667Z';
};

export const NotificationsIcon = (): string => {
  return 'M26.6667 22.6665H29.3333V25.3332H2.66667V22.6665H5.33334V13.3332C5.33334 10.5042 6.45714 7.79109 8.45753 5.7907C10.4579 3.79031 13.171 2.6665 16 2.6665C18.829 2.6665 21.5421 3.79031 23.5425 5.7907C25.5429 7.79109 26.6667 10.5042 26.6667 13.3332V22.6665ZM24 22.6665V13.3332C24 11.2114 23.1572 9.17661 21.6569 7.67632C20.1566 6.17603 18.1217 5.33317 16 5.33317C13.8783 5.33317 11.8434 6.17603 10.3432 7.67632C8.84286 9.17661 8.00001 11.2114 8.00001 13.3332V22.6665H24ZM12 27.9998H20V30.6665H12V27.9998Z';
};
