import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DealsBlock from '../DealsBlock/DealsBlock';
import Distance from '../Distance/Distance';
import style from './DealInfo.module.scss';

type PropsType = {
  userName: string;
  deals: number;
  performed: number;
  distance: number;
  pay: number;
  get: number;
};

const DealInfo: FC<PropsType> = ({
  deals,
  performed,
  userName,
  distance,
  pay,
  get,
}) => {
  const { t } = useTranslation();
  return (
    <div className={style.dealInfo}>
      <div className={style.header}>
        {t('Exchange.dealInfo.user')} <span>{userName}</span>
      </div>
      <DealsBlock deals={deals} performed={performed} />
      <div className={style.top}>
        <Distance distance={distance} />
      </div>
      <div className={style.total}>
        <p>{t('Exchange.dealInfo.pay')}</p>
        <span>{pay} USD</span>
      </div>
      <div className={style.total}>
        <p>{t('Exchange.dealInfo.get')}</p>
        <span>{get} USD</span>
      </div>
    </div>
  );
};

export default DealInfo;
