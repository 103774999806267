import { FC } from 'react';
import { ChatItemType } from 'src/helpers/static-data';
import send from 'src/assets/images/send-message.svg';
import { useTranslation } from 'react-i18next';
import CustomInput from '../../CustomInput/CustomInput';
import style from './ChatList.module.scss';

type PropsType = {
  chat: ChatItemType;
  type?: 'exchange' | 'notifications';
};

const ChatList: FC<PropsType> = ({ chat, type }) => {
  const { t } = useTranslation();
  const { messages } = chat;
  const styled =
    type !== 'exchange'
      ? style.container
      : `${style.container} ${style.exchange}`;
  return (
    <div className={style.chat}>
      <div className={styled}>
        <div className={style.messages}>
          {messages.map(({ id, isMe, text, time }) => {
            const styles = !isMe
              ? style.item
              : `${style.item} ${style.myMessage}`;
            return (
              <div key={id} className={styles}>
                <p>{text}</p>
                <span>{time}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className={style.input}>
        <CustomInput placeholder={t('placeholder.message')} />
        <button className={style.sendMessage} type="button">
          <img src={send} alt="send" />
        </button>
      </div>
    </div>
  );
};

export default ChatList;

ChatList.defaultProps = {
  type: 'notifications',
};
