import { ChangeEvent, useState } from 'react';
import HeaderSide from 'src/components/HeaderSide/HeaderSide';
import map from 'src/assets/images/map.svg';
import exchange from 'src/assets/images/exchange.png';
import { ActiveOffersList, cities, currencies } from 'src/helpers/static-data';
import useUI from 'src/hooks/use-ui';
import { useTranslation } from 'react-i18next';
import ButtonLink from '../ButtonLink/ButtonLink';
import CustomInput from '../CustomInput/CustomInput';
import CustomSelect from '../CustomSelect/CustomSelect';
import CustomTextArea from '../CustomTextArea/CustomTextArea';
import EmptyList from '../EmptyList/EmptyList';
import ActiveOffers from '../ActiveOffers/ActiveOffers';

import style from './FiatCryptoSide.module.scss';

const FiatCryptoSide = () => {
  const { t } = useTranslation();
  const { setSidePopup, setIsMap } = useUI();
  const [currency, setCurrency] = useState('usd');
  const [city, setCity] = useState('minsk');

  const onHeaderClick = () => {
    setSidePopup(null);
  };

  const onLoanTermChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrency(e.target.value);
  };

  const onCityChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCity(e.target.value);
  };

  const toggleMap = () => {
    setIsMap();
  };

  return (
    <div className={style.fiatCryptoSide}>
      <HeaderSide
        title={t('sideBlock.FiatCrypto.header')}
        onHeaderClick={onHeaderClick}
      />
      <div className={style.container}>
        <div className={style.fiatBlock}>
          <CustomSelect
            onChange={onLoanTermChange}
            defaulf={currency}
            items={currencies}
            placeholder={t('placeholder.currency')}
          />
          <CustomInput
            placeholder={t('placeholder.pledgeAmount')}
            type="number"
          />
          <CustomSelect
            onChange={onCityChange}
            defaulf={city}
            items={cities}
            placeholder={t('placeholder.city')}
          />
          <div className={style.mapLink}>
            <ButtonLink
              title={t('buttonsLink.map')}
              icon={map}
              onClick={toggleMap}
            />
          </div>
          <div style={{ marginTop: '32px' }} />
          <CustomTextArea placeholder={t('placeholder.wishes')} />
          <div className={style.description}>
            {t('sideBlock.FiatCrypto.text')}
          </div>
        </div>
      </div>
      {!ActiveOffersList.length ? (
        <div className={style.empty}>
          <EmptyList
            icon={exchange}
            title={t('sideBlock.FiatCrypto.empty.title')}
            description={t('sideBlock.FiatCrypto.empty.description')}
          />
        </div>
      ) : (
        <ActiveOffers />
      )}
    </div>
  );
};

export default FiatCryptoSide;
