import { useTranslation } from 'react-i18next';
import investments from 'src/assets/images/myScore/investments.png';
import { investTotal } from 'src/helpers/static-data';
import EmptyList from '../EmptyList/EmptyList';
import InvestTabs from '../InvestTabs/InvestTabs';
import style from './InvestData.module.scss';

const InvestData = () => {
  const { t } = useTranslation();
  const date = '17.05.2022';

  return (
    <div
      className={style.investData}
      style={investTotal ? {} : { justifyContent: 'flex-start' }}
    >
      <div>
        <div className={style.header}>
          <div className={style.title}>
            {t('MyScore.InvestBlock.InvestData.invest')}
          </div>
          {investTotal && <InvestTabs />}
        </div>
        {investTotal && (
          <>
            <div className={style.investData__total}>
              <p>
                {investTotal.total} <span>+{investTotal.rate}%</span>
              </p>
            </div>
            <div className={style.investData__date}>{date}</div>
          </>
        )}
      </div>
      {investTotal && (
        <div className={style.graph}>
          <div className={style.graphTotal}>{investTotal.total}</div>
        </div>
      )}
      {!investTotal && (
        <EmptyList
          icon={investments}
          title={t('MyScore.InvestBlock.InvestData.empty.header')}
          description={t('MyScore.InvestBlock.InvestData.empty.text')}
        />
      )}
    </div>
  );
};

export default InvestData;
