import logo from 'src/assets/images/authentications/X11_bank.svg';
import close from 'src/assets/images/close.svg';
import useUI from 'src/hooks/use-ui';
import Point from './Point/Point';

import style from './MapExchangers.module.scss';

const MapExchangers = () => {
  const { setIsMap } = useUI();

  const onCloseMap = () => {
    setIsMap();
  };

  return (
    <div className={style.container}>
      <div className={style.borderContainer}>
        <img
          className={style.logo}
          src={logo}
          alt="logo_x11"
          aria-hidden="true"
        />
        <div className={style.closeBtn} onClick={onCloseMap} aria-hidden="true">
          <span>Закрыть</span>
          <div className={style.icon}>
            <img src={close} alt="close" />
          </div>
        </div>
        <div className={style.map}>
          <div className={style.pointMap}>
            <Point />
          </div>
          <div className={style.pointMap}>
            <Point />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapExchangers;
