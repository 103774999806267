import { FC, ReactNode } from 'react';
import ActionButton from 'src/components/ActionButton/ActionButton';
import topUp from 'src/assets/images/myScore/action-add.svg';
import send from 'src/assets/images/myScore/action-send.svg';
import bill from 'src/assets/images/myScore/action-bill.svg';
import useUI from 'src/hooks/use-ui';
import { useTranslation } from 'react-i18next';
import style from './ActionsBlock.module.scss';

type PropsType = {
  children?: ReactNode;
};

const ActionsBlock: FC<PropsType> = () => {
  const { t } = useTranslation();
  const { setSidePopup } = useUI();

  const onTopUpClick = () => {
    setSidePopup('CurrenciesList');
  };

  const onSendClick = () => {
    setSidePopup('CreateTransfer');
  };

  const onBillClick = () => {
    setSidePopup('Bill');
  };

  return (
    <div className={style.actionsBlock}>
      <ActionButton
        icon={topUp}
        onClick={onTopUpClick}
        title={t('MyScore.ActionsBlock.topUp')}
      />
      <ActionButton
        icon={send}
        onClick={onSendClick}
        title={t('MyScore.ActionsBlock.send')}
      />
      <ActionButton
        icon={bill}
        onClick={onBillClick}
        title={t('MyScore.ActionsBlock.bill')}
      />
    </div>
  );
};

export default ActionsBlock;
