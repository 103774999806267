import { createContext } from 'react';
import { CurrencyType } from 'src/helpers/static-data';

export interface CurrencyState {
  currency: CurrencyType | null;
}

export const CurrencyInitialState: CurrencyState = {
  currency: null,
};

export const CurrencyReducer = (
  state: CurrencyState,
  action: ActionsType,
): CurrencyState => {
  switch (action.type) {
    case 'currency/x11-app/SET-CURRENCY': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export const setCurrencyAC = (currency: CurrencyType) =>
  ({
    type: 'currency/x11-app/SET-CURRENCY',
    payload: { currency },
  } as const);

type ActionsType = ReturnType<typeof setCurrencyAC>;

interface CurrnecyContextValue extends CurrencyState {
  setCurrency: (currency: CurrencyType) => void;
}

export const CurrencyContext = createContext<CurrnecyContextValue>({
  ...CurrencyInitialState,
  setCurrency: () => {},
});
