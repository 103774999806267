import { FC } from 'react';
import style from './ActionButton.module.scss';

type PropsType = {
  title: string;
  icon: string;
  onClick: () => void;
};

const ActionButton: FC<PropsType> = ({ icon, title, onClick }) => {
  return (
    <div className={style.actionButton} onClick={onClick} aria-hidden="true">
      <div className={style.actionImage}>
        <img src={icon} alt={title} />
      </div>
      <span>{title}</span>
    </div>
  );
};

export default ActionButton;
