import { FC } from 'react';
import style from './Devider.module.scss';

type PropsType = {
  styles: {
    [key: string]: string;
  };
};

const Devider: FC<PropsType> = ({ styles }) => {
  return <div style={styles} className={style.devider} />;
};

export default Devider;
