import { FC } from 'react';
import { CurrencyType } from 'src/helpers/static-data';
import style from './CurrencyItem.module.scss';

type PropsType = {
  onClick?: () => void;
  item: CurrencyType;
};

const CurrencyItem: FC<PropsType> = ({ onClick, item }) => {
  const { icon, id, title } = item;
  return (
    <div className={style.currency} onClick={onClick} aria-hidden="true">
      <img src={icon} alt={title} />
      <div className={style.currencyName}>
        <p>
          {title} <span>{id}</span>
        </p>
      </div>
    </div>
  );
};

export default CurrencyItem;

CurrencyItem.defaultProps = {
  onClick: () => {},
};
