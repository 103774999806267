import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import user from 'src/assets/images/user-map.svg';
import style from './Distance.module.scss';

type PropsType = {
  distance: number;
};

const Distance: FC<PropsType> = ({ distance }) => {
  const { t } = useTranslation();
  return (
    <div className={style.distance}>
      <img src={user} alt="user" />
      <span>
        {distance} {t('buttons.distance')}
      </span>
    </div>
  );
};

export default Distance;
