import useOffer from 'src/hooks/use-offer';
import useUI from 'src/hooks/use-ui';
import exchange from 'src/assets/images/exchange.png';
import { useEffect, useState } from 'react';
import { chatsList } from 'src/helpers/static-data';
import { useTranslation } from 'react-i18next';
import HeaderSide from '../HeaderSide/HeaderSide';
import EmptyList from '../EmptyList/EmptyList';
import CustomButton from '../CustomButton/CustomButton';
import ChatList from '../ChatCurrent/ChatList/ChatList';

import style from './Offer.module.scss';
import DealInfo from '../DealInfo/DealInfo';

const Offer = () => {
  const { t } = useTranslation();
  const { setSubSidePopup } = useUI();
  const { offer } = useOffer();
  const [isSend, setIsSend] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [currentChat, setCurrentChat] = useState(null);
  const [isSmall, setIsSmall] = useState(true);

  const onHeaderClick = () => {
    setSubSidePopup(null);
  };

  const onExchangeClick = () => {
    setIsSend(!isSend);
  };

  const onOfferClick = () => {
    if (isSend) {
      setIsSuccess(!isSuccess);
      const findChat = chatsList.find((el) => el.id === offer.id);
      if (findChat) {
        setCurrentChat(findChat);
      }
    }
  };

  const closeChat = () => {
    console.log('Открыть диспут');
  };

  useEffect(() => {
    if (currentChat && isSuccess) {
      const h = document.getElementById('chatOffer')?.clientHeight;
      if (h > 542) {
        setIsSmall(false);
      } else {
        setIsSmall(true);
      }
    }
  }, [currentChat, isSuccess]);

  return (
    <div className={style.offerSide}>
      <HeaderSide
        title={`${offer.userName} (USDT)`}
        onHeaderClick={onHeaderClick}
      />
      <div
        className={style.offerBlock}
        onClick={onOfferClick}
        aria-hidden="true"
      >
        <DealInfo
          deals={offer.deals}
          performed={offer.performed}
          distance={offer.distance}
          userName={offer.userName}
          get={150}
          pay={150}
        />
        {isSuccess && (
          <div className={style.open}>
            <CustomButton
              styled={{ color: ' #D92D20', borderColor: '#D92D20' }}
              title={t('buttons.openDispute')}
              type="secondary"
              onClick={closeChat}
            />
          </div>
        )}
      </div>
      {currentChat && (
        <div
          id="chatOffer"
          className={style.chatBlock}
          style={{ height: isSmall ? '100%' : '542px' }}
        >
          <ChatList chat={currentChat} type="exchange" />
        </div>
      )}
      {!isSuccess && (
        <>
          <div className={style.empty}>
            <EmptyList
              icon={exchange}
              title={
                isSend
                  ? t('sideBlock.Offer.empty1.title')
                  : t('sideBlock.Offer.empty2.title')
              }
              description={
                isSend
                  ? `${t('sideBlock.Offer.empty1.description1')} ${
                      offer.userName
                    } ${t('sideBlock.Offer.empty1.description2')}`
                  : t('sideBlock.Offer.empty2.description')
              }
            />
          </div>
          <div className={style.button}>
            <CustomButton
              title={isSend ? t('buttons.cancelReq') : t('buttons.exchangeReq')}
              type={isSend ? 'secondary' : 'main'}
              onClick={onExchangeClick}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Offer;
