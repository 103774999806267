import { FC, ReactNode } from 'react';
import CreateButton from '../CreateButton/CreateButton';
import DescriptionButton from '../DescriptionButton/DescriptionButton';
import style from './ContainerButtons.module.scss';

type PropsType = {
  description: string;
  descriptionHeader: string;
  buttonName: string;
  buttonHeader: string;
  buttonDescription: string;
  onButtonClick: () => void;
  children?: ReactNode;
};

const ContainerButtons: FC<PropsType> = ({
  description,
  descriptionHeader,
  onButtonClick,
  buttonName,
  buttonHeader,
  buttonDescription,
  children,
}) => {
  return (
    <div className={style.createDeposit}>
      <DescriptionButton header={descriptionHeader} title={description} />
      <div className={style.depositCredits}>{children}</div>
      <CreateButton
        header={buttonHeader}
        description={buttonDescription}
        name={buttonName}
        onClick={onButtonClick}
      />
    </div>
  );
};

export default ContainerButtons;

ContainerButtons.defaultProps = {
  children: null,
};
