type InitialStateFormType = {
  [key: string]: {
    value: string;
    error: string;
  };
};

export const GOOGLE_FORM_ACTION_URL =
  'https://docs.google.com/forms/u/0/d/e/1FAIpQLSfwbMLy2Hj_k_JqReWZ0PH5w1z1V-XXiVX2yhGQpfkAqKZ2hg/formResponse';
export const validateEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const initialStateForm: InitialStateFormType = {
  'entry.1166533059': {
    value: '',
    error: '',
  },
  'entry.759167060': {
    value: '',
    error: '',
  },
  'entry.1232779119': {
    value: '',
    error: '',
  },
  'entry.1245571282': {
    value: '',
    error: '',
  },
};

export const formReducer = (
  state: InitialStateFormType,
  action: ActionsType,
): InitialStateFormType => {
  switch (action.type) {
    case 'x11-bank/google-form/CHANGE_ITEMS':
      return {
        ...state,
        [action.name]: {
          ...state?.[action.name],
          value: action.value,
          error: '',
        },
      };
    case 'x11-bank/google-form/CHANGE_EMAIL':
      return {
        ...state,
        [action.name]: {
          ...state?.[action.name],
          value: action.value,
        },
      };
    case 'x11-bank/google-form/SET_ERROR':
      return {
        ...state,
        [action.name]: { ...state?.[action.name], error: action.error },
      };
    case 'x11-bank/google-form/RESET_FORM':
      return {
        ...state,
        ...initialStateForm,
      };
    default:
      return state;
  }
};

export const changeInputValue = (value: string, name: string) =>
  ({
    type: 'x11-bank/google-form/CHANGE_ITEMS',
    value,
    name,
  } as const);

export const changeEmailValue = (value: string, name: string) =>
  ({
    type: 'x11-bank/google-form/CHANGE_EMAIL',
    value,
    name,
  } as const);

export const setErrorInput = (error: string, name: string) =>
  ({
    type: 'x11-bank/google-form/SET_ERROR',
    error,
    name,
  } as const);

export const resetForm = () =>
  ({ type: 'x11-bank/google-form/RESET_FORM' } as const);

type ActionsType =
  | ReturnType<typeof changeInputValue>
  | ReturnType<typeof changeEmailValue>
  | ReturnType<typeof resetForm>
  | ReturnType<typeof setErrorInput>;
