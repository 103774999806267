import { Outlet, RouteObject } from 'react-router-dom';
import Login from './components/Authentications/Login/Login';
import SidebarLayout from './components/SidebarLayout/SidebarLayout';
import Credit from './pages/Credit/Credit';
import Deposit from './pages/Deposit/Deposit';
import Exchange from './pages/Exchange/Exchange';
import Invest from './pages/Invest/Invest';
import Invoice from './pages/Invoice/Invoice';
import KYC from './pages/KYC/KYC';
import MyScore from './pages/MyScore/MyScore';
import Notifications from './pages/Notifications/Notifications';

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <MyScore />,
      },
      {
        path: '/invest',
        element: <Invest />,
      },
      {
        path: '/deposit',
        element: <Deposit />,
      },
      {
        path: '/credit',
        element: <Credit />,
      },
      {
        path: '/exchange',
        element: <Exchange />,
      },
      {
        path: '/notifications',
        element: <Notifications />,
      },
    ],
  },
  {
    path: '/kyc',
    element: <KYC />,
  },
  {
    path: 'authentication',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <Login />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'registration',
        element: <Login />,
        children: [
          {
            path: 'personal',
            element: <Login />,
          },
        ],
      },
    ],
  },
  { path: '/invoice', element: <Invoice /> },
  {
    path: '/:locale',
    element: <Outlet />,
    children: [
      { path: '/:locale/invoice', element: <Invoice /> },
      {
        path: '/:locale/authentication',
        element: <Login />,
      },
      {
        path: '/:locale/authentication/login',
        element: <Login />,
      },
      {
        path: '/:locale/authentication/registration',
        element: <Login />,
      },
      {
        path: '/:locale/authentication/personal',
        element: <Login />,
      },
      {
        path: '/:locale/kyc',
        element: <KYC />,
      },
    ],
  },
];

export default routes;
