import { useTranslation } from 'react-i18next';
import bg_card from 'src/assets/images/myScore/bg_banner.png';
import coins from 'src/assets/images/myScore/coins.png';
import CustomButton from 'src/components/CustomButton/CustomButton';
import { currencyX11 } from 'src/helpers/static-data';
import useCurrency from 'src/hooks/use-currency';
import useUI from 'src/hooks/use-ui';
import style from './BannerBlock.module.scss';

const BannerBlock = () => {
  const { t } = useTranslation();
  const { setSidePopup } = useUI();
  const { setCurrency } = useCurrency();

  const onStackingClick = () => {
    setSidePopup('Staking');
  };

  const onBuyClick = () => {
    setSidePopup('TopUpAccountX11');
    setCurrency(currencyX11);
  };

  return (
    <div className={style.bannerBlock}>
      <img className={style.bgCard} src={bg_card} alt="bg_card" />
      <img className={style.coins} src={coins} alt="coins" />
      <div className={style.banner}>
        <div className={style.bannerHeader}>
          {t('MyScore.BannerBlock.header')}
        </div>
        <div className={style.description}>
          {t('MyScore.BannerBlock.title')}
        </div>
        <div className={style.buttons}>
          <div className={style.button}>
            <CustomButton
              title={t('MyScore.BannerBlock.stake')}
              type="main"
              onClick={onStackingClick}
            />
          </div>
          <div className={style.button}>
            <CustomButton
              title={t('MyScore.BannerBlock.buy')}
              type="secondary"
              onClick={onBuyClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerBlock;
