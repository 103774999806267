import { FC, ReactNode } from 'react';
import ActiveManagement from 'src/components/ActiveManagement/ActiveManagement';
import ButtonBack from 'src/components/ButtonBack/ButtonBack';
import InvestDiagram from 'src/components/InvestDiagram/InvestDiagram';
import StatisticBlock from 'src/components/StatisticBlock/StatisticBlock';
import InvestCurrencyItem from 'src/components/InvestCurrencyItem/InvestCurrencyItem';
import { currency } from 'src/helpers/static-data';
import useUI from 'src/hooks/use-ui';
import style from './Invest.module.scss';

type PropsType = {
  children?: ReactNode;
};

const Invest: FC<PropsType> = () => {
  const { setSidePopup } = useUI();
  const onFundClick = () => {
    setSidePopup('HedgeFund');
  };
  return (
    <div className={style.invest}>
      <ButtonBack />
      <div className={style.left}>
        <StatisticBlock />
        <InvestDiagram />
      </div>
      <div className={style.right}>
        <div className={style.funds} onClick={onFundClick} aria-hidden="true">
          <ActiveManagement />
        </div>
        <div className={style.currencies}>
          {currency.map((el) => (
            <InvestCurrencyItem key={el.id} item={el} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Invest;
