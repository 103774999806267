import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { depositCredits } from 'src/helpers/static-data';
import useUI from 'src/hooks/use-ui';
import ButtonAnte from '../ButtonAnte/ButtonAnte';
import ContainerButtons from '../ContainerButtons/ContainerButtons';

type PropsType = {
  children?: ReactNode;
};

const CreateCredit: FC<PropsType> = () => {
  const { t } = useTranslation();
  const { setSidePopup } = useUI();
  const onCreateClick = () => {
    setSidePopup('CreditsList');
  };

  const onClick = () => {
    setSidePopup('LoanOffer');
  };

  const total = '10 500';

  return (
    <ContainerButtons
      descriptionHeader={t('Credit.createBlock.descriptionHeader')}
      description={t('Credit.createBlock.description')}
      buttonHeader={t('Credit.createBlock.buttonDescription')}
      buttonDescription={t('Credit.createBlock.buttonHeader')}
      buttonName={t('buttons.choose')}
      onButtonClick={onCreateClick}
    >
      <>
        <ButtonAnte add={t('buttons.create')} onClickButton={onClick} />
        {depositCredits.map(({ month, rate }) => {
          const creditCreate = () => {
            console.log(month, rate);
          };
          return (
            <ButtonAnte
              key={month}
              month={`${month} ${t('buttons.month')}`}
              rate={`${rate}%`}
              onClickButton={creditCreate}
            />
          );
        })}
      </>
    </ContainerButtons>
  );
};

export default CreateCredit;
