import { useTranslation } from 'react-i18next';
import style from './InfiniteLine.module.scss';

const InfiniteLine = () => {
  const { t, i18n } = useTranslation();

  const text = Array(3).fill(`${t('MyScore.Card.account')}`);
  const styled = i18n.language === 'en' ? { padding: '0 22px' } : {};

  return (
    <div className={style.infiniteLine}>
      <div className={style.text}>
        {text.map((el) => (
          <span style={styled}>{el}</span>
        ))}
      </div>
      <div className={style.text1}>
        {text.map((el) => (
          <span style={styled} key={el}>
            {el}
          </span>
        ))}
      </div>
    </div>
  );
};

export default InfiniteLine;
