import { useMemo, useCallback, useReducer, ReactNode } from 'react';
import { CurrencyType } from 'src/helpers/static-data';
import {
  CurrencyContext,
  CurrencyInitialState,
  CurrencyReducer,
  setCurrencyAC,
} from 'src/reducers/CurrencyReducer';

type PropsType = {
  children: ReactNode;
};

const CurrencyProvider = (props: PropsType) => {
  const { children } = props;
  const [state, dispatch] = useReducer(CurrencyReducer, CurrencyInitialState);

  const setCurrency = useCallback((currency: CurrencyType) => {
    dispatch(setCurrencyAC(currency));
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      setCurrency,
    }),
    [state, setCurrency],
  );

  return (
    <CurrencyContext.Provider value={contextValue}>
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
