import style from './Point.module.scss';

const Point = () => {
  return (
    <div className={style.point}>
      <div className={style.dots}>
        <div className={style.dotsBg} />
      </div>
      <div className={style.title}>1 USDT/1.03 USD</div>
    </div>
  );
};

export default Point;
