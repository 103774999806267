import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import performedPercent from 'src/helpers/performedPercent';
import style from './DealsBlock.module.scss';

type PropsType = {
  deals: number;
  performed: number;
};

const DealsBlock: FC<PropsType> = ({ deals, performed }) => {
  const { t } = useTranslation();
  return (
    <div className={style.deals}>
      <div className={style.total}>
        {t('Exchange.dealInfo.deals')}: <span>{deals}</span>
      </div>
      <div className={style.performed}>
        {t('Exchange.dealInfo.performed')}:{' '}
        <span>{performedPercent(deals, performed)}%</span>
      </div>
    </div>
  );
};

export default DealsBlock;
