import { Web3Auth } from '@web3auth/modal';
import { createContext } from 'react';

export type AuthType = {
  isAuth: boolean;
  address: string;
  balance: number;
  balanceUSDC: number;
  balanceUSDT: number;
  chainId: string;
  w3Auth: Web3Auth;
  provider: any;
};

export interface AuthState {
  auth: AuthType | null;
}

const initialAddress: string = localStorage.getItem('address') || '0x000';
const initialBalance: string = localStorage.getItem('balance') || '0';
const initialBalanceUSDC: string = localStorage.getItem('balanceUSDC') || '0';
const initialBalanceUSDT: string = localStorage.getItem('balanceUSDT') || '0';

export const AuthInitialState: AuthState = {
  auth: {
    isAuth: true,
    address: initialAddress,
    balance: parseFloat(initialBalance),
    balanceUSDC: parseFloat(initialBalanceUSDC),
    balanceUSDT: parseFloat(initialBalanceUSDT),
    chainId: '0x5',
    w3Auth: null,
    provider: null,
  },
};

type ActionsType =
  | ReturnType<typeof setAuthAC>
  | ReturnType<typeof initAuthAC>
  | ReturnType<typeof setAddressAC>
  | ReturnType<typeof setBalanceAC>
  | ReturnType<typeof setBalanceUSDCAC>
  | ReturnType<typeof setBalanceUSDTAC>;

export const AuthReducer = (
  state: AuthState,
  action: ActionsType,
): AuthState => {
  switch (action.type) {
    case 'auth/x11-app/SET-AUTH': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'auth/x11-app/INIT': {
      // init(state);
      return {
        ...state,
      };
    }
    case 'auth/x11-app/SET-BALANCE': {
      console.log('action.payload: ', action.payload);
      // eslint-disable-next-line no-param-reassign
      state.auth.balance = action.payload.balance;
      localStorage.setItem('balance', state.auth.balance.toString());
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'auth/x11-app/SET-BALANCE-USDC': {
      console.log('action.payload: ', action.payload);
      // eslint-disable-next-line no-param-reassign
      state.auth.balanceUSDC = action.payload.balance;
      localStorage.setItem('balanceUSDC', state.auth.balanceUSDC.toString());
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'auth/x11-app/SET-BALANCE-USDT': {
      console.log('action.payload: ', action.payload);
      // eslint-disable-next-line no-param-reassign
      state.auth.balanceUSDT = action.payload.balance;
      localStorage.setItem('balanceUSDT', state.auth.balanceUSDT.toString());
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'auth/x11-app/SET-ADDRESS': {
      console.log('SET-ADDRESS:', state);
      // eslint-disable-next-line no-param-reassign
      state.auth.address = action.payload.address;
      console.log('address: ', state.auth);
      // localStorage.setItem('auth', JSON.stringify(state.auth));
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export const setAuthAC = (auth: AuthType) =>
  ({
    type: 'auth/x11-app/SET-AUTH',
    payload: { auth },
  } as const);

export const initAuthAC = (auth: AuthType) =>
  ({
    type: 'auth/x11-app/INIT',
    payload: { auth },
  } as const);

export const setAddressAC = (address: string) =>
  ({
    type: 'auth/x11-app/SET-ADDRESS',
    payload: { address },
  } as const);

export const setBalanceAC = (balance: number) =>
  ({
    type: 'auth/x11-app/SET-BALANCE',
    payload: { balance },
  } as const);

export const setBalanceUSDCAC = (balance: number) =>
  ({
    type: 'auth/x11-app/SET-BALANCE-USDC',
    payload: { balance },
  } as const);

export const setBalanceUSDTAC = (balance: number) =>
  ({
    type: 'auth/x11-app/SET-BALANCE-USDT',
    payload: { balance },
  } as const);

interface AuthContextValue extends AuthState {
  setAuth: (auth: AuthType) => void;
  initAuth: (auth: AuthType) => void;
  setAddress: (address: string) => void;
  setBalance: (balance: number) => void;
  setBalanceUSDC: (balance: number) => void;
  setBalanceUSDT: (balance: number) => void;
}

export const AuthContext = createContext<AuthContextValue>({
  ...AuthInitialState,
  setAuth: () => {},
  initAuth: () => {},
  setAddress: () => {},
  setBalance: () => {},
  setBalanceUSDC: () => {},
  setBalanceUSDT: () => {},
});
