import bnb from 'src/assets/images/currency/bnb.svg';
import btc from 'src/assets/images/currency/btc.svg';
import usdt from 'src/assets/images/currency/usdt.svg';
import eth from 'src/assets/images/currency/eth.svg';
import x11 from 'src/assets/images/currency/x11.svg';
import add from 'src/assets/images/transactions/add.svg';
import change from 'src/assets/images/transactions/change.svg';
import deposit from 'src/assets/images/transactions/deposit.svg';
import withdrawal from 'src/assets/images/transactions/withdrawal.svg';
import { CreditType } from 'src/reducers/CreditReducer';

export type CurrencyType = {
  id: string;
  icon: string;
  title: string;
  total: string;
  usd: string;
  rate: string;
};

export const address = '0xc65e32b538d88e0a77b5ea70222d21c4e4adb12e';

export const isMobile = navigator.userAgent.match(
  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i,
);

export const eventData = {
  name: 'John Taylor',
  date: '23.02.2022',
  comment: 'Some comment',
  currency: 'USDT',
  total: '2 300',
  available: '6 664.45 USDT',
};

export const user = {
  name: 'Your Name',
  birthDate: '12.08.1986',
  address: 'Row Avenue, 22, Manchester',
  phone: '+380800205205',
  email: 'johntay@gmail.com',
  role: 'changer',
};

export const numbsCard = [
  { total: '233', currency: 'USDT' },
  { total: '1 000', currency: 'USDC' },
  { total: '2 124', currency: 'CADC' },
];

export const cardTotal = {
  credit: 0,
  total: 5236,
};

export const activeFund = {
  total: '0 USD',
  rate: 32,
};

export const investTotal = {
  total: '0 USD',
  rate: 345,
};

export const datePeriod = {
  start: '22.02.2022',
  end: '14.06.2022',
};

export const tableRate: RateType[] = [
  { min: 1, max: 5, rate: 5 },
  { min: 5, max: 15, rate: 8 },
  { min: 15, max: 30, rate: 10 },
  { min: 30, max: 50, rate: 15 },
  { min: 50, max: 70, rate: 20 },
  { min: 0, max: 70, rate: 30 },
];

export type RateType = {
  min: number;
  max: number;
  rate: number;
};

export const currencyX11: CurrencyType = {
  id: 'xgrp',
  icon: x11,
  title: 'X11',
  total: '0',
  usd: '0 USD',
  rate: '+10,01%',
};

export const currency: CurrencyType[] = [
  {
    id: 'BNB',
    icon: bnb,
    title: 'Binance Coin',
    total: '0',
    usd: '0 USD',
    rate: '+10,01%',
  },
  {
    id: 'BTC',
    icon: btc,
    title: 'Bitcoin',
    total: '0',
    usd: '0 USD',
    rate: '+12,35%',
  },
  {
    id: 'USDT',
    icon: usdt,
    title: 'Tether',
    total: '0',
    usd: '0 USD',
    rate: '+0,35%',
  },
  {
    id: 'ETH',
    icon: eth,
    title: 'Ethereum',
    total: '0',
    usd: '0 USD',
    rate: '+35,78%',
  },
];

export const transactionsList = [
  {
    type: 'add',
    title: 'Top up',
    icons: add,
    currency: 'ETH',
    total: '$0',
    date: '12.03.2022',
  },
  {
    type: 'withdrawal',
    title: 'Withdrawal',
    icons: withdrawal,
    currency: 'BTC',
    total: '$0',
    date: '12.03.2022',
  },
  {
    type: 'deposit',
    title: 'Deposit',
    icons: deposit,
    currency: 'USD',
    total: '$0',
    date: '12.03.2022',
  },
  {
    type: 'add',
    title: 'Top up',
    icons: add,
    currency: 'ETH',
    total: '+$0',
    date: '12.03.2022',
  },
  {
    type: 'change',
    title: 'Exchange',
    icons: change,
    currency: 'ETH/USDT',
    total: '$0',
    date: '12.03.2022',
  },
  {
    type: 'add',
    title: 'Top up',
    icons: add,
    currency: 'BTC',
    total: '$0',
    date: '12.03.2022',
  },
  {
    type: 'add',
    title: 'Top up',
    icons: add,
    currency: 'BTC',
    total: '$0',
    date: '12.03.2022',
  },
  {
    type: 'change',
    title: 'Exchange',
    icons: change,
    currency: 'ETH/USDT',
    total: '$0',
    date: '10.03.2022',
  },
];

export const depositCredits = [
  { month: 3, rate: 3.5 },
  { month: 6, rate: 5 },
  { month: 12, rate: 8 },
];

export const creditsList: CreditType[] = [
  { id: '1', rate: 8, month: 12, availableCredit: 100000, isDisabled: true },
  { id: '2', rate: 8.1, month: 12, availableCredit: 100000, isDisabled: false },
  { id: '3', rate: 9, month: 12, availableCredit: 150000, isDisabled: false },
  { id: '4', rate: 9.3, month: 12, availableCredit: 250000, isDisabled: false },
];

export const activeLoans: ActiveLoansType[] = [
  { id: '1', month: 12, user: 'Neal Turner', credit: 2100, rate: 8.5 },
  { id: '2', month: 8, user: 'Mr. Dree', credit: 1100, rate: 9 },
];

export const myLoans: ActiveLoansType[] = [
  {
    id: '1',
    month: 12,
    user: '135 USD/month till 05.05.2023',
    credit: 2100,
    rate: 8.5,
  },
];

export type ActiveLoansType = {
  id: string;
  month: number;
  user: string;
  credit: number;
  rate: number;
};

export type NotificationsType = {
  id: string;
  time: string;
  message: string;
  header: string;
  isActive: boolean;
};

export const notifications: NotificationsType[] = [
  {
    id: '1',
    time: '12:53',
    message: 'Exchange successfully completed. You received 150 USDT.',
    header: 'Deal status updated',
    isActive: true,
  },
  {
    id: '2',
    time: '12:51',
    message: 'You received an invoice for 3000 USDT from Steven Snow.',
    header: 'Dispute opened',
    isActive: true,
  },
  {
    id: '3',
    time: '12:41',
    message: 'You received an invoice for 3000 USDT from Steven Snow.',
    header: 'Received an invoice',
    isActive: false,
  },
  {
    id: '4',
    time: '12:35',
    message: 'You received an invoice for 3000 USDT from Steven Snow.',
    header: 'Received an invoice',
    isActive: false,
  },
];

export const infoChats = {
  newMessage: 3,
  chats: 25,
};

export type ChatItemType = {
  id: string;
  time: string;
  chatName: string;
  isActive: boolean;
  userName: string;
  messages: MessageType[];
};

export type MessageType = {
  id: string;
  time: string;
  text: string;
  isMe: boolean;
};

export const chatsList: ChatItemType[] = [
  {
    id: '1',
    time: '12:35',
    chatName: 'Exchange with Leo Jordan',
    isActive: true,
    userName: 'Leo Jordan',
    messages: [
      {
        id: '1',
        text: 'Hello, in which district are you located?',
        isMe: false,
        time: '12:53',
      },
      {
        id: '2',
        text: 'Hi, Miles Square',
        isMe: true,
        time: '12:54',
      },
      {
        id: '3',
        text: 'When could we meet?',
        isMe: false,
        time: '12:54',
      },
      {
        id: '4',
        text: '12',
        isMe: true,
        time: '12:54',
      },
    ],
  },
  {
    id: '2',
    time: '12:25',
    chatName: 'Dispute with Mark Butcher',
    isActive: true,
    userName: 'Mark Butcher',
    messages: [
      {
        id: '1',
        text: 'Hello, in which district are you located?',
        isMe: false,
        time: '12:53',
      },
      {
        id: '2',
        text: 'Hi, Miles Square',
        isMe: true,
        time: '12:54',
      },
      {
        id: '3',
        text: 'When could we meet?',
        isMe: false,
        time: '12:54',
      },
    ],
  },
  {
    id: '3',
    time: '12:21',
    chatName: 'Exchange with Peter Rompler',
    isActive: false,
    userName: 'Peter Rompler',
    messages: [],
  },
];

export const translateNotifications = {
  transform: 'translateX(0px)',
  transition: '0.3s ease-in',
};

export const languages: SelectType[] = [
  { value: 'ru', label: 'Русский' },
  { value: 'en', label: 'English' },
];

export const currencies: SelectType[] = [
  // { value: 'eth', label: 'ETH' },
  { value: 'usdt', label: 'USDT' },
  { value: 'usdc', label: 'USDC' },
];

export const networks: SelectType[] = [
  { value: 'eth', label: 'Ethereum (ERC20)' },
];

export type SelectType = {
  value: string;
  label: string;
};

export const loanTerms: SelectType[] = [
  { label: 'Year', value: 'year' },
  { label: 'Month', value: 'month' },
];

export const cities: SelectType[] = [{ label: 'Dublin', value: 'dublin' }];

export const workDays: SelectType[] = [
  { label: 'Mn, Tu, Wd, Th, Fr', value: 'week' },
];

export const ActiveOffersList: ActiveOfferType[] = [
  {
    id: '1',
    userName: 'John Smith',
    deals: 1356,
    performed: 1356,
    distance: 1,
    usdt: 1,
    usd: 1,
  },
  {
    id: '2',
    userName: 'Peter Rompler',
    deals: 23,
    performed: 21,
    distance: 2.3,
    usdt: 1,
    usd: 1.02,
  },
  {
    id: '3',
    userName: 'Alex Butcher',
    deals: 114,
    performed: 112,
    distance: 3,
    usdt: 1,
    usd: 1.11,
  },
];

export type ActiveOfferType = {
  id: string;
  userName: string;
  deals: number;
  performed: number;
  distance: number;
  usdt: number;
  usd: number;
};

export const activeDeals: ActiveDealType[] = [
  {
    id: '1',
    userName: 'Peter Rompler',
    deals: 114,
    performed: 112,
    distance: 3,
    pay: 150,
    get: 150,
  },
  {
    id: '2',
    userName: 'John Smith',
    deals: 1356,
    performed: 1355,
    distance: 1.7,
    pay: 1200,
    get: 1120,
  },
  {
    id: '3',
    userName: 'Mark Butcher',
    deals: 1356,
    performed: 1355,
    distance: 2.7,
    pay: 120,
    get: 130,
  },
];

export type ActiveDealType = {
  id: string;
  userName: string;
  deals: number;
  performed: number;
  distance: number;
  pay: number;
  get: number;
};

export const requests = [
  {
    id: '1',
    userName: 'Peter Rompler',
    distance: 1,
    pay: 150,
    get: 150,
    city: 'Dublin',
    comment: 'I am close to Miles Square, could you drop onto a card?',
  },
  {
    id: '2',
    userName: 'Mark Butcher',
    distance: 1.9,
    pay: 1150,
    get: 1250,
    city: 'Dublin',
    comment: 'I am close to Miles Square, could you drop onto a card?',
  },
];
