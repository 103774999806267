import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AccountButton from 'src/components/AccountButton/AccountButton';
import HeaderAuth from '../HeaderAuth/HeaderAuth';
import style from './RegistrationPreview.module.scss';

type PropsType = {
  isShow: boolean;
};

const RegistrationPreview: FC<PropsType> = ({ isShow }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onPersonalClick = () => {
    navigate('/authentication/registration/personal', { replace: true });
  };

  return (
    isShow && (
      <div className={style.registrationPreview}>
        <HeaderAuth secondary={t('auth.registrationPreview.description')} />
        <div className={style.previewButtons}>
          <AccountButton
            title={t('auth.registrationPreview.personal.title')}
            description={t('auth.registrationPreview.personal.secondary')}
            onClick={onPersonalClick}
          />
          <div style={{ marginBottom: '4px' }} />
          <AccountButton
            title={t('auth.registrationPreview.business.title')}
            description={t('auth.registrationPreview.business.secondary')}
            onClick={onPersonalClick}
          />
        </div>
      </div>
    )
  );
};

export default RegistrationPreview;
