import { FC } from 'react';
import style from './HeaderAuth.module.scss';

type PropsType = {
  header?: string;
  secondary?: string;
};

const HeaderAuth: FC<PropsType> = ({ header, secondary }) => {
  return (
    <div className={style.headerAuth}>
      {secondary && <span>{secondary}</span>}
      {header && <h2>{header}</h2>}
    </div>
  );
};

export default HeaderAuth;

HeaderAuth.defaultProps = {
  header: '',
  secondary: '',
};
