import { FC } from 'react';

type PropsType = {
  icon: string;
  isActive: boolean;
};

const IconContainer: FC<PropsType> = ({ icon, isActive }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d={icon}
        fill={isActive ? 'url(#paint0_linear_1792_4475)' : '#A1A6B3'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_1792_4475"
          x1="2.66667"
          y1="1.45361"
          x2="35.3988"
          y2="24.5077"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDB022" stopOpacity="0.23" />
          <stop offset="1" stopColor="#FDB022" />
        </linearGradient>
        <clipPath id="clip0_1792_4475">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconContainer;
