import { FC } from 'react';
import style from './DescriptionButton.module.scss';

type PropsType = {
  header: string;
  title?: string;
};

const DescriptionButton: FC<PropsType> = ({ header, title }) => {
  return (
    <div className={style.description}>
      <h3>{header}</h3>
      {title && <p>{title}</p>}
    </div>
  );
};

export default DescriptionButton;

DescriptionButton.defaultProps = {
  title: '',
};
