import { ChangeEvent, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { currencies } from 'src/helpers/static-data';
import useUI from 'src/hooks/use-ui';
import CustomButton from '../CustomButton/CustomButton';
import CustomInput from '../CustomInput/CustomInput';
import CustomSelect from '../CustomSelect/CustomSelect';
import CustomTextArea from '../CustomTextArea/CustomTextArea';
import HeaderSide from '../HeaderSide/HeaderSide';
import style from './BillSide.module.scss';

const BillSide = () => {
  const { t } = useTranslation();
  const { setSidePopup, setSubSidePopup, setNotification } = useUI();
  const [currency, setCurrency] = useState('USDT');
  const [invoiceNumber, setInvoiceNumber] = useState(0);

  const inputCurrency = useRef<HTMLSelectElement>(null);
  const inputInvoiceLine = useRef<HTMLTextAreaElement>(null);
  const inputAmount = useRef<HTMLInputElement>(null);
  const inputEmailTo = useRef<HTMLInputElement>(null);
  const inputNameTo = useRef<HTMLInputElement>(null);

  const [errorCurrency, setErrorCurrency] = useState('');
  const [errorInvoiceLine, setErrorInvoiceLine] = useState('');
  const [errorAmount, setErrorAmount] = useState('');
  const [errorEmailTo, setErrorEmailTo] = useState('');
  const [errorNameTo, setErrorNameTo] = useState('');

  const onHeaderClick = () => {
    setSidePopup(null);
  };

  const onCurrencyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrency(e.target.value);
  };

  const clearErrors = () => {
    setErrorCurrency('');
    setErrorInvoiceLine('');
    setErrorAmount('');
    setErrorEmailTo('');
    setErrorNameTo('');
  };

  const sentForm = () => {
    clearErrors();

    const url = 'https://api.bank11.io/invoice';
    const formData = new FormData();
    formData.append('currency', currency.trim().toUpperCase());
    formData.append('invoiceLine', inputInvoiceLine.current?.value || '');
    formData.append('amount', inputAmount.current?.value || '');
    formData.append('emailTo', inputEmailTo.current?.value || '');
    formData.append('nameTo', inputNameTo.current?.value || '');
    const token = localStorage.getItem('token');

    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.errors) {
          setSubSidePopup('SubBill');
          console.log('Success: ', data);
          setInvoiceNumber(data.invoiceNumber);
          setNotification({
            isShow: true,
            header: 'Invoice sent',
            description: `Invoice #${data.invoiceNumber} sent to ${inputEmailTo.current?.value}.`,
          });
        } else {
          setErrorAmount(data.errors.amount);
          setErrorCurrency(data.errors.currency);
          setErrorEmailTo(data.errors.emailTo);
          setErrorInvoiceLine(data.errors.invoiceLine);
          setErrorNameTo(data.errors.nameTo);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // setSubSidePopup('SubBill');
    console.log('send');
  };

  return (
    <div className={style.billSide}>
      <HeaderSide
        title={t('sideBlock.Bill.header')}
        onHeaderClick={onHeaderClick}
        isQuestion
      />
      <form>
        <div className={style.wrap}>
          <CustomSelect
            onChange={onCurrencyChange}
            placeholder={t('placeholder.currency')}
            items={currencies}
            defaulf={currency}
            ref={inputCurrency}
          />
        </div>
        <span className={style.error}>{errorCurrency}</span>
        <div className={style.wrap}>
          <CustomInput
            type="number"
            placeholder={t('placeholder.amount')}
            ref={inputAmount}
          />
        </div>
        <span className={style.error}>{errorAmount}</span>
        <div className={style.wrap}>
          <CustomInput
            placeholder={t('placeholder.email')}
            ref={inputEmailTo}
          />
        </div>
        <span className={style.error}>{errorEmailTo}</span>
        <div className={style.wrap}>
          <CustomInput placeholder={t('placeholder.name')} ref={inputNameTo} />
        </div>
        <span className={style.error}>{errorNameTo}</span>
        <CustomTextArea
          placeholder={t('placeholder.jobline')}
          ref={inputInvoiceLine}
        />
        <span className={style.error}>{errorInvoiceLine}</span>
        <div className={style.button}>
          <CustomButton
            type="main"
            title={t('buttons.send')}
            onClick={sentForm}
          />
        </div>
      </form>
    </div>
  );
};

export default BillSide;
