import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrency from 'src/hooks/use-currency';
import useUI from 'src/hooks/use-ui';
import { TabsType } from 'src/types/types';
import CardTabs from '../CardTabs/CardTabs';
import HeaderSide from '../HeaderSide/HeaderSide';
import InvestInfo from '../InvestInfo/InvestInfo';
import InvestCurrency from './InvestCurrency/InvestCurrency';
import style from './InvestSide.module.scss';
import TopUp from './TopUp/TopUp';

const InvestSide = () => {
  const { t } = useTranslation();
  const { setSidePopup, sidePopup } = useUI();
  const { currency, setCurrency } = useCurrency();
  const [currentTab, setCurrentTab] = useState<string>('topUp');
  const tabsInvest: TabsType[] = [
    { title: t('sideBlock.InvestSide.topUp'), id: 'topUp' },
    { title: t('sideBlock.InvestSide.withdraw'), id: 'withdraw' },
  ];
  const title =
    (sidePopup === 'HedgeFund' && t('sideBlock.InvestSide.header')) ||
    (sidePopup === 'InvestCurrency' && currency?.title);

  const onHeaderClick = () => {
    setSidePopup(null);
    if (sidePopup === 'InvestCurrency') {
      setCurrency(null);
    }
  };

  const changeTab = (id: string) => {
    setCurrentTab(id);
  };

  return (
    <div className={style.hedgeFund}>
      <HeaderSide title={title} onHeaderClick={onHeaderClick} />
      <InvestInfo />
      <div style={{ marginBottom: '24px' }} />
      <CardTabs
        currentTab={currentTab}
        changeTab={changeTab}
        tabs={tabsInvest}
        type="invest"
      />
      <div style={{ marginBottom: '16px' }} />
      {currentTab === 'topUp' && (
        <>
          {sidePopup === 'HedgeFund' && <TopUp />}
          {sidePopup === 'InvestCurrency' && <InvestCurrency />}
        </>
      )}
    </div>
  );
};

export default InvestSide;
