import { FC, ReactNode } from 'react';
import filter from 'src/assets/images/myScore/filter.svg';
import search from 'src/assets/images/myScore/search.svg';
import EmptyList from 'src/components/EmptyList/EmptyList';
import transactions from 'src/assets/images/myScore/transactions.png';
import { transactionsList } from 'src/helpers/static-data';
import Devider from 'src/components/Devider/Devider';
import { useTranslation } from 'react-i18next';
import style from './Transactions.module.scss';

type PropsType = {
  children?: ReactNode;
};

const Transactions: FC<PropsType> = () => {
  const { t } = useTranslation();
  const onSearchClick = () => {
    console.log('search');
  };

  const onFilterClick = () => {
    console.log('filter');
  };

  return (
    <div className={style.transactions}>
      <div className={style.header}>
        <div className={style.image} onClick={onFilterClick} aria-hidden="true">
          <img src={filter} alt="filter" />
        </div>
        <span>{t('MyScore.Transactions.header')}</span>
        <div className={style.image} onClick={onSearchClick} aria-hidden="true">
          <img src={search} alt="search" />
        </div>
      </div>
      <Devider styles={{ marginTop: '16px', marginBottom: '20px' }} />
      {transactionsList && (
        <div className={style.transactionsList}>
          {transactionsList.map(({ currency, date, total, type, icons }) => {
            let color = '';
            if (type === 'add') color = '#12B76A';
            if (type === 'withdrawal') color = '#F04438';

            return (
              <div className={style.transactionItem} key={total}>
                <div className={style.type}>
                  <div className={style.icon}>
                    <img src={icons} alt={type} />
                  </div>
                  <div className={style.typeTitle}>
                    <span>{t(`MyScore.Transactions.${type}`)}</span>
                    <span className={style.currency}>{currency}</span>
                  </div>
                </div>
                <div>
                  <p style={{ color }}>{total}</p>
                  <p className={style.date}>{date}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {!transactionsList && (
        <EmptyList
          icon={transactions}
          title="У вас нет транзакций"
          description="В этом разделе будут отображаться все движения средств по вашему счету"
        />
      )}
    </div>
  );
};

export default Transactions;
