import { ChangeEvent, FC } from 'react';
import style from './CustomCheckbox.module.scss';

type PropsType = {
  onCheck: (value: boolean) => void;
  checked: boolean;
  description: string;
};

const CustomCheckbox: FC<PropsType> = ({ onCheck, checked, description }) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onCheck(e.target.checked);
  };

  return (
    <label className={style.container} htmlFor="checkBox">
      <span className={style.descriptopn}>{description}</span>
      <input
        type="checkbox"
        checked={checked}
        id="checkBox"
        onChange={onChange}
      />
      <span className={style.checkmark} />
    </label>
  );
};

export default CustomCheckbox;
