import { useMemo, useCallback, useReducer, ReactNode } from 'react';
import {
  NotificationType,
  setIsMapAC,
  setNotificationAC,
  setSidePopupAC,
  setSubSidePopupAC,
  SideType,
  SubSideType,
  UIContext,
  UIInitialState,
  UIReducer,
} from 'src/reducers/UIReducer';

type PropsType = {
  children: ReactNode;
};

const UIProvider = (props: PropsType) => {
  const { children } = props;
  const [state, dispatch] = useReducer(UIReducer, UIInitialState);

  const setNotification = useCallback((notification: NotificationType) => {
    dispatch(setNotificationAC(notification));
  }, []);

  const setSidePopup = useCallback((sidePopup: SideType) => {
    dispatch(setSidePopupAC(sidePopup));
  }, []);

  const setSubSidePopup = useCallback((subSidePopup: SubSideType) => {
    dispatch(setSubSidePopupAC(subSidePopup));
  }, []);

  const setIsMap = useCallback(() => {
    dispatch(setIsMapAC(!state.isMap));
  }, [state.isMap]);

  const contextValue = useMemo(
    () => ({
      ...state,
      setNotification,
      setSidePopup,
      setSubSidePopup,
      setIsMap,
    }),
    [state, setNotification, setSidePopup, setSubSidePopup, setIsMap],
  );

  return (
    <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>
  );
};

export default UIProvider;
