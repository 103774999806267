import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SidebarType } from 'src/types/types';
import style from './ActiveLine.module.scss';

type PropsType = {
  sidebarItem: SidebarType[];
};

const ActiveLine: FC<PropsType> = ({ sidebarItem }) => {
  const location = useLocation();
  const [active, setActive] = useState(0);
  const [scroll, setScroll] = useState(208);

  useEffect(() => {
    const size = window.innerHeight;
    if (size < 950) {
      setScroll(156);
    }
  }, []);

  useEffect(() => {
    const index = sidebarItem.findIndex((el) => el.link === location.pathname);
    setActive(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div
      className={style.activeLine}
      style={{ top: `${scroll + 108 * active}px` }}
    />
  );
};

export default ActiveLine;
