import { FC } from 'react';
import style from './EmptyList.module.scss';

type PropsType = {
  title: string;
  description: string;
  icon: string;
};

const EmptyList: FC<PropsType> = ({ title, description, icon }) => {
  return (
    <div className={style.empty}>
      <img src={icon} alt="empty list" />
      <div className={style.title}>{title}</div>
      <div className={style.description}>{description}</div>
    </div>
  );
};

export default EmptyList;
