import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import avatar from 'src/assets/images/avatar.png';
import logout from 'src/assets/images/logout.svg';
import { user } from 'src/helpers/static-data';
import useUI from 'src/hooks/use-ui';
import style from './AccountBlock.module.scss';

const AccountBlock = () => {
  const { t } = useTranslation();
  const { setSidePopup, setSubSidePopup } = useUI();
  const navigate = useNavigate();

  const onDetailsClick = () => {
    setSubSidePopup('ProfileDetails');
  };

  const onLogOutClick = () => {
    setSidePopup(null);
    navigate('/authentication', { replace: true });
  };

  return (
    <div className={style.accountBlock}>
      <div className={style.user}>
        <img src={avatar} alt="avatar" />
        <div className={style.text}>
          <p className={style.name}>{user.name}</p>
          <p
            className={style.details}
            onClick={onDetailsClick}
            aria-hidden="true"
          >
            {t('sideBlock.SettingsAccount.details')}
          </p>
        </div>
      </div>
      <div className={style.logout} onClick={onLogOutClick} aria-hidden="true">
        <img src={logout} alt="logout" />
      </div>
    </div>
  );
};

export default AccountBlock;
