import { FC } from 'react';
import { NotificationsType } from 'src/helpers/static-data';
import style from './NotificationItem.module.scss';

type PropsType = {
  notification: NotificationsType;
};

const NotificationItem: FC<PropsType> = ({ notification }) => {
  const { header, message, time, isActive } = notification;
  const color = { color: !isActive ? '#A1A6B3' : '' };
  return (
    <div className={style.notificationItem}>
      <div className={style.info}>
        <div className={style.header} style={color}>
          {isActive && <div className={style.active} />}
          {header}
        </div>
        <div>{time}</div>
      </div>
      <div className={style.message} style={color}>
        {message}
      </div>
    </div>
  );
};

export default NotificationItem;
