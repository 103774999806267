import { FC } from 'react';
import style from './StatisticItem.module.scss';

type PropsType = {
  icon: string;
  title: string;
  total: string;
  rate?: number;
  styles?: {
    [key: string]: string;
  };
};

const StatisticItem: FC<PropsType> = ({ icon, title, rate, total, styles }) => {
  return (
    <div className={style.statisticItem} style={styles}>
      <img src={icon} alt={title} />
      <div className={style.name}>{title}</div>
      <p>
        {total}{' '}
        {rate && (
          <span style={{ color: rate > 0 ? '#12B76A' : '#F04438' }}>
            {`${rate > 0 ? '+' : ''}${rate} %`}
          </span>
        )}
      </p>
    </div>
  );
};

export default StatisticItem;

StatisticItem.defaultProps = {
  styles: {},
  rate: null,
};
