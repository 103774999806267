import useUI from 'src/hooks/use-ui';
import icon from 'src/assets/images/notification.svg';
import error from 'src/assets/images/error.svg';
import close from 'src/assets/images/close-notification.svg';
import style from './Notification.module.scss';

const Notification = () => {
  const { notification, setNotification } = useUI();

  const onCloseClick = () => {
    setNotification({
      ...notification,
      isShow: false,
    });
  };

  return (
    notification.isShow && (
      <div className={style.notification}>
        <div className={style.close} onClick={onCloseClick} aria-hidden="true">
          <img src={close} alt="close-notification" />
        </div>
        <div className={style.icon}>
          <img src={notification?.isError ? error : icon} alt="notification" />
        </div>
        <div className={style.description}>
          <h3>{notification.header}</h3>
          <p>{notification.description}</p>
        </div>
      </div>
    )
  );
};

export default Notification;
