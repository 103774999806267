import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUI from 'src/hooks/use-ui';
import CustomButton from '../CustomButton/CustomButton';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import CustomInput from '../CustomInput/CustomInput';
import HeaderSide from '../HeaderSide/HeaderSide';
import UploadPhoto from '../UploadPhoto/UploadPhoto';
import style from './ActivateAccount.module.scss';

const ActivateAccount = () => {
  const { t } = useTranslation();
  const { setSidePopup } = useUI();
  const [consent, setConsent] = useState(false);

  const onHeaderClick = () => {
    setSidePopup(null);
  };

  const onPasswordChange = () => {
    console.log('activate');
    onHeaderClick();
  };

  const onCheck = (value: boolean) => {
    setConsent(value);
  };

  return (
    <div className={style.activateAccount}>
      <HeaderSide
        title={t('sideBlock.ActivateAccount.header')}
        onHeaderClick={onHeaderClick}
      />
      <form action="">
        <CustomInput placeholder={t('sideBlock.ActivateAccount.name')} />
        <CustomInput placeholder={t('sideBlock.ActivateAccount.fullName')} />
        <CustomInput placeholder={t('sideBlock.ActivateAccount.date')} />
        <CustomInput placeholder={t('sideBlock.ActivateAccount.address')} />
        <CustomInput placeholder="E-mail" />
        <UploadPhoto />
        <div style={{ marginTop: '48px' }} />
        <CustomCheckbox
          checked={consent}
          description={t('checkBox.processingData')}
          onCheck={onCheck}
        />
        <div style={{ marginTop: '24px' }} />
        <CustomButton
          type="main"
          title={t('buttons.review')}
          onClick={onPasswordChange}
        />
      </form>
    </div>
  );
};

export default ActivateAccount;
