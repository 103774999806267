import { useTranslation } from 'react-i18next';
import { currency } from 'src/helpers/static-data';
import useCurrency from 'src/hooks/use-currency';
import useUI from 'src/hooks/use-ui';
import CurrencyItem from '../CurrencyItem/CurrencyItem';
import CustomInput from '../CustomInput/CustomInput';
import HeaderSide from '../HeaderSide/HeaderSide';
import style from './CurrenciesList.module.scss';

const CurrenciesList = () => {
  const { t } = useTranslation();
  const { setSidePopup, setSubSidePopup } = useUI();
  const { setCurrency } = useCurrency();

  const onHeaderClick = () => {
    setSidePopup(null);
    setSubSidePopup(null);
  };

  return (
    <div className={style.currenciesList}>
      <HeaderSide
        title={t('sideBlock.CurrenciesList.header')}
        onHeaderClick={onHeaderClick}
      />
      <div className={style.inputSearch}>
        <CustomInput
          placeholder={t('sideBlock.CurrenciesList.search')}
          isSearch
        />
      </div>
      <div className={style.list}>
        {currency.map((item) => {
          const onCurrencyClick = () => {
            setSubSidePopup('TopUpAccountCurrency');
            setCurrency(item);
          };
          return (
            <CurrencyItem key={item.id} item={item} onClick={onCurrencyClick} />
          );
        })}
      </div>
    </div>
  );
};

export default CurrenciesList;
