import { createContext } from 'react';

export type NotificationType = {
  header: string | null;
  description: string | null;
  isShow: boolean;
  isError?: boolean;
};

export type SideType =
  | 'ActivateAccount'
  | 'AccountSettings'
  | 'CurrenciesList'
  | 'CreateTransfer'
  | 'Bill'
  | 'PayBill'
  | 'HedgeFund'
  | 'TopUpAccountX11'
  | 'InvestCurrency'
  | 'GetPaid'
  | 'DepositMonths'
  | 'MakeDeposit'
  | 'MakeDepositRate'
  | 'Staking'
  | 'LoanOffer'
  | 'CreditsList'
  | 'FiatCrypto'
  | 'ActiveDeal'
  | 'ExchangeFunds';
export type SubSideType =
  | 'ProfileDetails'
  | 'ChangePassword'
  | 'TopUpAccountCurrency'
  | 'SubBill'
  | 'CreditLend'
  | 'Offer';

export interface UIState {
  notification: NotificationType;
  sidePopup: SideType | null;
  subSidePopup: SubSideType | null;
  isMap: boolean;
}

export const UIInitialState: UIState = {
  sidePopup: null,
  subSidePopup: null,
  notification: {
    header: null,
    description: null,
    isShow: false,
  },
  isMap: false,
};

export const UIReducer = (state: UIState, action: ActionsType): UIState => {
  switch (action.type) {
    case 'ui/x11-app/TOGGLE-SET-NOTIFICATION':
    case 'ui/x11-app/SET-SIDE-POPUP':
    case 'ui/x11-app/SET-SUB_SIDE-POPUP':
    case 'ui/x11-app/SET-IS-MAP': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export const setNotificationAC = (notification: NotificationType) =>
  ({
    type: 'ui/x11-app/TOGGLE-SET-NOTIFICATION',
    payload: { notification },
  } as const);

export const setSidePopupAC = (sidePopup: SideType) =>
  ({
    type: 'ui/x11-app/SET-SIDE-POPUP',
    payload: { sidePopup },
  } as const);

export const setSubSidePopupAC = (subSidePopup: SubSideType) =>
  ({
    type: 'ui/x11-app/SET-SUB_SIDE-POPUP',
    payload: { subSidePopup },
  } as const);

export const setIsMapAC = (isMap: boolean) =>
  ({
    type: 'ui/x11-app/SET-IS-MAP',
    payload: { isMap },
  } as const);

type ActionsType =
  | ReturnType<typeof setNotificationAC>
  | ReturnType<typeof setSidePopupAC>
  | ReturnType<typeof setSubSidePopupAC>
  | ReturnType<typeof setIsMapAC>;

interface UIContextValue extends UIState {
  setNotification: (notification: NotificationType) => void;
  setSidePopup: (sidePopup: SideType) => void;
  setSubSidePopup: (subSidePopup: SubSideType) => void;
  setIsMap: () => void;
}

export const UIContext = createContext<UIContextValue>({
  ...UIInitialState,
  setNotification: () => {},
  setSidePopup: () => {},
  setSubSidePopup: () => {},
  setIsMap: () => {},
});
