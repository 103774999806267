import { FC, useContext } from 'react';
import logo from 'src/assets/images/sidebar/logo_X11.svg';
import setting from 'src/assets/images/sidebar/setting.svg';
import logout from 'src/assets/images/sidebar/logout.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { SidebarType } from 'src/types/types';
import useUI from 'src/hooks/use-ui';
import {
  DepositIcon,
  WalletIcon,
  CreditIcon,
  ExchangeIcon,
  NotificationsIcon,
} from 'src/icons/IconsSidebar';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'src/reducers/AuthReducer';
import style from './Sidebar.module.scss';
import SidebarItem from './SidebarItem/SidebarItem';
import ActiveLine from './ActiveLine/ActiveLine';

type PropsType = {
  isOpen: boolean;
  onMenuClick: () => void;
};

const Sidebar: FC<PropsType> = ({ isOpen, onMenuClick }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { setSidePopup } = useUI();
  const authCtx = useContext(AuthContext);
  const sidebarItem: SidebarType[] = [
    { title: t('sideBar.myScore'), link: '/', icon: WalletIcon() },
    { title: t('sideBar.deposit'), link: '/deposit', icon: DepositIcon() },
    { title: t('sideBar.credit'), link: '/credit', icon: CreditIcon() },
    { title: t('sideBar.exchange'), link: '/exchange', icon: ExchangeIcon() },
    {
      title: t('sideBar.notifications'),
      link: '/notifications',
      icon: NotificationsIcon(),
    },
  ];

  const onSettingsClick = () => {
    setSidePopup('AccountSettings');
    onMenuClick();
  };

  const onLogOutClick = async () => {
    const { auth } = authCtx;
    if (auth.w3Auth) {
      await auth.w3Auth.logout();
      localStorage.removeItem('address');
      localStorage.removeItem('balance');
      localStorage.removeItem('balanceUSDC');
      localStorage.removeItem('balanceUSDT');
    }
    onMenuClick();
    navigate('/authentication', { replace: true });
  };

  const styles = {
    display: 'flex',
    transform: 'translateX(0%)',
    transition: '0.3s ease-in',
  };

  return (
    <div className={style.sideBar} style={isOpen ? styles : {}}>
      <ActiveLine sidebarItem={sidebarItem} />
      <div className={style.border} />
      <div className={style.sideBarLogo}>
        <img src={logo} alt="sidebar logo" />
      </div>
      <div className={style.sideBarMenu}>
        {sidebarItem.map((item) => (
          <SidebarItem
            item={item}
            path={location.pathname}
            key={item.title}
            onMenuClick={onMenuClick}
          />
        ))}
      </div>
      <div
        className={style.setting}
        onClick={onSettingsClick}
        aria-hidden="true"
      >
        <img src={setting} alt="setting" />
      </div>
      <div className={style.logout} onClick={onLogOutClick} aria-hidden="true">
        <img src={logout} alt="setting" />
      </div>
    </div>
  );
};

export default Sidebar;
