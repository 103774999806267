import filter from 'src/assets/images/myScore/filter.svg';
import { ActiveOffersList } from 'src/helpers/static-data';
import { useTranslation } from 'react-i18next';
import style from './ActiveOffers.module.scss';
import ActiveOfferItem from '../ActiveOfferItem/ActiveOfferItem';

const ActiveOffers = () => {
  const { t } = useTranslation();
  const onFilterClick = () => {
    console.log('filter');
  };

  return (
    <div className={style.activeOffers}>
      <div className={style.header}>
        <div className={style.title}>
          <p>{t('sideBlock.FiatCrypto.active')}</p>
          <span>{t('sideBlock.FiatCrypto.verified')}</span>
        </div>
        <div
          className={style.filter}
          onClick={onFilterClick}
          aria-hidden="true"
        >
          <img src={filter} alt="filter" />
        </div>
      </div>
      <div className={style.offersList}>
        {ActiveOffersList.map((el) => (
          <ActiveOfferItem offer={el} key={el.id} />
        ))}
      </div>
    </div>
  );
};

export default ActiveOffers;
