import { useTranslation } from 'react-i18next';
import { requests } from 'src/helpers/static-data';
import CustomButton from '../CustomButton/CustomButton';
import Distance from '../Distance/Distance';
import style from './RequestsBlock.module.scss';

const RequestsBlock = () => {
  const { t } = useTranslation();
  return (
    <div className={style.requestsBlock}>
      {requests.map(({ id, userName, distance, pay, city, comment, get }) => (
        <div className={style.request} key={id}>
          <div className={style.header}>
            <span>{userName}</span> {t('Exchange.requestItem.buy')}{' '}
            <span>{pay} USDT</span>, {t('Exchange.requestItem.get')}{' '}
            <span>{get} USD</span>
          </div>
          <div className={style.place}>
            <div className={style.city}>
              {t('Exchange.requestItem.city')}: <span>{city}</span>
            </div>
            <Distance distance={distance} />
          </div>
          <div className={style.comment}>
            {t('Exchange.requestItem.comment')}: <span>{comment}</span>
          </div>
          <div className={style.buttons}>
            <div className={style.button}>
              <CustomButton
                size="small"
                styled={{
                  color: '#D92D20',
                  borderColor: '#FDA29B',
                }}
                type="secondary"
                onClick={() => console.log('Отказаться')}
                title={t('buttons.refuse')}
              />
            </div>
            <div className={style.button}>
              <CustomButton
                size="small"
                type="main"
                onClick={() => console.log('Подвердить')}
                title={t('buttons.confirm')}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RequestsBlock;
