import { useMemo, useCallback, useReducer, ReactNode } from 'react';
import {
  CreditContext,
  CreditInitialState,
  CreditReducer,
  CreditType,
  setConditionСreditAC,
} from 'src/reducers/CreditReducer';

type PropsType = {
  children: ReactNode;
};

const CreditProvider = (props: PropsType) => {
  const { children } = props;
  const [state, dispatch] = useReducer(CreditReducer, CreditInitialState);

  const setConditionСredit = useCallback((conditionСredit: CreditType) => {
    dispatch(setConditionСreditAC(conditionСredit));
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      setConditionСredit,
    }),
    [state, setConditionСredit],
  );

  return (
    <CreditContext.Provider value={contextValue}>
      {children}
    </CreditContext.Provider>
  );
};

export default CreditProvider;
