import { FC, ReactNode, useState } from 'react';
import useUI from 'src/hooks/use-ui';
import email from 'src/assets/images/email_send.svg';
import contract from 'src/assets/images/contract.svg';
import { useTranslation } from 'react-i18next';
import ConditionСreditInfo from '../ConditionСreditInfo/ConditionСreditInfo';
import HeaderSide from '../HeaderSide/HeaderSide';
import style from './CreditLend.module.scss';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import CustomButton from '../CustomButton/CustomButton';
import ButtonLink from '../ButtonLink/ButtonLink';
import CustomInput from '../CustomInput/CustomInput';
import Devider from '../Devider/Devider';

type PropsType = {
  children?: ReactNode;
};

const CreditLend: FC<PropsType> = () => {
  const { t } = useTranslation();
  const { setSubSidePopup, setNotification, setSidePopup } = useUI();
  const [isSign, setIsSign] = useState(false);

  const onHeaderClick = () => {
    setSubSidePopup(null);
  };

  const onCanselClick = () => {
    onHeaderClick();
  };

  const onCheckedChange = () => {
    setIsSign(!isSign);
  };

  const onCreditClick = () => {
    onHeaderClick();
    setSidePopup(null);
    setNotification({
      isShow: true,
      header: t('sideBlock.LoanOffer.notification'),
      description: t('sideBlock.LoanOffer.description'),
    });
  };

  return (
    <div className={style.creditLend}>
      <HeaderSide
        title={t('sideBlock.CreditsList.borrow')}
        onHeaderClick={onHeaderClick}
      />
      <div className={style.container}>
        <div className={style.creditLendBlock}>
          <h3>{t('sideBlock.CreditsList.title')}</h3>
          <div style={{ marginTop: '8px' }} />
          <span>{t('sideBlock.CreditsList.borrowText')}</span>
          <ConditionСreditInfo />
          <Devider styles={{ marginTop: '20px', marginBottom: '24px' }} />
          <div className={style.payout}>
            {t('sideBlock.CreditsList.payment')} <span>135 USD</span>{' '}
            {t('sideBlock.CreditsList.every')} <span>5 мая</span>{' '}
            {t('sideBlock.CreditsList.until')} <span>05.05.2023</span>
          </div>
          <div style={{ marginTop: '16px' }} />
          <CustomInput placeholder={t('placeholder.provisions')} />
          <h3>{t('sideBlock.LoanOffer.contract')}</h3>
          <span>{t('secondary.description')}</span>
          <ButtonLink icon={email} title={t('buttonsLink.sendEmail')} />
          <ButtonLink icon={contract} title={t('buttonsLink.openContract')} />
        </div>
      </div>
      <div style={{ marginTop: '48px' }} />
      <CustomCheckbox
        description={t('checkBox.read')}
        onCheck={onCheckedChange}
        checked={isSign}
      />
      <div className={style.buttons}>
        <div className={style.button}>
          <CustomButton
            type="secondary"
            title={t('buttons.cancel')}
            onClick={onCanselClick}
          />
        </div>
        <div className={style.button}>
          <CustomButton
            type="main"
            title={t('buttons.make')}
            onClick={onCreditClick}
          />
        </div>
      </div>
    </div>
  );
};

export default CreditLend;
