import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AuthContext } from 'src/reducers/AuthReducer';
import copy from 'src/assets/images/myScore/copy.svg';
import style from './DepositAddress.module.scss';

const DepositAddress = () => {
  const { t } = useTranslation();
  const authCtx = useContext(AuthContext);
  const { auth } = authCtx;
  const { address } = auth;

  const cotyAddress = async () => {
    try {
      await navigator.clipboard.writeText(address);
      console.log('Async: Copying to clipboard was successful!');
    } catch (err) {
      console.error('Async: Could not copy text: ', err);
    }
  };

  return (
    <div className={style.addressBlock}>
      <p>{t('placeholder.topUpAddress')} USDT</p>
      <div className={style.address}>
        <p>{address}</p>
        <div className={style.copy} onClick={cotyAddress} aria-hidden="true">
          <img src={copy} alt="copy" />
        </div>
      </div>
    </div>
  );
};

export default DepositAddress;
