import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderSide from 'src/components/HeaderSide/HeaderSide';
import { currencies } from 'src/helpers/static-data';
import useUI from 'src/hooks/use-ui';
import CustomButton from '../CustomButton/CustomButton';
import CustomInput from '../CustomInput/CustomInput';
import CustomSelect from '../CustomSelect/CustomSelect';

import style from './ExchangeFunds.module.scss';

const ExchangeFunds = () => {
  const { t } = useTranslation();
  const { setSidePopup } = useUI();
  const [currency, setCurrency] = useState('usd');

  const onHeaderClick = () => {
    setSidePopup(null);
  };

  const onLoanTermChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrency(e.target.value);
  };

  return (
    <div className={style.exchangeFunds}>
      <HeaderSide
        title={t('sideBlock.ExchangeFunds.header')}
        onHeaderClick={onHeaderClick}
      />
      <div className={style.container}>
        <div className={style.fundsBlock}>
          <CustomSelect
            onChange={onLoanTermChange}
            defaulf={currency}
            items={currencies}
            placeholder={t('placeholder.exchange')}
          />
          <CustomInput placeholder={t('placeholder.get')} type="number" />
          <CustomInput placeholder={t('placeholder.total')} type="number" />
        </div>
      </div>
      <div className={style.buttons}>
        <div className={style.button}>
          <CustomButton
            type="secondary"
            onClick={() => console.log('Отказаться')}
            title={t('buttons.dispute')}
          />
        </div>
        <div className={style.button}>
          <CustomButton
            type="main"
            onClick={() => console.log('Подвердить')}
            title={t('buttons.show')}
          />
        </div>
      </div>
    </div>
  );
};

export default ExchangeFunds;
