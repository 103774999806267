import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import invest from 'src/assets/images/myScore/invest.svg';
import profit from 'src/assets/images/myScore/profit.svg';
import wastage from 'src/assets/images/myScore/wastage.svg';
import { StatisticType } from 'src/types/types';
import StatisticItem from '../StatisticItem/StatisticItem';
import style from './StatisticBlock.module.scss';

type PropsType = {
  children?: ReactNode;
};

const StatisticBlock: FC<PropsType> = () => {
  const { t } = useTranslation();

  const statistic: StatisticType[] = [
    {
      icon: invest,
      title: t('Invest.Statistic.Invested'),
      total: '4 326 USD',
      rate: 345,
    },
    {
      icon: profit,
      title: t('Invest.Statistic.Income'),
      total: '123 US',
      rate: 32,
    },
    {
      icon: wastage,
      title: t('Invest.Statistic.Losses'),
      total: '65 USD',
      rate: -17,
    },
  ];

  return (
    <div className={style.statisticBlock}>
      {statistic.map(({ icon, title, total, rate }) => (
        <StatisticItem
          key={title}
          icon={icon}
          rate={rate}
          total={total}
          title={title}
        />
      ))}
    </div>
  );
};

export default StatisticBlock;
