import { FC, ReactNode, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import bg_card from 'src/assets/images/myScore/bg_card.png';
import BalanceUp from 'src/components/BalanceUp/BalanceUp';
import CardTabs from 'src/components/CardTabs/CardTabs';
import InfiniteLine from 'src/components/InfiniteLine/InfiniteLine';
import WalletAddress from 'src/components/WalletAddress/WalletAddress';
import { cardTotal, numbsCard } from 'src/helpers/static-data';
import useUI from 'src/hooks/use-ui';
import { TabsType } from 'src/types/types';
import { AuthContext } from 'src/reducers/AuthReducer';
import YellowLine from 'src/components/YellowLine/YellowLine';
import RPC from 'src/helpers/web3RPC';
import style from './Card.module.scss';

type PropsType = {
  children?: ReactNode;
};

const Card: FC<PropsType> = () => {
  const { t } = useTranslation();
  const { setSidePopup } = useUI();

  const authState = useContext(AuthContext);
  const { auth, setBalance, setBalanceUSDC, setBalanceUSDT, setAddress } =
    authState;

  const [currentTab, setCurrentTab] = useState<string>('total');
  const [address, setAddr] = useState<string>('');
  const [authentication, setAuthentication] = useState<boolean>(false);
  const [kycStatus, setKycStatus] = useState<string>('');

  const navigate = useNavigate();

  const tabs: TabsType[] = [
    { title: t('MyScore.Card.tabs.total'), id: 'total' },
    { title: t('MyScore.Card.tabs.available'), id: 'available' },
  ];

  const changeTab = (id: string) => {
    setCurrentTab(id);
  };

  const onActivateClick = () => {
    setSidePopup('ActivateAccount');
  };

  const onVerifyClick = () => {
    navigate('/kyc');
  };

  console.log(authState);

  const clientId = RPC.W3AUTH_KEY;
  // ('BASqr11NXLd7DyxSwtQ2Szy3JSv1dqtfq_0z6LWgsJT3hbUr8Rbv1y_nyXMUdXaquZnBhNFcO0XQ51gT8QN6c98');

  //

  const getKYCStatus = async () => {
    const token = localStorage.getItem('token');
    const messageResponse = await fetch('https://api.bank11.io/kyc', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    const message = await messageResponse.json();
    const { status } = message.data;
    setKycStatus(status);

    console.log(status);
  };

  useEffect(() => {
    getKYCStatus();
    setInterval(async () => {
      // getKYCStatus();
    }, 15000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth.w3Auth) {
      const rpc = new RPC(auth.w3Auth.provider);
      setInterval(async () => {
        await auth.w3Auth.connect();
        console.log('USE EFFECT');

        const balance = await rpc.getBalance();
        const balanceUSDC = await rpc.getUSDCBalance();
        const balanceUSDT = await rpc.getUSDTBalance();
        console.log('balance: ', balance);
        console.log('balanceUSDC: ', balanceUSDC);
        console.log('balanceUSDT: ', balanceUSDT);

        // authState.auth.address = await rpc.getAccounts();
        setAddress(await rpc.getAccounts());
        setBalance(parseFloat(balance));
        setBalanceUSDC(parseFloat(balanceUSDC));
        setBalanceUSDT(parseFloat(balanceUSDT));
      }, 15000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.cardContainer}>
      <WalletAddress address={auth.address} />
      <img className={style.bgCard} src={bg_card} alt="bg_card" />

      {kycStatus === 'success' && (
        <>
          <div className={style.total}>
            <p>
              {auth.balance} <span>ETH</span>
            </p>
          </div>
          <div className={style.numbsList}>
            <div key="USDT" className={style.numbItem}>
              <p>
                {auth.balanceUSDT} <span>USDT</span>
              </p>
            </div>
            <div key="USDC" className={style.numbItem}>
              <p>
                {auth.balanceUSDC} <span>USDC</span>
              </p>
            </div>
          </div>
          <div className={style.credits}>
            <p>
              {t('MyScore.Card.creditFunds')} <span>{cardTotal.credit}</span>{' '}
              USD
            </p>
          </div>
        </>
      )}
      {kycStatus !== 'success' && kycStatus !== 'created' && (
        <div onClick={onVerifyClick} aria-hidden="true">
          <InfiniteLine />
        </div>
      )}
      {kycStatus === 'created' && (
        <div onClick={onActivateClick} aria-hidden="true">
          <YellowLine />
        </div>
      )}
    </div>
  );
};

export default Card;
