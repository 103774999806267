import { useTranslation } from 'react-i18next';
import useCredit from 'src/hooks/use-credit';
import style from './ConditionСreditInfo.module.scss';

const ConditionСreditInfo = () => {
  const { t } = useTranslation();
  const { conditionСredit } = useCredit();
  return (
    <div className={style.infoBlock}>
      <div className={style.infoItem}>
        <span>{t('sideBlock.CreditsList.term')}</span>
        <p>{conditionСredit.month}</p>
        <span>{t('sideBlock.CreditsList.month')}</span>
      </div>
      <div className={style.infoItem}>
        <span>Ставка:</span>
        <p>{conditionСredit.rate}</p>
        <span>{t('buttons.annum')}</span>
      </div>
      <div className={style.infoItem}>
        <span>{t('placeholder.total')}:</span>
        <p>{conditionСredit.availableCredit}</p>
        <span>USD</span>
      </div>
    </div>
  );
};

export default ConditionСreditInfo;
