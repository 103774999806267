import { ChangeEvent, FC, forwardRef } from 'react';
import arrow from 'src/assets/images/select_arrow.svg';
import { SelectType } from 'src/helpers/static-data';
import style from './CustomSelect.module.scss';

type PropsType = {
  placeholder: string;
  defaulf: string;
  items: SelectType[];
  error?: boolean;
  textError?: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
};

const CustomSelect = forwardRef<HTMLSelectElement, PropsType>((props, ref) => {
  const { items, placeholder, defaulf, onChange, error, textError } = props;

  return (
    <>
      <div
        className={style.container}
        style={error ? { border: '1px solid rgba(240, 68, 56, 1)' } : {}}
      >
        <span className={style.placeholder}>{placeholder}</span>
        <img className={style.arrow} src={arrow} alt="arrow" />
        <label className={style.select} htmlFor="slct">
          <select
            id="slct"
            required
            onChange={onChange}
            value={defaulf}
            ref={ref}
          >
            {items.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className={style.errorText}>
        {error && (textError || 'Error message text')}
      </div>
    </>
  );
});

export default CustomSelect;

CustomSelect.defaultProps = {
  error: false,
  textError: '',
};
