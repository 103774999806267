import './App.css';
import bg from 'src/assets/images/bg.png';
import { useRoutes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import routes from './router';
import Overlay from './components/OverlayBlock/Overlay';
import CurrencyProvider from './contexts/CurrencyContext';
import Notification from './components/Notification/Notification';
import DepositProvider from './contexts/DepositContext';
import CreditProvider from './contexts/CreditContext';
import OfferProvider from './contexts/OfferContext';
import AuthProvider from './contexts/AuthContext';
import './i18n';

function App() {
  const { i18n } = useTranslation();
  const content = useRoutes(routes);
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const lang = await localStorage.getItem('X11_lang');
      const pathRu = `${location.pathname}`.includes('/ru');
      if (lang && !pathRu) {
        i18n.changeLanguage(lang);
      } else {
        i18n.changeLanguage(!pathRu ? 'en' : 'ru');
      }
    })();
  }, [i18n, location.pathname]);

  return (
    <div className="App">
      <img className="bg" src={bg} alt="bg" />
      <DepositProvider>
        <CreditProvider>
          <CurrencyProvider>
            <OfferProvider>
              <AuthProvider>
                {content}
                <Overlay />
                <Notification />
              </AuthProvider>
            </OfferProvider>
          </CurrencyProvider>
        </CreditProvider>
      </DepositProvider>
    </div>
  );
}

export default App;
