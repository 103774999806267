import { FC, ReactNode, useState } from 'react';
import email from 'src/assets/images/email_send.svg';
import contract from 'src/assets/images/contract.svg';
import useDeposit from 'src/hooks/use-deposit';
import useUI from 'src/hooks/use-ui';
import { useTranslation } from 'react-i18next';
import ButtonLink from '../ButtonLink/ButtonLink';
import HeaderSide from '../HeaderSide/HeaderSide';
import style from './CreateDepositSide.module.scss';
import CustomInput from '../CustomInput/CustomInput';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import CustomButton from '../CustomButton/CustomButton';

type PropsType = {
  children?: ReactNode;
};

const CreateDepositSide: FC<PropsType> = () => {
  const { t } = useTranslation();
  const { setNotification, setSidePopup } = useUI();
  const { depositVariant, addDeposit } = useDeposit();

  const [value, setValue] = useState(null);
  const [isSign, setIsSign] = useState(false);

  const headerTitle = `${t('sideBlock.CreateDeposit.header')} ${
    depositVariant.month
  } ${t('sideBlock.CreateDeposit.month')}`;

  const onDepositChange = (depositValue: string) => {
    setValue(depositValue);
  };

  const onHeaderClick = () => {
    setSidePopup(null);
  };

  const onCheckedChange = () => {
    setIsSign(!isSign);
  };

  const onCanselClick = () => {
    onHeaderClick();
  };

  const onDepositClick = async () => {
    try {
      if (value && isSign) {
        await addDeposit(depositVariant.month, depositVariant.rate, value);
        onHeaderClick();
        setNotification({
          isShow: true,
          header: t('sideBlock.CreateDeposit.notification.success.header'),
          description: t(
            'sideBlock.CreateDeposit.notification.success.description',
          ),
        });
      }
    } catch (e) {
      console.log(e);
      setNotification({
        isShow: true,
        isError: true,
        header: t('sideBlock.CreateDeposit.notification.error.header'),
        description: t(
          'sideBlock.CreateDeposit.notification.error.description',
        ),
      });
    }
  };

  return (
    <div className={style.createDepositSide}>
      <HeaderSide title={headerTitle} onHeaderClick={onHeaderClick} />
      <div className={style.depositBlock}>
        <div className={style.header}>
          <p>
            {t('sideBlock.CreateDeposit.header')}{' '}
            <span>
              {depositVariant.month} {t('sideBlock.CreateDeposit.month')}{' '}
            </span>{' '}
            {t('sideBlock.CreateDeposit.at')}:
          </p>
          <div className={style.rate}>{depositVariant.rate}%</div>
        </div>
        <div className={style.agreement}>
          <h3>{t('sideBlock.CreateDeposit.contract')}</h3>
          <p>{t('secondary.description')}</p>
        </div>
        <ButtonLink icon={email} title={t('buttonsLink.sendEmail')} />
        <ButtonLink icon={contract} title={t('buttonsLink.openContract')} />
        <div className={style.depositInput}>
          <CustomInput
            placeholder={t('placeholder.deposit')}
            type="number"
            onChange={onDepositChange}
          />
        </div>
      </div>
      <div style={{ marginTop: '38px' }} />
      <CustomCheckbox
        description={t('checkBox.read')}
        onCheck={onCheckedChange}
        checked={isSign}
      />
      <div className={style.buttons}>
        <div className={style.button}>
          <CustomButton
            type="secondary"
            title={t('buttons.cancel')}
            onClick={onCanselClick}
          />
        </div>
        <div className={style.button}>
          <CustomButton
            type="main"
            title={t('buttons.makeDeposit')}
            onClick={onDepositClick}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateDepositSide;
