import { createContext } from 'react';
import { ActiveDealType, ActiveOfferType } from 'src/helpers/static-data';

export interface OfferState {
  offer: ActiveOfferType | null;
  activeDeal: ActiveDealType | null;
}

export const OfferInitialState: OfferState = {
  offer: null,
  activeDeal: null,
};

export const OfferReducer = (
  state: OfferState,
  action: ActionsType,
): OfferState => {
  switch (action.type) {
    case 'offer/x11-app/SET-OFFER':
    case 'offer/x11-app/SET-ACTIVE-DEAL': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export const setOfferAC = (offer: ActiveOfferType) =>
  ({
    type: 'offer/x11-app/SET-OFFER',
    payload: { offer },
  } as const);

export const setActiveDealAC = (activeDeal: ActiveDealType) =>
  ({
    type: 'offer/x11-app/SET-ACTIVE-DEAL',
    payload: { activeDeal },
  } as const);

type ActionsType =
  | ReturnType<typeof setOfferAC>
  | ReturnType<typeof setActiveDealAC>;

interface OfferContextValue extends OfferState {
  setOffer: (id: string) => void;
  setActiveDeal: (id: string) => void;
}

export const OfferContext = createContext<OfferContextValue>({
  ...OfferInitialState,
  setOffer: () => {},
  setActiveDeal: () => {},
});
