/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  FocusEvent,
  FormEvent,
  useContext,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  changeEmailValue,
  changeInputValue,
  formReducer,
  GOOGLE_FORM_ACTION_URL,
  initialStateForm,
  resetForm,
  setErrorInput,
  validateEmail,
} from 'src/reducers/formReducer';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/reducers/AuthReducer';
import RPC from 'src/helpers/web3RPC';
import CustomButton from '../CustomButton/CustomButton';
import ResponseForm from './ResponseForm';
import style from './WriteForm.module.scss';

const WriteForm = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [reducerState, dispatch] = useReducer(formReducer, initialStateForm);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [responseType, setResponseType] = useState('failed');
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const onValidate = (
    e: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>,
  ) => {
    const { value, name } = e.target;
    if (name === 'entry.1166533059' && !value) {
      dispatch(setErrorInput('Name is required!', name));
    } else if (
      name === 'entry.759167060' &&
      value &&
      !value.match(validateEmail)
    ) {
      dispatch(setErrorInput('Email not valid!', name));
    } else if (name === 'entry.1232779119' && !value) {
      dispatch(setErrorInput('Message is required!', name));
    } else if (
      name === 'entry.1245571282' &&
      value &&
      // eslint-disable-next-line no-useless-escape
      !value.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g)
    ) {
      dispatch(setErrorInput('Phone not valid!', name));
    }
  };

  const onInputChange = (
    e: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>,
  ) => {
    const { value, name } = e.currentTarget;
    if (name === 'entry.759167060' && !value.match(validateEmail)) {
      dispatch(changeEmailValue(value, name));
    } else {
      dispatch(changeInputValue(value, name));
    }
    if (disabled) {
      setDisabled(false);
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    // @ts-ignore
    const name = document.forms.googleForm['entry.1166533059'].value;
    // @ts-ignore
    const email = document.forms.googleForm['entry.759167060'].value;
    // @ts-ignore
    const message = document.forms.googleForm['entry.1232779119'].value;
    // @ts-ignore
    const phone = document.forms.googleForm['entry.1245571282'].value;
    // if (!email && !name && !message && !phone) {
    //   e.preventDefault();
    //   setDisabled(true);
    //   dispatch(setErrorInput('Name is required', 'entry.1166533059'));
    //   return false;
    // }
    if (name === '') {
      e.preventDefault();
      dispatch(setErrorInput('Name is required', 'entry.1166533059'));
      return false;
    }
    if (phone === '') {
      e.preventDefault();
      dispatch(setErrorInput('Phone is required', 'entry.1245571282'));
      return false;
    }
    if (email === '') {
      e.preventDefault();
      dispatch(setErrorInput('Email is required', 'entry.759167060'));
      return false;
    }
    /* if (message === '') {
      e.preventDefault();
      dispatch(setErrorInput('Message is required', 'entry.1232779119'));
      return false;
    } */
    setIsLoading(true);
    // @ts-ignore
    fbq('track', 'Contact');
    return true;
  };

  const onLoad = () => {
    setIsLoading(false);
    setIsSend(true);
    dispatch(resetForm());
    if (window.navigator.onLine) {
      setResponseType('success');
    } else {
      setResponseType('failed');
    }
  };

  const onLogInClick = async () => {
    // await authCtx.auth.w3Auth.initModal();
    const { auth, setAddress, setBalance, setBalanceUSDC, setBalanceUSDT } =
      authCtx;
    await auth.w3Auth.connect();

    const rpc = new RPC(auth.w3Auth.provider);
    const address = await rpc.getAccounts();
    const balance = await rpc.getBalance();
    const balanceUSDC = await rpc.getUSDCBalance();
    const balanceUSDT = await rpc.getUSDTBalance();
    console.log('balance: ', balance);
    console.log('balanceUSDC: ', balanceUSDC);

    setAddress(address);
    setBalance(parseFloat(balance));
    setBalanceUSDC(parseFloat(balanceUSDC));
    setBalanceUSDT(parseFloat(balanceUSDT));
    console.log(address);
    navigate('/');
  };

  if (isSend) {
    return (
      <div className={style.writeBlock} ref={ref}>
        <ResponseForm type={responseType} />
        <br />
        <CustomButton
          type="secondary"
          title={t('buttons.signIn')}
          onClick={onLogInClick}
        />
      </div>
    );
  }

  return (
    <div className={style.writeBlock} ref={ref}>
      <iframe
        title="googleForm"
        name="hidden_iframe"
        id="hidden_iframe"
        style={{ display: 'none' }}
        onLoad={onLoad}
      />
      <form
        id="googleForm"
        name="googleForm"
        method="post"
        target="hidden_iframe"
        action={GOOGLE_FORM_ACTION_URL}
        onSubmit={(e) => onSubmit(e)}
      >
        <div className={style.writeHeader}>
          <div>
            <input
              name="entry.1166533059"
              value={reducerState?.['entry.1166533059']?.value}
              onChange={onInputChange}
              onBlur={onValidate}
              className={style.inputInvest}
              type="text"
              placeholder={t('writeForm.name')}
            />
            <p className={style.errorText}>
              {reducerState?.['entry.1166533059']?.error}
            </p>
          </div>
          <div>
            <input
              name="entry.1245571282"
              value={reducerState?.['entry.1245571282']?.value}
              onChange={onInputChange}
              onBlur={onValidate}
              className={style.inputInvest}
              type="text"
              placeholder={t('writeForm.phone')}
              maxLength={13}
            />
            <p className={style.errorText}>
              {reducerState?.['entry.1245571282']?.error}
            </p>
          </div>
          <div>
            <input
              name="entry.759167060"
              value={reducerState?.['entry.759167060']?.value}
              onChange={onInputChange}
              onBlur={onValidate}
              className={style.inputInvest}
              type="email"
              placeholder="Email"
            />
            <p className={style.errorText}>
              {reducerState?.['entry.759167060']?.error}
            </p>
          </div>
          <div>
            <p className={style.errorText}>
              {reducerState?.['entry.1232779119']?.error}
            </p>
          </div>
          <CustomButton
            type="main"
            title={t('writeForm.send')}
            typeButton="submit"
            disabled={disabled}
          />
        </div>
      </form>
    </div>
  );
};

export default WriteForm;
