import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from 'src/assets/images/authentications/X11_bank.svg';
import WriteForm from 'src/components/WriteForm/WriteForm';
import { useTranslation } from 'react-i18next';
import EthereumRpc from 'src/helpers/web3RPC';
import AuthenticationContainer from '../AuthenticationContainer/AuthenticationContainer';
import style from './Login.module.scss';
import AuthenticationAdvantages from '../Advantages/Advantages';
import RegistrationPreview from '../RegistrationPreview/RegistrationPreview';

// import RegistrationPersonal from '../RegistrationPersonal/RegistrationPersonal';
// import LoginForm from '../LoginForm/LoginForm';

const Login = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [isRegistration, setIsRegistration] = useState(false);
  const [isPersonal, setIsPersonal] = useState(false);
  const [logoStyle, setLogoStyle] = useState([style.logo]);
  const [headerAuth, setHeaderAuth] = useState([style.headerAuth]);

  const clientId = EthereumRpc.W3AUTH_KEY;

  const onLogoClick = () => {
    if (location.pathname.includes('registration')) {
      navigate('/authentication', { replace: true });
    }
  };

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes('login') || pathname.includes('personal')) {
      setLogoStyle([...logoStyle, style.logoAnimate]);
      setHeaderAuth([...headerAuth, style.headerAuthPosition]);
    } else {
      setLogoStyle([style.logo]);
      setHeaderAuth([style.headerAuth]);
    }
    if (pathname.includes('login')) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
    if (pathname.includes('personal')) {
      setIsPersonal(true);
    } else {
      setIsPersonal(false);
    }
    if (pathname.includes('registration') && !pathname.includes('personal')) {
      setIsRegistration(true);
      setLogoStyle([style.logo, style.logoHover]);
    } else {
      setIsRegistration(false);
      if (!pathname.includes('login') && !pathname.includes('personal')) {
        setLogoStyle([style.logo]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <AuthenticationContainer>
      <div className={style.login}>
        <div className={headerAuth.join(' ')}>
          <img
            onClick={onLogoClick}
            className={logoStyle.join(' ')}
            src={logo}
            alt="logo_x11"
            aria-hidden="true"
          />
          {!isLogin && !isRegistration && !isPersonal && (
            <div className={style.textAuth}>
              {t('auth.authentication.newEra')}
            </div>
          )}
        </div>
        <RegistrationPreview isShow={isRegistration} />
        <AuthenticationAdvantages
          isLogin={isLogin || isRegistration || isPersonal}
        />
        {/* {isLogin && <LoginForm isShow={isLogin} />} */}
        {/* {isPersonal && <RegistrationPersonal isShow={isPersonal} />} */}
        {isPersonal && <WriteForm />}
        {isLogin && <WriteForm />}
      </div>
    </AuthenticationContainer>
  );
};

export default Login;

/* export async function action({ request }) {
  const data = await request.formData();
  const email = data.get('email');
  const password = data.get('password');

  console.log('Email: ', email);
  console.log('Password: ', password);

  const authResponse = await fetch(
    'https://api.bank11.io/auth/loginByPassword',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    },
  );

  console.log('STATUS: ', authResponse.status);

  const authR = await authResponse.json();
  console.log('authR: ', authR);

  if (authResponse.status === 200) {
    const { token } = authR.data;
    console.log('TOKEN: ', token);
  }

  return redirect('/');
} */
