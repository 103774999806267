import { FC, ReactNode } from 'react';
import invest from 'src/assets/images/myScore/invest.svg';
import file from 'src/assets/images/file.png';
import { investTotal } from 'src/helpers/static-data';
import StatisticItem from 'src/components/StatisticItem/StatisticItem';
import CreateDeposit from 'src/components/CreateDeposit/CreateDeposit';
import useDeposit from 'src/hooks/use-deposit';
import DepositItem from 'src/components/DepositItem/DepositItem';
import ContainerPage from 'src/components/ContainerPage/ContainerPage';
import { useTranslation } from 'react-i18next';
import style from './Deposit.module.scss';

type PropsType = {
  children?: ReactNode;
};

const Deposit: FC<PropsType> = () => {
  const { t } = useTranslation();
  const { rate, total } = investTotal;
  const { deposits } = useDeposit();

  const leftBlock = (
    <div className={style.statisticDeposit}>
      <StatisticItem
        styles={{ width: '100%', height: '164px' }}
        icon={invest}
        rate={rate}
        total={total}
        title={t('Deposit.Savings')}
      />
      <CreateDeposit />
    </div>
  );

  return (
    <ContainerPage
      leftBlock={leftBlock}
      isEmpty={!deposits.length}
      iconEmpty={file}
      emptyTitle={t('Deposit.empty.title')}
      emptyDescription={t('Deposit.empty.description')}
    >
      <div className={style.depositList}>
        {deposits.map(
          ({
            id,
            month,
            total: depositTotal,
            rate: depositRate,
            nextPayout,
          }) => (
            <DepositItem
              id={id}
              month={month}
              rate={depositRate}
              nextPayout={nextPayout}
              total={depositTotal}
              key={id}
            />
          ),
        )}
      </div>
    </ContainerPage>
  );
};

export default Deposit;
