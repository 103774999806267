import InvestActiveFund from 'src/components/InvestActiveFund/InvestActiveFund';
import InvestData from 'src/components/InvestData/InvestData';
import style from './InvestBlock.module.scss';

const InvestBlock = () => {
  return (
    <div className={style.investBlock}>
      <InvestData />
      <InvestActiveFund />
    </div>
  );
};

export default InvestBlock;
