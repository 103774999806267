import { useTranslation } from 'react-i18next';
import arrow from 'src/assets/images/myScore/arrow-drop-down-line.svg';
import style from './InvestTabs.module.scss';

const InvestTabs = () => {
  const { t } = useTranslation();
  return (
    <div className={style.investDataTabs}>
      <p>{t('MyScore.InvestBlock.InvestData.tabs')}</p>
      <img src={arrow} alt="arrow" />
    </div>
  );
};

export default InvestTabs;
