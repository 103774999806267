import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderSide from 'src/components/HeaderSide/HeaderSide';
import useUI from 'src/hooks/use-ui';
import CustomButton from '../CustomButton/CustomButton';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import Devider from '../Devider/Devider';
import EventInfo from '../EventInfo/EventInfo';
import style from './PayBillSide.module.scss';

const PayBillSide = () => {
  const { t } = useTranslation();
  const { setNotification, setSidePopup } = useUI();

  const [isWaiting, setIsWaiting] = useState(false);

  const onHeaderClick = () => {
    setSidePopup(null);
  };

  const onCheck = (value: boolean) => {
    setIsWaiting(value);
  };

  const onCanselClick = () => {
    setSidePopup(null);
  };

  const onBuyClick = () => {
    setSidePopup(null);
    setNotification({
      isShow: true,
      header: t('sideBlock.PayBillSide.notification'),
      description: t('sideBlock.PayBillSide.description'),
    });
  };

  return (
    <div className={style.payBillSide}>
      <HeaderSide
        title={t('sideBlock.PayBillSide.header')}
        onHeaderClick={onHeaderClick}
        isQuestion
      />
      <EventInfo />
      <Devider styles={{ marginTop: '32px', marginBottom: '24px' }} />
      <CustomCheckbox
        description={t('checkBox.waiting')}
        checked={isWaiting}
        onCheck={onCheck}
      />
      <div className={style.description}>
        <span>
          {t('sideBlock.PayBillSide.explanation.item1')}
          <br />
          <br />
        </span>
        <span>{t('sideBlock.PayBillSide.explanation.item2')}</span>
      </div>
      <div className={style.buttons}>
        <div className={style.button}>
          <CustomButton
            type="secondary"
            title={t('buttons.refuse')}
            onClick={onCanselClick}
          />
        </div>
        <div className={style.button}>
          <CustomButton
            type="main"
            title={t('buttons.pay')}
            onClick={onBuyClick}
          />
        </div>
      </div>
    </div>
  );
};

export default PayBillSide;
