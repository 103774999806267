import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { depositCredits } from 'src/helpers/static-data';
import useDeposit from 'src/hooks/use-deposit';
import useUI from 'src/hooks/use-ui';
import ButtonAnte from '../ButtonAnte/ButtonAnte';
import ContainerButtons from '../ContainerButtons/ContainerButtons';

type PropsType = {
  children?: ReactNode;
};

const CreateDeposit: FC<PropsType> = () => {
  const { t } = useTranslation();
  const { setSidePopup } = useUI();
  const { setDepositVariant } = useDeposit();
  const onStakingClick = () => {
    setSidePopup('Staking');
  };

  const onCreateClick = () => {
    console.log('Создать');
  };

  return (
    <ContainerButtons
      descriptionHeader={t('Deposit.createBlock.descriptionHeader')}
      description={t('Deposit.createBlock.description')}
      buttonDescription={t('Deposit.createBlock.buttonDescription')}
      buttonHeader={t('Deposit.createBlock.buttonHeader')}
      buttonName={t('buttons.create')}
      onButtonClick={onCreateClick}
    >
      <>
        {depositCredits.map(({ month, rate }) => {
          const depositCreate = () => {
            setSidePopup('MakeDepositRate');
            setDepositVariant({ month, rate });
          };
          return (
            <ButtonAnte
              key={month}
              month={`${month} ${t('buttons.month')}`}
              rate={`${rate}%`}
              onClickButton={depositCreate}
            />
          );
        })}
        <ButtonAnte
          month={t('buttons.stakeX11')}
          rate={`${t('Deposit.upTo')} 30%`}
          onClickButton={onStakingClick}
        />
      </>
    </ContainerButtons>
  );
};

export default CreateDeposit;
