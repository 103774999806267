import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomSelect from 'src/components/CustomSelect/CustomSelect';
import DepositAddress from 'src/components/DepositAddress/DepositAddress';
import QrCode from 'src/components/QrCode/QrCode';
import { networks } from 'src/helpers/static-data';
import useCurrency from 'src/hooks/use-currency';
import useUI from 'src/hooks/use-ui';
import CustomButton from '../../CustomButton/CustomButton';
import CustomInput from '../../CustomInput/CustomInput';
import style from './InvestCurrency.module.scss';

const InvestCurrency = () => {
  const { t } = useTranslation();
  const { setNotification, setSidePopup } = useUI();
  const { setCurrency } = useCurrency();
  const [network, setNetwork] = useState('Ethereum (ERC20)');
  const [check, setCheck] = useState('main');

  const checkTypes = [{ value: 'main', label: t('sideBlock.InvestSide.main') }];

  const onNetworkChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setNetwork(e.target.value);
  };
  const onСheckChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCheck(e.target.value);
  };

  const onClickTopUp = () => {
    setSidePopup(null);
    setCurrency(null);
    setNotification({
      isShow: true,
      header: t('sideBlock.InvestSide.notification'),
      description: t('sideBlock.InvestSide.description'),
    });
  };

  return (
    <div className={style.investCurrency}>
      <CustomSelect
        onChange={onСheckChange}
        placeholder={t('placeholder.topUpAccount')}
        items={checkTypes}
        defaulf={check}
      />
      <CustomInput placeholder={t('placeholder.replenishment')} />
      <CustomButton
        title={t('buttons.topUp')}
        type="main"
        onClick={onClickTopUp}
      />
      <div style={{ marginBottom: '40px' }} />
      <CustomSelect
        onChange={onNetworkChange}
        placeholder={t('placeholder.net')}
        items={networks}
        defaulf={network}
      />
      <div style={{ marginBottom: '16px' }} />
      <QrCode />
      <div style={{ marginBottom: '32px' }} />
      <DepositAddress />
    </div>
  );
};

export default InvestCurrency;
