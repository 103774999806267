import { MouseEvent } from 'react';
import useUI from 'src/hooks/use-ui';
import { SideType, SubSideType } from 'src/reducers/UIReducer';
import DividerVertical from 'src/components/DividerVertical/DividerVertical';
import ActivateAccount from '../ActivateAccount/ActivateAccount';
import BillSendSide from '../BillSendSide/BillSendSide';
import BillSide from '../BillSide/BillSide';
import ChangePassword from '../ChangePassword/ChangePassword';
import CreateDepositSide from '../CreateDepositSide/CreateDepositSide';
import CreateTransfer from '../CreateTranslation/CreateTransfer';
import CreditLend from '../CreditLend/CreditLend';
import CreditsList from '../CreditsList/CreditsList';
import CurrenciesList from '../CurrenciesList/CurrenciesList';
import TopUpAccount from '../CurrenciesList/TopUpAccount/TopUpAccount';
import DepositSide from '../DepositSide/DepositSide';
import GetPaid from '../GetPaid/GetPaid';
import InvestSide from '../InvestSide/InvestSide';
import LoanOffer from '../LoanOffer/LoanOffer';
import PayBillSide from '../PayBillSide/PayBillSide';
import ProfileDetails from '../ProfileDetails/ProfileDetails';
import SettingsAccount from '../SettingsAccount/SettingsAccount';
import StakingSide from '../StakingSide/StakingSide';
import FiatCryptoSide from '../FiatCryptoSide/FiatCryptoSide';
import Offer from '../Offer/Offer';
import ActiveDealSide from '../ActiveDealSide/ActiveDealSide';
import ExchangeFunds from '../ExchangeFunds/ExchangeFunds';
import MapExchangers from '../MapExchangers/MapExchangers';

import style from './Overlay.module.scss';

const Overlay = () => {
  const { sidePopup, subSidePopup, setSidePopup, setSubSidePopup, isMap } =
    useUI();

  const closeOverLay = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setSidePopup(null);
      if (subSidePopup) {
        setSubSidePopup(null);
      }
    }
  };

  const renderPopup = (side: SideType) => {
    switch (side) {
      case 'ActivateAccount':
        return <ActivateAccount />;
      case 'AccountSettings':
        return <SettingsAccount />;
      case 'CurrenciesList':
        return <CurrenciesList />;
      case 'TopUpAccountX11':
        return <TopUpAccount />;
      case 'CreateTransfer':
        return <CreateTransfer />;
      case 'Bill':
        return <BillSide />;
      case 'Staking':
        return <StakingSide />;
      case 'HedgeFund':
      case 'InvestCurrency':
        return <InvestSide />;
      case 'PayBill':
        return <PayBillSide />;
      case 'GetPaid':
        return <GetPaid />;
      case 'DepositMonths':
        return <DepositSide />;
      case 'MakeDepositRate':
        return <CreateDepositSide />;
      case 'LoanOffer':
        return <LoanOffer />;
      case 'CreditsList':
        return <CreditsList />;
      case 'FiatCrypto':
        return <FiatCryptoSide />;
      case 'ActiveDeal':
        return <ActiveDealSide />;
      case 'ExchangeFunds':
        return <ExchangeFunds />;
      default:
        return null;
    }
  };

  const renderSubPopup = (side: SubSideType) => {
    switch (side) {
      case 'ChangePassword':
        return <ChangePassword />;
      case 'ProfileDetails':
        return <ProfileDetails />;
      case 'TopUpAccountCurrency':
        return <TopUpAccount />;
      case 'SubBill':
        return <BillSendSide />;
      case 'CreditLend':
        return <CreditLend />;
      case 'Offer':
        return <Offer />;
      default:
        return null;
    }
  };

  return (
    sidePopup && (
      <div className={style.overlay} onClick={closeOverLay} aria-hidden="true">
        {isMap && <MapExchangers />}
        <div
          className={style.side}
          style={
            subSidePopup
              ? { transform: 'translateX(0%)', transition: '0.3s ease-in' }
              : { transform: 'translateX(50%)', transition: '0.3s ease-in' }
          }
        >
          <div className={style.sideBlock}>
            {renderPopup(sidePopup)}
            {subSidePopup && <div className={style.blur} />}
          </div>
          <div className={style.sideBlockSub}>
            {subSidePopup && (
              <>
                <DividerVertical />
                {renderSubPopup(subSidePopup)}
              </>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default Overlay;
