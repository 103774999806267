import { FC } from 'react';
import { ActiveOfferType } from 'src/helpers/static-data';
import useOffer from 'src/hooks/use-offer';
import useUI from 'src/hooks/use-ui';
import DealsBlock from '../DealsBlock/DealsBlock';
import Distance from '../Distance/Distance';
import style from './ActiveOfferItem.module.scss';

type PropsType = {
  offer: ActiveOfferType;
};

const ActiveOfferItem: FC<PropsType> = ({ offer }) => {
  const { setSubSidePopup } = useUI();
  const { setOffer } = useOffer();

  const onOfferClick = () => {
    setSubSidePopup('Offer');
    setOffer(offer.id);
  };

  return (
    <div
      className={style.offerContainer}
      onClick={onOfferClick}
      aria-hidden="true"
    >
      <div className={style.header}>
        <div className={style.userName}>{offer.userName}</div>
        <Distance distance={offer.distance} />
      </div>
      <div className={style.exchangeRate}>
        {offer.usdt} USDT = {offer.usd} USD
      </div>
      <DealsBlock deals={offer.deals} performed={offer.performed} />
    </div>
  );
};

export default ActiveOfferItem;
