import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/reducers/AuthReducer';
import RPC from 'src/helpers/web3RPC';
import bullet from 'src/assets/images/authentications/bullet.svg';
import CustomButton from 'src/components/CustomButton/CustomButton';
import style from './Advantages.module.scss';

type PropsType = {
  isLogin: boolean;
};

const Advantages: FC<PropsType> = ({ isLogin }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const points = [
    t('auth.authentication.advantages.item1'),
    t('auth.authentication.advantages.item2'),
    t('auth.authentication.advantages.item3'),
  ];
  const authCtx = useContext(AuthContext);
  const [isModalInit, setIsModalInit] = useState(false);

  const clientId = RPC.W3AUTH_KEY;

  useEffect(() => {
    // if (i18n.language === 'ru') i18n.changeLanguage('en');
    const init = async () => {
      try {
        const web3auth = RPC.getWeb3Auth();

        const { auth } = authCtx;
        auth.w3Auth = web3auth;

        if (!isModalInit) {
          await web3auth.initModal();
          setIsModalInit(true);
        }
        if (web3auth.provider) {
          // setProvider(web3auth.provider);
        }
      } catch (error) {
        console.error(error);
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogInClick = async () => {
    // await authCtx.auth.w3Auth.initModal();
    // await authCtx.auth.w3Auth.connect();
    const { auth } = authCtx;
    await auth.w3Auth.connect();

    const rpc = new RPC(auth.w3Auth.provider);
    const address = await rpc.getAccounts();
    const balance = await rpc.getBalance();
    const balanceUSDC = await rpc.getUSDCBalance();
    const balanceUSDT = await rpc.getUSDTBalance();
    console.log('balance: ', balance);
    console.log('balanceUSDC: ', balanceUSDC);

    localStorage.setItem('address', address);
    localStorage.setItem('balance', balance);
    localStorage.setItem('balanceUSDC', balanceUSDC);
    localStorage.setItem('balanceUSDT', balanceUSDT);

    const { setAddress, setBalance, setBalanceUSDC, setBalanceUSDT } = authCtx;
    setAddress(address);
    setBalance(parseFloat(balance));
    setBalanceUSDC(parseFloat(balanceUSDC));
    setBalanceUSDT(parseFloat(balanceUSDT));
    console.log(address);

    const messageResponse = await fetch(
      'https://api.bank11.io/auth/createWalletchallenge',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const messageR = await messageResponse.json();
    const { sessionId, message } = messageR.data;
    const signedMessage = await rpc.signPersonalMessage(message);

    const authResponse = await fetch(
      'https://api.bank11.io/auth/loginByChallenge',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId,
          signature: signedMessage,
        }),
      },
    );
    const authR = await authResponse.json();
    console.log('authR: ', authR);

    const { token } = authR.data;
    console.log('token: ', token);

    localStorage.setItem('token', token);

    // check kyc status
    const kycResponse = await fetch('https://api.bank11.io/kyc', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    });
    const kycR = await kycResponse.json();
    const { status } = kycR.data;
    console.log('status: ', status);

    const isRu = false; // window.location.pathname.includes('/ru');

    if (status === 'created' || status === 'success') {
      const pathMain = isRu ? '/ru' : '/';
      navigate(pathMain);
    } else {
      const pathKYC = isRu ? '/ru/kyc' : '/kyc';
      navigate(pathKYC);
    }
  };

  const onLogOutClick = async () => {
    const { auth } = authCtx;
    if (auth.w3Auth) {
      await auth.w3Auth.logout();
    }
  };

  const onRegistrationClick = () => {
    navigate('/authentication/registration', { replace: true });
  };

  return (
    !isLogin && (
      <>
        <div className={style.advantages}>
          {points.map((el) => (
            <div key={el} className={style.item}>
              <img src={bullet} alt="bullet" />
              <span>{el}</span>
            </div>
          ))}
        </div>
        <div className={style.buttons}>
          <div className={style.button}>
            <CustomButton
              type="secondary"
              title={t('buttons.signIn')}
              onClick={onLogInClick}
            />
          </div>
        </div>
      </>
    )
  );
};

export default Advantages;
