import CurrencyItem from 'src/components/CurrencyItem/CurrencyItem';
import HeaderSide from 'src/components/HeaderSide/HeaderSide';
import useCurrency from 'src/hooks/use-currency';
import useUI from 'src/hooks/use-ui';
import DepositAddress from 'src/components/DepositAddress/DepositAddress';
import QrCode from 'src/components/QrCode/QrCode';
import Devider from 'src/components/Devider/Devider';
import { ChangeEvent, useState } from 'react';
import CustomSelect from 'src/components/CustomSelect/CustomSelect';
import { networks } from 'src/helpers/static-data';
import { useTranslation } from 'react-i18next';
import style from './TopUpAccount.module.scss';

const TopUpAccount = () => {
  const { t } = useTranslation();
  const { setSidePopup, setSubSidePopup } = useUI();
  const { currency } = useCurrency();
  const [network, setNetwork] = useState('Ethereum (ERC20)');

  const onNetworkChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setNetwork(e.target.value);
  };

  const onHeaderClick = () => {
    if (currency.title === 'X11') {
      setSidePopup(null);
    } else {
      setSubSidePopup(null);
    }
  };

  return (
    <div className={style.topUpAccount}>
      <HeaderSide
        title={`${t('placeholder.topUp')} ${currency?.id}`}
        onHeaderClick={onHeaderClick}
      />
      <div className={style.network}>
        <div className={style.container}>
          <CurrencyItem item={currency} />
          <CustomSelect
            onChange={onNetworkChange}
            placeholder={t('placeholder.net')}
            items={networks}
            defaulf={network}
          />
        </div>
      </div>

      <QrCode />
      <div style={{ marginBottom: '32px' }} />
      <DepositAddress />
    </div>
  );
};

export default TopUpAccount;
