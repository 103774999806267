import { useTranslation } from 'react-i18next';
import useUI from 'src/hooks/use-ui';
import CustomButton from '../../CustomButton/CustomButton';
import CustomInput from '../../CustomInput/CustomInput';
import style from './TopUp.module.scss';

const TopUp = () => {
  const { t } = useTranslation();
  const { setNotification, setSidePopup } = useUI();

  const commissions = [
    {
      title: t('sideBlock.InvestSide.commissions.item1.title'),
      description: t('sideBlock.InvestSide.commissions.item1.description'),
    },
    {
      title: t('sideBlock.InvestSide.commissions.item2.title'),
      description: t('sideBlock.InvestSide.commissions.item2.description'),
    },
    {
      title: t('sideBlock.InvestSide.commissions.item3.title'),
      description: t('sideBlock.InvestSide.commissions.item3.description'),
    },
  ];

  const onClickTopUp = () => {
    setSidePopup(null);
    setNotification({
      isShow: true,
      header: t('sideBlock.InvestSide.notification'),
      description: t('sideBlock.InvestSide.description'),
    });
  };

  return (
    <div className={style.topUp}>
      <CustomInput placeholder={t('placeholder.replenishment')} />
      <CustomButton
        title={t('sideBlock.InvestSide.topUp')}
        type="main"
        onClick={onClickTopUp}
      />
      <div className={style.info}>
        <h3>{t('sideBlock.InvestSide.commissions.header')}</h3>
        {commissions.map(({ title, description }) => (
          <div key={title} className={style.infoItem}>
            <p className={style.header}>{title}</p>
            <p>{description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopUp;
