import { useMemo, useCallback, useReducer, ReactNode } from 'react';
import { activeDeals, ActiveOffersList } from 'src/helpers/static-data';
import {
  OfferContext,
  OfferInitialState,
  OfferReducer,
  setActiveDealAC,
  setOfferAC,
} from 'src/reducers/OfferReducer';

type PropsType = {
  children: ReactNode;
};

const OfferProvider = (props: PropsType) => {
  const { children } = props;
  const [state, dispatch] = useReducer(OfferReducer, OfferInitialState);

  const setOffer = useCallback((id: string) => {
    const offer = ActiveOffersList.find((el) => el.id === id);
    dispatch(setOfferAC(offer));
  }, []);

  const setActiveDeal = useCallback((id: string) => {
    const activeDeal = activeDeals.find((el) => el.id === id);
    dispatch(setActiveDealAC(activeDeal));
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      setOffer,
      setActiveDeal,
    }),
    [state, setOffer, setActiveDeal],
  );

  return (
    <OfferContext.Provider value={contextValue}>
      {children}
    </OfferContext.Provider>
  );
};

export default OfferProvider;
