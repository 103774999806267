import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import KYCBlock from 'src/components/KYCBlock/KYCBlock';
import logo from 'src/assets/images/LogoX11bank.svg';
import style from './KYC.module.scss';

type PropsType = {
  children?: ReactNode;
};

const KYC: FC<PropsType> = () => {
  const { t } = useTranslation();
  return (
    <div className={style.kycPage}>
      <img src={logo} alt="" className={style.logo} />
      <div className={style.kycInner}>
        <h6>{t('activateAccount.registration')}</h6>
        <h1>{t('activateAccount.verification')}</h1>
        <KYCBlock />
      </div>
    </div>
  );
};

export default KYC;
