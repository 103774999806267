import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { currency } from 'src/helpers/static-data';
import useUI from 'src/hooks/use-ui';
import ActiveManagement from '../ActiveManagement/ActiveManagement';
import CustomButton from '../CustomButton/CustomButton';
import InvestCurrencyItem from '../InvestCurrencyItem/InvestCurrencyItem';
import style from './InvestActiveFund.module.scss';

const InvestActiveFund = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onMoreClick = () => {
    navigate('/invest', { replace: true });
  };

  const { setSidePopup } = useUI();
  const onFundClick = () => {
    setSidePopup('HedgeFund');
  };

  return (
    <div className={style.investActiveFund}>
      <div
        className={style.management}
        onClick={onFundClick}
        aria-hidden="true"
      >
        <ActiveManagement />
      </div>
      <div className={style.listCurrensy}>
        {currency.map((el) => (
          <InvestCurrencyItem key={el.id} item={el} />
        ))}
      </div>
    </div>
  );
};

export default InvestActiveFund;
