import { useTranslation } from 'react-i18next';
import { activeFund } from 'src/helpers/static-data';
import style from './ActiveManagement.module.scss';

const ActiveManagement = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={style.header}>
        {t('MyScore.InvestBlock.InvestActiveFund.activeFund')}
      </div>
      <div className={style.total}>
        {activeFund.total} <span>+{activeFund.rate}%</span>
      </div>
    </>
  );
};

export default ActiveManagement;
