import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CurrencyType } from 'src/helpers/static-data';
import useCurrency from 'src/hooks/use-currency';
import useUI from 'src/hooks/use-ui';
import CustomButton from '../CustomButton/CustomButton';
import style from './InvestCurrencyItem.module.scss';

type PropsType = {
  item: CurrencyType;
};

const InvestCurrencyItem: FC<PropsType> = ({ item }) => {
  const { t } = useTranslation();
  const { id, icon, rate, title, total, usd } = item;
  const location = useLocation();
  const { setSidePopup } = useUI();
  const { setCurrency } = useCurrency();
  const invest = location.pathname.includes('invest');

  const onInvestClick = () => {
    setSidePopup('InvestCurrency');
    setCurrency(item);
  };

  return (
    <div key={id} className={style.crypto}>
      <div className={style.image}>
        <img src={icon} alt={title} />
        <div>
          <p>
            {id} <span>{rate}</span>
          </p>
          <p className={style.total_usd}>{title}</p>
        </div>
      </div>
      {total && !invest ? (
        <div className={style.cryptoTotal}>
          <p>{total}</p>
          <p className={style.total_usd}>{usd}</p>
        </div>
      ) : (
        <CustomButton
          size="small"
          styled={{ width: '132px' }}
          title={t('Invest.button')}
          type="secondary"
          onClick={onInvestClick}
        />
      )}
    </div>
  );
};

export default InvestCurrencyItem;
