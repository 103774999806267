import { useTranslation } from 'react-i18next';
import support from 'src/assets/images/support.svg';
import style from './SupportButton.module.scss';

const SupportButton = () => {
  const { t } = useTranslation();
  return (
    <div className={style.support}>
      <div className={style.description}>
        <p>{t('Notifications.support.chat')}</p>
        <span>{t('Notifications.support.question')}</span>
      </div>
      <div className={style.icon}>
        <img src={support} alt="support" />
      </div>
    </div>
  );
};

export default SupportButton;
