import { FC } from 'react';
import style from './CustomButton.module.scss';

type PropsType = {
  type: 'main' | 'secondary';
  typeButton?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  title: string;
  onClick?: () => void;
  size?: 'main' | 'small';
  styled?: {
    [key: string]: string;
  };
};

const CustomButton: FC<PropsType> = ({
  type,
  typeButton,
  disabled,
  title,
  onClick,
  styled,
  size,
}) => {
  const styles =
    type === 'main'
      ? `${style.button} ${style.main} ${size === 'small' ? style.small : {}}`
      : `${style.button} ${style.secondary} ${
          size === 'small' ? style.small : {}
        }`;
  return (
    <button
      style={styled || {}}
      className={styles}
      disabled={disabled}
      // eslint-disable-next-line react/button-has-type
      type={typeButton}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default CustomButton;

CustomButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  typeButton: 'button',
  styled: {},
  size: 'main',
};
