import useUI from 'src/hooks/use-ui';
import bill from 'src/assets/images/bill_send.png';
import { useTranslation } from 'react-i18next';
import HeaderSide from '../HeaderSide/HeaderSide';
import style from './BillSendSide.module.scss';
import CustomButton from '../CustomButton/CustomButton';

const BillSendSide = () => {
  const { t } = useTranslation();
  const { setSidePopup, setSubSidePopup, subSidePopup } = useUI();

  const onHeaderClick = () => {
    if (subSidePopup) {
      setSubSidePopup(null);
    } else {
      setSidePopup(null);
    }
  };

  const onCanselClick = () => {
    setSidePopup(null);
    setSubSidePopup(null);
  };

  const onConfirmClick = () => {
    setSubSidePopup(null);
    setSidePopup(null);
    // setSidePopup('PayBill');
  };

  return (
    <div className={style.billSendSide}>
      <HeaderSide
        title={t('sideBlock.Bill.header')}
        onHeaderClick={onHeaderClick}
      />
      <div className={style.image}>
        <img src={bill} alt="bill" />
      </div>
      <div className={style.description}>
        <h3>{t('sideBlock.Bill.description.header')}</h3>
        <p>{t('sideBlock.Bill.description.item1')}</p>
        <p>{t('sideBlock.Bill.description.item2')}</p>
        <p>{t('sideBlock.Bill.description.item3')}</p>
      </div>
      <div className={style.buttons}>
        <div className={style.button}>
          <CustomButton
            type="main"
            title={t('buttons.confirm')}
            onClick={onConfirmClick}
          />
        </div>
      </div>
    </div>
  );
};

export default BillSendSide;
