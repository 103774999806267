import useUI from 'src/hooks/use-ui';
import contract from 'src/assets/images/contract.svg';
import email from 'src/assets/images/email_send.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderSide from '../HeaderSide/HeaderSide';
import style from './StakingSide.module.scss';
import CustomInput from '../CustomInput/CustomInput';
import CustomButton from '../CustomButton/CustomButton';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import TableRate from '../TableRate/TableRate';
import ButtonLink from '../ButtonLink/ButtonLink';

const StakingSide = () => {
  const { t } = useTranslation();
  const { setSidePopup } = useUI();
  const [signIt, setSignIt] = useState(false);

  const onHeaderClick = () => {
    setSidePopup(null);
  };

  const onCanselClick = () => {
    onHeaderClick();
  };

  const onStakingClick = () => {
    console.log('Cтейкинг');
  };

  const onCheck = (value: boolean) => {
    setSignIt(value);
  };

  return (
    <div className={style.stakingSide}>
      <HeaderSide
        title={t('sideBlock.StakingSide.header')}
        onHeaderClick={onHeaderClick}
      />
      <div className={style.stakingBlock}>
        <h3>{t('sideBlock.StakingSide.title')}</h3>
        <span>{t('secondary.description')}</span>
        <ButtonLink icon={email} title={t('buttonsLink.sendEmail')} />
        <ButtonLink icon={contract} title={t('buttonsLink.openContract')} />
        <div className={style.stakingTotal}>
          <CustomInput
            placeholder={t('placeholder.stakeAmount')}
            type="number"
          />
        </div>
      </div>
      <TableRate />
      <div className={style.policy}>
        <CustomCheckbox
          description={t('checkBox.read')}
          checked={signIt}
          onCheck={onCheck}
        />
      </div>
      <div className={style.buttons}>
        <div className={style.button}>
          <CustomButton
            type="secondary"
            title={t('buttons.cancel')}
            onClick={onCanselClick}
          />
        </div>
        <div className={style.button}>
          <CustomButton
            type="main"
            title={t('buttons.stake')}
            onClick={onStakingClick}
          />
        </div>
      </div>
    </div>
  );
};

export default StakingSide;
