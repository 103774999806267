import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabsType } from 'src/types/types';
import CardTabs from '../CardTabs/CardTabs';
import DealsChnager from '../DealsChnager/DealsChnager';
import RequestsBlock from '../RequestsBlock/RequestsBlock';
import style from './ChangerBlock.module.scss';

const ChangerBlock = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<string>('deal');
  const tabsCahnger: TabsType[] = [
    { title: t('Exchange.activeDeals'), id: 'deal' },
    { title: t('Exchange.requests'), id: 'requests' },
  ];

  const changeTab = (id: string) => {
    setCurrentTab(id);
  };
  return (
    <div className={style.changerBlock}>
      <CardTabs
        currentTab={currentTab}
        changeTab={changeTab}
        tabs={tabsCahnger}
        type="changer"
      />
      <div className={style.exchangeFund}>
        <div className={style.title}>{t('Exchange.myFund')}</div>
        <div className={style.fund}>
          <span>2850</span>/3000
        </div>
      </div>
      {currentTab === 'deal' && <DealsChnager />}
      {currentTab === 'requests' && <RequestsBlock />}
    </div>
  );
};

export default ChangerBlock;
