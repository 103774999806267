import { FC, ReactNode } from 'react';
import { formatDate } from 'src/helpers/formatDate';
import email from 'src/assets/images/email_send.svg';
import contract from 'src/assets/images/contract.svg';
import close from 'src/assets/images/close.svg';
import useDeposit from 'src/hooks/use-deposit';
import useUI from 'src/hooks/use-ui';
import { useTranslation } from 'react-i18next';
import HeaderSide from '../HeaderSide/HeaderSide';
import style from './DepositSide.module.scss';
import ButtonLink from '../ButtonLink/ButtonLink';

type PropsType = {
  children?: ReactNode;
};

const DepositSide: FC<PropsType> = () => {
  const { t } = useTranslation();
  const { currentDeposit, removeDeposit } = useDeposit();
  const { setSidePopup } = useUI();

  const headerTitle = `${t('sideBlock.CreateDeposit.header')} ${
    currentDeposit.month
  } ${t('sideBlock.CreateDeposit.month')}`;

  const onHeaderClick = () => {
    setSidePopup(null);
  };

  const deleteDeposit = () => {
    onHeaderClick();
    removeDeposit(currentDeposit.id);
  };

  return (
    <div className={style.depositSide}>
      <HeaderSide title={headerTitle} onHeaderClick={onHeaderClick} />
      <div className={style.depositBlock}>
        <div className={style.info}>
          <p>
            {t('sideBlock.CreateDeposit.header')}{' '}
            <span>
              {currentDeposit.month} {t('sideBlock.CreateDeposit.month')}
            </span>
          </p>
          <div className={style.total}>
            {currentDeposit.total} USD <span>+12,783 USD</span>
          </div>
        </div>
        <div style={{ marginTop: '52px' }} />
        <div className={style.date}>
          <div>{t('sideBlock.CreateDeposit.including')}</div>
          <span>{formatDate(currentDeposit.activeDate)}</span>
        </div>
        <div style={{ marginTop: '8px' }} />
        <div className={style.date}>
          <div>{t('sideBlock.CreateDeposit.nextPayout')}</div>
          <span>{formatDate(currentDeposit.nextPayout)}</span>
        </div>
      </div>
      <ButtonLink icon={email} title={t('buttonsLink.sendEmail')} />
      <ButtonLink icon={contract} title={t('buttonsLink.openContract')} />
      <ButtonLink
        icon={close}
        title={t('buttonsLink.break')}
        onClick={deleteDeposit}
      />
    </div>
  );
};

export default DepositSide;
