import useUI from 'src/hooks/use-ui';
import password from 'src/assets/images/password.svg';
import share from 'src/assets/images/share.svg';
import wechat from 'src/assets/images/wechat.svg';
import information from 'src/assets/images/information_account.svg';
import { useTranslation } from 'react-i18next';
import AccountBlock from '../AccountBlock/AccountBlock';
import ButtonLink from '../ButtonLink/ButtonLink';
import HeaderSide from '../HeaderSide/HeaderSide';

import style from './SettingsAccount.module.scss';
import ChangeSettingsBlock from './ChangeSettingsBlock/ChangeSettingsBlock';

const SettingsAccount = () => {
  const { t } = useTranslation();
  const { setSidePopup, setSubSidePopup } = useUI();

  const version = '1.19';

  const onHeaderClick = () => {
    setSidePopup(null);
  };

  const onPasswordChange = () => {
    setSubSidePopup('ChangePassword');
  };

  return (
    <div className={style.settingsAccount}>
      <HeaderSide
        title={t('sideBlock.SettingsAccount.settings')}
        onHeaderClick={onHeaderClick}
      />
      <AccountBlock />
      <ButtonLink
        icon={password}
        title={t('buttonsLink.password')}
        onClick={onPasswordChange}
      />
      <ButtonLink icon={information} title={t('buttonsLink.bank')} />
      <ButtonLink icon={wechat} title={t('buttonsLink.support')} />
      <div className={style.widget}>
        <div className={style.link}>
          <h3>{t('sideBlock.SettingsAccount.widget')}</h3>
          <div className={style.share}>
            <img src={share} alt="share" />
          </div>
        </div>
        <p>{t('sideBlock.SettingsAccount.widgetText')}</p>
      </div>
      <ChangeSettingsBlock />
      <div className={style.version}>App version {version}</div>
    </div>
  );
};

export default SettingsAccount;
