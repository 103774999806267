import { useMemo, useCallback, useReducer, ReactNode } from 'react';
import {
  AuthContext,
  AuthInitialState,
  AuthReducer,
  setAuthAC,
  AuthType,
} from 'src/reducers/AuthReducer';

type PropsType = {
  children: ReactNode;
};

const AuthProvider = (props: PropsType) => {
  const { children } = props;
  const [state, dispatch] = useReducer(AuthReducer, AuthInitialState);

  const setAuth = useCallback((auth: AuthType) => {
    dispatch({ type: 'auth/x11-app/SET-AUTH', payload: null });
  }, []);

  const initAuth = useCallback((auth: AuthType) => {
    dispatch({ type: 'auth/x11-app/INIT', payload: null });
  }, []);

  const setAddress = useCallback((address: string) => {
    dispatch({
      type: 'auth/x11-app/SET-ADDRESS',
      payload: { address },
    });
  }, []);

  const setBalance = useCallback((balance: number) => {
    dispatch({
      type: 'auth/x11-app/SET-BALANCE',
      payload: { balance },
    });
  }, []);

  const setBalanceUSDC = useCallback((balance: number) => {
    dispatch({
      type: 'auth/x11-app/SET-BALANCE-USDC',
      payload: { balance },
    });
  }, []);

  const setBalanceUSDT = useCallback((balance: number) => {
    dispatch({
      type: 'auth/x11-app/SET-BALANCE-USDT',
      payload: { balance },
    });
  }, []);

  const contextValue = useMemo(
    () => ({ ...state, setAuth, initAuth, setAddress }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.auth.address, state.auth.balance, setAuth, initAuth, setAddress],
  );

  // eslint-disable-next-line
  const ctxValue = {
    ...state,
    setAuth,
    initAuth,
    setAddress,
    setBalance,
    setBalanceUSDC,
    setBalanceUSDT,
  };

  return (
    <AuthContext.Provider value={ctxValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
