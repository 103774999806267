import { FC } from 'react';
import copy from 'src/assets/images/myScore/copy_address.svg';
import formatAddress from 'src/helpers/changeFormat';
import { isMobile } from 'src/helpers/static-data';
import style from './WalletAddress.module.scss';

type PropsType = {
  address: string;
};

const WalletAddress: FC<PropsType> = ({ address }) => {
  const cotyAddress = async () => {
    try {
      await navigator.clipboard.writeText(address);
      console.log('Async: Copying to clipboard was successful!');
    } catch (err) {
      console.error('Async: Could not copy text: ', err);
    }
  };

  return (
    <div className={style.address} onClick={cotyAddress} aria-hidden="true">
      <p>{isMobile ? formatAddress(address) : address}</p>
      <img src={copy} alt="copy" />
    </div>
  );
};

export default WalletAddress;
