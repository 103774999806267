import { FC } from 'react';
import arrow from 'src/assets/images/myScore/arrow_side.svg';
import question from 'src/assets/images/question.svg';
import style from './HeaderSide.module.scss';

type PropsType = {
  title: string;
  isQuestion?: boolean;
  onHeaderClick: () => void;
};

const HeaderSide: FC<PropsType> = ({ title, onHeaderClick, isQuestion }) => {
  return (
    <div className={style.container}>
      <div
        className={style.headerSide}
        onClick={onHeaderClick}
        aria-hidden="true"
      >
        <div className={style.arrow}>
          <img src={arrow} alt="arrow" />
        </div>
        <span>{title}</span>
      </div>
      {isQuestion && (
        <div className={style.prompt}>
          <img src={question} alt="question" />
        </div>
      )}
    </div>
  );
};

export default HeaderSide;

HeaderSide.defaultProps = {
  isQuestion: false,
};
