import { FC, ReactNode } from 'react';
import style from './AuthenticationContainer.module.scss';

type PropsType = {
  children: ReactNode;
};

const AuthenticationContainer: FC<PropsType> = ({ children }) => {
  return <div className={style.borderContainer}>{children}</div>;
};

export default AuthenticationContainer;
