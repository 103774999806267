import { useMemo, useCallback, useReducer, ReactNode } from 'react';
import {
  addDepositAC,
  DepositContext,
  DepositInitialState,
  DepositReducer,
  DepositVariantType,
  getDepositAC,
  removeDepositAC,
  setDepositVariantAC,
} from 'src/reducers/DepositReducer';

type PropsType = {
  children: ReactNode;
};

const DepositProvider = (props: PropsType) => {
  const { children } = props;
  const [state, dispatch] = useReducer(DepositReducer, DepositInitialState);

  const addDeposit = useCallback(
    (month: number, rate: number, total: number) => {
      const date = new Date();

      const activeDate = new Date(
        date.setFullYear(date.getFullYear() + 1),
      ).toISOString();

      const nextPayout = new Date(
        date.setMonth(date.getMonth() + 1),
      ).toISOString();

      dispatch(
        addDepositAC({
          month,
          rate,
          total,
          nextPayout,
          activeDate,
          id: date.toISOString(),
        }),
      );
    },
    [],
  );

  const getDeposit = useCallback(
    (id: string) => {
      const deposit = state.deposits.find((el) => el.id === id);
      dispatch(getDepositAC(deposit));
    },
    [state.deposits],
  );

  const setDepositVariant = useCallback(
    (depositVariant: DepositVariantType) => {
      dispatch(setDepositVariantAC(depositVariant));
    },
    [],
  );

  const removeDeposit = useCallback((id: string) => {
    dispatch(removeDepositAC(id));
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      addDeposit,
      getDeposit,
      setDepositVariant,
      removeDeposit,
    }),
    [state, addDeposit, getDeposit, setDepositVariant, removeDeposit],
  );

  return (
    <DepositContext.Provider value={contextValue}>
      {children}
    </DepositContext.Provider>
  );
};

export default DepositProvider;
