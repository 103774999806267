import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { creditsList } from 'src/helpers/static-data';
import useCredit from 'src/hooks/use-credit';
import useUI from 'src/hooks/use-ui';
import CustomInput from '../CustomInput/CustomInput';
import HeaderSide from '../HeaderSide/HeaderSide';
import style from './CreditsList.module.scss';

type PropsType = {
  children?: ReactNode;
};

const CreditsList: FC<PropsType> = () => {
  const { t } = useTranslation();
  const { setSidePopup, setSubSidePopup } = useUI();
  const { setConditionСredit } = useCredit();

  const onHeaderClick = () => {
    setSidePopup(null);
  };

  return (
    <div className={style.creditsListContainer}>
      <HeaderSide
        title={t('sideBlock.CreditsList.header')}
        onHeaderClick={onHeaderClick}
      />
      <div className={style.container}>
        <div className={style.creditsListBlock}>
          <h3>{t('sideBlock.CreditsList.title')}</h3>
          <CustomInput placeholder={t('placeholder.loanTerms')} />
          <CustomInput
            placeholder={t('placeholder.pledgeAmount')}
            type="number"
          />
        </div>
      </div>
      <div className={style.creditsList}>
        {creditsList.map((item) => {
          const { id, availableCredit, rate, month, isDisabled } = item;
          const onCreditClick = () => {
            if (!isDisabled) {
              setSubSidePopup('CreditLend');
              setConditionСredit(item);
            }
          };
          const color = { color: isDisabled ? '#757985' : '' };
          return (
            <div
              key={id}
              className={
                isDisabled
                  ? `${style.creditItem} ${style.disabledBg}`
                  : style.creditItem
              }
              onClick={onCreditClick}
              aria-hidden="true"
            >
              <div style={color} className={style.rate}>
                {rate}%
              </div>
              <div className={style.info}>
                <div>
                  <p>{t('sideBlock.CreditsList.term')}</p>{' '}
                  <span style={color}>
                    {month} {t('sideBlock.CreditsList.month')}
                  </span>
                </div>
                <div>
                  <p>{t('sideBlock.CreditsList.available')}</p>{' '}
                  <span style={color}>{availableCredit} USD</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CreditsList;
