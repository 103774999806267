import { FC } from 'react';
import DescriptionButton from '../DescriptionButton/DescriptionButton';
import style from './CreateButton.module.scss';

type PropsType = {
  name: string;
  header: string;
  description?: string;
  isLine?: boolean;
  onClick: () => void;
};

const CreateButton: FC<PropsType> = ({
  onClick,
  name,
  header,
  description,
  isLine,
}) => {
  return (
    <>
      {isLine && <div className={style.line} />}
      <DescriptionButton header={header} title={description} />
      <button className={style.button} onClick={onClick} type="button">
        {name}
      </button>
    </>
  );
};

export default CreateButton;

CreateButton.defaultProps = {
  description: '',
  isLine: true,
};
