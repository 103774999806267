import { ChangeEvent, useEffect, useState } from 'react';
import { currencies, languages } from 'src/helpers/static-data';
import { useTranslation } from 'react-i18next';
import Devider from '../../Devider/Devider';
import CheckboxNotice from '../../CheckboxNotice/CheckboxNotice';
import CustomSelect from '../../CustomSelect/CustomSelect';

import style from './ChangeSettingsBlock.module.scss';

const ChangeSettingsBlock = () => {
  const { i18n, t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [language, setLanguage] = useState('ru');
  const [currency, setCurrency] = useState('usd');

  const onNoticeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  const onLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
    localStorage.setItem('X11_lang', e.target.value);
  };

  const onCurrencyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrency(e.target.value);
  };

  useEffect(() => {
    (async () => {
      const lang = await localStorage.getItem('X11_lang');
      if (lang) {
        setLanguage(lang);
      }
    })();
  }, []);

  return (
    <>
      <Devider styles={{ marginTop: '24px', marginBottom: '24px' }} />
      <CustomSelect
        onChange={onCurrencyChange}
        placeholder={t('placeholder.displayCurrency')}
        items={currencies}
        defaulf={currency}
      />
      <div className={style.inputBlock}>
        <CustomSelect
          onChange={onLanguageChange}
          placeholder={t('placeholder.language')}
          items={languages}
          defaulf={language}
        />
      </div>
      <div className={style.notice}>
        <span>{t('placeholder.notifications')}</span>
        <CheckboxNotice checked={checked} onNoticeChange={onNoticeChange} />
      </div>
    </>
  );
};

export default ChangeSettingsBlock;
