import DateTimeFormatOptions = Intl.DateTimeFormatOptions;

export const formatDate = (date?: string) => {
  const options: DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const createdAt = new Date(Date.parse(date));
  const dateFormat = createdAt.toLocaleDateString('ru-RU', options);
  return dateFormat;
};

export const el = null;
