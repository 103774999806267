import { useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import photoIcon from 'src/assets/images/photo.svg';
import style from './UploadPhoto.module.scss';

const UploadPhoto = forwardRef<HTMLInputElement>((props, ref) => {
  const { t } = useTranslation();

  const [passport, setPassport] = useState<File | null>(null);

  return (
    <div className={style.activateAccount}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={style.uploadLabel} htmlFor="upload-passport">
        <div className={style.icon}>
          <img src={photoIcon} alt="" />
        </div>
        {t('activateAccount.uploadPhoto')}
      </label>
      <input
        className={style.uploadPassport}
        name="passport"
        id="upload-passport"
        type="file"
        ref={ref}
        onChange={(e) => {
          console.log(e.target.files);
          setPassport(e.target.files?.[0]);
        }}
      />
      <span className={style.fileName}>{passport ? passport.name : ''}</span>
    </div>
  );
});

export default UploadPhoto;
