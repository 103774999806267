import { createContext } from 'react';

export type DepositType = {
  id: string;
  month: number;
  rate: number;
  total: number;
  activeDate: string;
  nextPayout: string;
};

export type DepositVariantType = {
  month: number;
  rate: number;
};

export interface DepositState {
  deposits: DepositType[];
  currentDeposit: DepositType | null;
  depositVariant: DepositVariantType | null;
}

export const DepositInitialState: DepositState = {
  deposits: [],
  currentDeposit: null,
  depositVariant: null,
};

export const DepositReducer = (
  state: DepositState,
  action: ActionsType,
): DepositState => {
  switch (action.type) {
    case 'deposit/x11-app/ADD-DEPOSIT': {
      return {
        ...state,
        deposits: [action.payload, ...state.deposits],
      };
    }
    case 'deposit/x11-app/GET-DEPOSIT': {
      return {
        ...state,
        currentDeposit: action.payload,
      };
    }
    case 'deposit/x11-app/SET-DEPOSIT-VARIANT': {
      return {
        ...state,
        depositVariant: action.payload,
      };
    }
    case 'deposit/x11-app/DELETE-DEPOSIT': {
      return {
        ...state,
        deposits: state.deposits.filter((el) => el.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

export const addDepositAC = (deposit: DepositType) =>
  ({
    type: 'deposit/x11-app/ADD-DEPOSIT',
    payload: deposit,
  } as const);

export const getDepositAC = (deposit: DepositType) =>
  ({
    type: 'deposit/x11-app/GET-DEPOSIT',
    payload: deposit,
  } as const);

export const removeDepositAC = (id: string) =>
  ({
    type: 'deposit/x11-app/DELETE-DEPOSIT',
    payload: id,
  } as const);

export const setDepositVariantAC = (depositVariant: DepositVariantType) =>
  ({
    type: 'deposit/x11-app/SET-DEPOSIT-VARIANT',
    payload: depositVariant,
  } as const);

type ActionsType =
  | ReturnType<typeof addDepositAC>
  | ReturnType<typeof setDepositVariantAC>
  | ReturnType<typeof removeDepositAC>
  | ReturnType<typeof getDepositAC>;

interface DepositContextValue extends DepositState {
  addDeposit: (month: number, rate: number, total: number) => void;
  getDeposit: (id: string) => void;
  removeDeposit: (id: string) => void;
  setDepositVariant: (depositVariant: DepositVariantType) => void;
}

export const DepositContext = createContext<DepositContextValue>({
  ...DepositInitialState,
  addDeposit: () => {},
  getDeposit: () => {},
  removeDeposit: () => {},
  setDepositVariant: () => {},
});
