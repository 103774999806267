import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { user } from 'src/helpers/static-data';
import useUI from 'src/hooks/use-ui';
import CreateButton from '../CreateButton/CreateButton';
import style from './ExchangeControls.module.scss';

const ExchangeControls: FC = () => {
  const { t } = useTranslation();
  const { setSidePopup } = useUI();
  const { role } = user;
  const giveFiat = () => {
    setSidePopup('FiatCrypto');
  };

  const giveCrypto = () => {
    console.log('Отдать крипту');
  };

  const onExchangeClick = () => {
    setSidePopup('ExchangeFunds');
  };

  return (
    <div className={style.createDeposit}>
      <CreateButton
        header={t('Exchange.awayFiat.header')}
        description={t('Exchange.awayFiat.description')}
        name={t('buttons.choose')}
        isLine={false}
        onClick={giveFiat}
      />
      <CreateButton
        header={t('Exchange.awayCrypto.header')}
        description={t('Exchange.awayCrypto.description')}
        name={t('buttons.choose')}
        onClick={giveCrypto}
      />
      {role !== 'changer' && (
        <CreateButton
          header={t('Exchange.headerWallet')}
          name={t('buttons.exchange')}
          onClick={onExchangeClick}
        />
      )}
    </div>
  );
};

export default ExchangeControls;
