import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatItemType } from 'src/helpers/static-data';
import style from './ChatItem.module.scss';

type PropsType = {
  chat: ChatItemType;
  onChatClick: (id: string) => void;
};

const ChatItem: FC<PropsType> = ({ chat, onChatClick }) => {
  const { t } = useTranslation();
  const { id, chatName, time, isActive } = chat;
  const color = { background: !isActive ? '#ECEFF5' : '' };

  const onClick = () => {
    onChatClick(id);
  };

  return (
    <div
      className={style.chatItem}
      style={color}
      onClick={onClick}
      aria-hidden="true"
    >
      <div className={style.info}>
        <div className={style.header}>
          {isActive && <div className={style.active} />}
          {isActive
            ? t('Notifications.statusChats.new')
            : t('Notifications.statusChats.old')}
        </div>
        <div>{time}</div>
      </div>
      <div className={style.message}>{chatName}</div>
    </div>
  );
};

export default ChatItem;
