import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useUI from 'src/hooks/use-ui';
import CustomButton from '../CustomButton/CustomButton';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import CustomInput from '../CustomInput/CustomInput';
import CustomInput2 from '../CustomInput2/CustomInput2';
import UploadPhoto from '../UploadPhoto/UploadPhoto';
import UploadSelfie from '../UploadSelfie/UploadSelfie';
import style from './KYCBlock.module.scss';
import Spinner from '../Spinner/Spinner';

const KYCBlock = () => {
  const { t } = useTranslation();
  const [consent, setConsent] = useState(false);
  const navigate = useNavigate();

  const inputName = useRef<HTMLInputElement>(null);
  const inputCountry = useRef<HTMLInputElement>(null);
  const inputAddress = useRef<HTMLInputElement>(null);
  const inputPassport = useRef<HTMLInputElement>(null);
  const inputSelfie = useRef<HTMLInputElement>(null);

  const [errorName, setErrorName] = useState('');
  const [errorCountry, setErrorCountry] = useState('');
  const [errorAddress, setErrorAddress] = useState('');
  const [errorPassport, setErrorPassport] = useState('');
  const [errorSelfie, setErrorSelfie] = useState('');
  const [loading, setLoading] = useState(false);

  const onCheck = (value: boolean) => {
    setConsent(value);
  };

  const clearErrors = () => {
    setErrorCountry('');
    setErrorAddress('');
    setErrorPassport('');
    setErrorSelfie('');
    setErrorName('');
  };

  const onSend = () => {
    clearErrors();
    const url = 'https://api.bank11.io/kyc';

    /* let flag = false;
    if (
      inputName.current?.value === '' ||
      inputName.current?.value.length < 5
    ) {
      setErrorName(t('activateAccount.inputRequired'));
      flag = true;
    }
    if (
      inputCountry.current?.value === '' ||
      inputCountry.current?.value.length < 3
    ) {
      setErrorCountry(t('activateAccount.inputRequired'));
      flag = true;
    }
    if (
      inputAddress.current?.value === '' ||
      inputAddress.current?.value.length < 10
    ) {
      setErrorAddress(t('activateAccount.inputRequired'));
      flag = true;
    }
    if (inputPassport.current?.files[0] === undefined) {
      setErrorPassport(t('activateAccount.inputRequired'));
      flag = true;
    }
    if (inputSelfie.current?.files[0] === undefined) {
      setErrorSelfie(t('activateAccount.inputRequired'));
      flag = true;
    }

    if (flag) {
      return;
    } */

    setLoading(true);

    const formData = new FormData();
    formData.append('fullName', inputName.current?.value);
    formData.append('country', inputCountry.current?.value);
    formData.append('address', inputAddress.current?.value);
    formData.append('idFile', inputPassport.current?.files[0]);
    formData.append('selfie', inputSelfie.current?.files[0]);

    const token = localStorage.getItem('token');
    console.log('token: ', token);
    console.log('formData: ', formData.get('selfie'));
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        if (!data.errors) {
          navigate('/');
        } else {
          setErrorName(data.errors.fullName);
          setErrorCountry(data.errors.country);
          setErrorAddress(data.errors.address);
          setErrorPassport(data.errors.idFile);
          setErrorSelfie(data.errors.selfie);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className={style.activateAccount}>
      <form action="">
        <CustomInput2
          placeholder={t('sideBlock.ActivateAccount.fullName')}
          ref={inputName}
        />
        <span className={style.error}>{errorName}</span>
        <CustomInput2
          placeholder={t('sideBlock.ActivateAccount.country')}
          ref={inputCountry}
        />
        <span className={style.error}>{errorCountry}</span>
        <CustomInput2
          placeholder={t('sideBlock.ActivateAccount.address')}
          ref={inputAddress}
        />
        <span className={style.error}>{errorAddress}</span>
        <UploadPhoto ref={inputPassport} />
        <span className={style.error}>{errorPassport}</span>
        <UploadSelfie ref={inputSelfie} />
        <span className={style.error}>{errorSelfie}</span>
        <div style={{ marginTop: '48px' }} />
        <CustomCheckbox
          checked={consent}
          description={t('checkBox.processingData')}
          onCheck={onCheck}
        />
        <div style={{ marginTop: '24px' }} />
        {!loading && (
          <CustomButton
            type="main"
            title={t('buttons.review')}
            onClick={onSend}
          />
        )}
        {loading && <Spinner />}
      </form>
    </div>
  );
};

export default KYCBlock;
