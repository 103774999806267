import { datePeriod, investTotal } from 'src/helpers/static-data';
import InvestTabs from '../InvestTabs/InvestTabs';
import style from './InvestDiagram.module.scss';

const InvestDiagram = () => {
  return (
    <div className={style.investDiagram}>
      <div className={style.header}>
        <InvestTabs />
        <div className={style.datePeriod}>
          {datePeriod.start} - {datePeriod.end}
        </div>
      </div>
      <div className={style.graph}>
        <div className={style.graphTotal}>{investTotal.total}</div>
      </div>
      <div className={style.axisDate}>
        <p>{datePeriod.start}</p>
        <p>{datePeriod.end}</p>
      </div>
    </div>
  );
};

export default InvestDiagram;
